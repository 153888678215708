<template>
  <div
    data-test-id="company-page-permissions"
  >
    <div
      v-if="isFetchingPaymentPlans || emptyState"
      class="loading-container"
    >
      <am2-loading-spinner size="48px" />
    </div>
    <div v-else>
      <FeaturesEditor
        title="Demo Plan"
        description="You can manually turn on features for a promoter, even if they didn't pay for them. IMPORTANT: To enable SMS, a promoter MUST have a Chargebee subscription for SMS too (even a free one)."
        :promoter-plan="demoPromoterPlan"
        editable
        :loading="isUpdatingPromoterPlan"
        :promoter-email-editor-setting="!!promoterEmailEditorSetting ? promoterEmailEditorSetting.property : null"
        @update="updateDemoPlan"
      />
      <FeaturesEditor
        title="Chargebee Plan"
        description="These are the features which the promoter has paid for"
        :promoter-plan="chargebeePromoterPlan"
        disabled
      />
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import FeaturesEditor from '../../components/features-editor';

export default {
  name: 'CompanyPermissions',
  components: {
    FeaturesEditor,
  },

  data: () => ({
    chargebeePromoterPlan: null,
    demoPromoterPlan: null,
    isUpdatingPromoterPlan: false,
    isFetchingPaymentPlans: false,
    promoterEmailEditorSetting: null,
  }),

  computed: {
    emptyState() {
      return this.demoPromoterPlan === null && this.chargebeePromoterPlan === null
    },
  },

  created() {
    this.fetchPaymentInfo(this.$route.params.oid);
  },

  methods: {
    ...mapActions(['UPDATE_PAYMENT', 'FETCH_PROMOTER_PAYMENT', 'CREATE_EDITOR_PREFERENCE', 'UPDATE_EDITOR_PREFERENCE']),

    async updateDemoPlan(demoPromoterPlan, editor = null) {
      const promoterOid = this.$route.params.oid;

      this.isUpdatingPromoterPlan = true;
      try {
        if (!!editor) {
          if (!!this.promoterEmailEditorSetting) {
            console.warn(`Promoter OID ${promoterOid} email editor is being updated to ${this.promoterEmailEditorSetting}, property oid ${this.promoterEmailEditorSetting.oid}`)
            await this.UPDATE_EDITOR_PREFERENCE({
              promoterOid: promoterOid,
              propertyOid: this.promoterEmailEditorSetting.oid,
              editor: editor
            })
          } else {
            console.warn(`Promoter OID ${promoterOid} email editor is being set to ${this.promoterEmailEditorSetting}`)
            await this.CREATE_EDITOR_PREFERENCE({
              promoterOid: promoterOid,
              editor: editor
            })
          }
        }

        await this.UPDATE_PAYMENT({
          promoterOid: promoterOid,
          demoPromoterPlan: demoPromoterPlan,
        });
        this.$arNotification.push({ type: 'success', message: `Permissions successfully updated` });
      } catch (e) {
        this.$arNotification.push({ type: 'error', message: `Failed to update paymentInfo of promoter #${demoPromoterPlan.oid}` });
      } finally {
        this.isUpdatingPromoterPlan = false;
      }
    },

    async fetchPaymentInfoForPromoter(promoterOid, planType){
      try {
        let promoterPlan = await this.FETCH_PROMOTER_PAYMENT({ promoterOid, planType }) || null;
        if (promoterPlan) {
          // Replace all "null" with "false"
          // TODO: Remove it once server solves the issue that, disabled should only have true or false.
          promoterPlan = JSON.parse(JSON.stringify(promoterPlan).replace(/"([^"]+)":null/g, '"$1":false'));
          promoterPlan.promoterDisabled = !!promoterPlan.promoterDisabled;
        }

        this[`${planType}PromoterPlan`] = promoterPlan;
      } catch (e) {
        console.error(e);
        this.$arNotification.push({
          type: 'error',
          message: `Failed to fetch ${planType} promoter plan`,
        });
      }
    },

    async fetchPaymentInfo(promoterOid) {
      try {
        this.promoterEmailEditorSetting = await this.$api.beefree.getPromoterEmailEditorSetting(promoterOid)
      } catch (error) {
        console.error("OID", promoterOid, "getPromoterEmailEditorSetting failed to fetch", error)
      }      

      this.isFetchingPaymentPlans = true;
      await Promise.all([
        this.fetchPaymentInfoForPromoter(promoterOid, 'chargebee'),
        this.fetchPaymentInfoForPromoter(promoterOid, 'demo'),
      ]);
      this.isFetchingPaymentPlans = false;
    },
  },
}
</script>

<style lang="scss" scoped>
.loading-container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
</style>