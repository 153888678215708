<template>
  <portal
    to="modal"
    :disabled="!isShow"
    :style="{ position: 'absolute' }"
  >
    <ar-modal
      :is-open="isShow"
      class="ask-moshtix-access-token-modal"
      header="Connect To Moshtix"
      width="590px"
      :mobile="$arMediaQuery.window.maxWidth('xs')"
      @close="handleCloseClick"
    >
      <div
        slot="body"
        :class="[
          'ask-moshtix-access-token-modal-body',
          $arMediaQuery.window.maxWidth('xs') && 'u-padding-x-3',
        ]"
      >
        <ar-field
          label="Username"
        >
          <ar-input
            v-model="username"
            name="username"
            placeholder="Please enter your Moshtix username"
          />
        </ar-field>
        <ar-field
          label="Password"
          :style="{
            marginTop: '20px',
          }"
        >
          <ar-input
            v-model="password"
            name="password"
            type="password"
            placeholder="Please enter your Moshtix password"
          />
        </ar-field>
      </div>
      <div
        slot="body"
        :class="[
          'ask-moshtix-access-token-modal-body',
          $arMediaQuery.window.maxWidth('xs') && 'u-padding-x-3',
        ]"
      >
         <ar-text
            class="u-margin-top-2"
          size="xs"
          text="Or instead"
          weight="bold"
          :style="{
            color: $arStyle.color.blueGrey800,
          }"
        />
        <ar-field
          label="Access Token"
          :style="{
            marginTop: '20px',
          }"
        >
          <ar-input
            v-model="accessToken"
            name="accessToken"
            type="password"
            placeholder="Please enter your Moshtix access token"
          />
        </ar-field>
      </div>
      <div
        slot="footer"
        :class="[
          'ask-moshtix-access-token-modal-footer',
          $arMediaQuery.window.maxWidth('xs') && 'u-padding-x-3',
        ]">
        <ar-simple-button
          text="Confirm"
          :loading="loading"
          :disabled="confirmButtonDisabled"
          :style="{ width: '108px' }"
          @click="handleConfirmClick"
        />
      </div>
    </ar-modal>
  </portal>
</template>

<script>

export default {
  name: 'AskInputModal',
  components: {},
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      username: null,
      password: null,
      accessToken: null
    };
  },

  computed: {
    confirmButtonDisabled() {
      if (this.accessToken) {
        return false;
      }
      else {
        return !this.username || !this.password;
      }
    },
  },

  watch: {
    isShow(val) {
      if (val) {
        this.username = null;
        this.password = null;
        this.accessToken = null;
      }
    },
  },

  methods: {
    handleConfirmClick() {
      this.$emit('confirm', { username: this.username, password: this.password, accessToken: this.accessToken });
    },
    handleCloseClick() {
      this.$emit('cancel');
    },
  },
};
</script>

<style lang="scss" scoped>
.ask-moshtix-access-token-modal {
  .ask-moshtix-access-token-modal-body {
    padding: 20px 30px 30px;
  }
  .ask-moshtix-access-token-modal-footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 25px 30px;
    height: 100px;
  }
}
</style>
