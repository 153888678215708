var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('am2-card-container',{style:({
    padding: _vm.padding,
  }),attrs:{"ar-widget-id":"am2-swtich-field-card","ar-widget-meta":_vm.arWidgetMeta || _vm.computedArWidgetMeta}},[_c('div',{class:[
      'u-display-flex',
      _vm.alignCenter && 'u-align-items-center'
    ],style:({
      flexFlow: _vm.size === 'small' ? 'column-reverse' : null,
    })},[_c('div',{class:[
        'u-flex-grow-1',
        'u-padding-right-3' ]},[_c('div',{staticClass:"u-display-flex u-flex-flow-row",style:({
          marginTop: _vm.titleMarginTop,
        })},[_c('ar-text',{style:({
            color: _vm.$arStyle.color.blueGrey800,
          }),attrs:{"size":_vm.titleSize,"text":_vm.title,"weight":"bold","multiple-lines":""}}),_vm._v(" "),(!!_vm.link)?_c('ar-link-button',{staticClass:"u-margin-left-3",attrs:{"text":_vm.link,"disabled":_vm.linkDisabled},on:{"click":function($event){return _vm.$emit('linkClick')}}}):_vm._e()],1),_vm._v(" "),_c('ar-text',{directives:[{name:"show",rawName:"v-show",value:(!!_vm.subtitle),expression:"!!subtitle"}],style:({
          color: _vm.$arStyle.color.blueGrey700,
        }),attrs:{"size":"xs","text":_vm.subtitle,"multiple-lines":""}})],1),_vm._v(" "),_c('am2-switch',{class:[
        _vm.size === 'small' ? 'u-margin-bottom-3' : null ],attrs:{"disabled":_vm.disabled,"value":_vm.value,"is-controlled-value":_vm.isControlledValue},on:{"input":_vm.handleSwitch}})],1),_vm._v(" "),(_vm.displayField)?_c('div',{staticClass:"u-margin-top-3"},[_vm._t("default")],2):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }