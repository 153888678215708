<template>
  <div :class="[
    'settings-view',
    $arMediaQuery.pageContent.maxWidth('md') && 'md-max',
  ]">
    <div class="title-section">
      <ar-text
        size="lg"
        text="Account Settings"
      />
    </div>
    <am2-tabs
      :selected-tab-index="selectedPageItemIdx"
      :items="fetchPageItems"
      :style="{
        marginTop: '22px',
      }"
      @select="handlePageItemSelect"
    />
    <ar-divider />
    <nuxt-child
      class="child-content"
    />
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import {defaultSettingsPath} from "@/utils/helpers";

export default {
  layout: 'default',
  data() {
    return {
      pageItems: [
        {
          title: 'Subscription',
          path: '/settings/subscriptions'
        },
        // {
        //   title: 'Profile',
        //   path: '/settings/profile'
        // },
        {
          title: 'Password',
          path: '/settings/password'
        },
        {
          title: 'Billing',
          path: '/settings/billing'
        },
        {
          title: 'Integrations',
          path: '/settings/integrations'
        },
        {
          title: 'API Tokens',
          path: '/settings/api-tokens'
        }
      ]
    };
  },

  computed: {
    ...mapGetters({
      isFeatureEnabled: 'auth/isFeatureEnabled'
    }),
    ...mapState({
      path: state => state.route.path,
      promoterOid: state => state.auth.account.promoterOid,
    }),
    isApiTokensFeatureEnabled() {
      return this.isFeatureEnabled(['integrations', 'apiTokens']);
    },
    filteredPageItems() {
      // Add ability to remove options from the settings section based on certain promoter account conditions
      const defaultPathValue = defaultSettingsPath(this.promoterOid);
      return this.pageItems.filter(item => {
        if (defaultPathValue !== '/settings/subscriptions' && item.title === 'Subscription') {
          return false;
        }
        return true;
      })
    },
    fetchPageItems() {
      return this.filteredPageItems
      .filter(item => {

        if (!this.isApiTokensFeatureEnabled) {
          return item.title !== 'API Tokens';
        }
        else {
          return true;
        };
      });
    },
    selectedPageItemIdx() {
      return this.filteredPageItems.findIndex(item => item.path === this.path);
    },
  },

  async mounted() {},

  methods: {
    ...mapActions([]),

    handlePageItemSelect(tab) {
      this.$router.push(tab.path);
    },
  },
};
</script>

<style lang="scss" scoped>
.settings-view {
  max-width: 1200px;
  padding: 50px 30px 30px;
  margin: 0 auto;

  &.md-max {
    padding: 50px 12px 12px;
  }

  .title-section {
    height: 50px;
  }
}
</style>
