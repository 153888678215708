<template>
  <section data-test-id="companies-page">
    <div
      class="u-margin-top-6"
      v-infinite-scroll="fetchMoreData"
      infinite-scroll-disabled="disableFetchMoreMessages"
      :infinite-scroll-distance="10"
      :style="{
        width: '100%',
      }"
    >
      <am2-top
        :search="{
          action: serverSideSearch,
          placeholder: 'Search promoters by email, name or oid',
          value: searchString,
          style: {
            width: '350px',
          }
        }"
  
        title="Companies"
        :class="[
          'top-wrapper',
          'u-margin-bottom-8',
          $arMediaQuery.pageContent.maxWidth('sm') && 'sm-max',
        ]"
      >
        <template v-slot:button>
          <ar-simple-button
            text="New company (internal only)"
            icon-name="circle-plus"
            icon-side="left"
            icon-distance="12px"
            :style="{
              width: '250px'
            }"
            data-test-id="create-new-promoter-account"
            @click="handleCreatePromoterClick"
            v-tooltip.bottom="{
              content: 'This should only be used for testing and does not link to chargebee',
            }"
          />
        </template>
      </am2-top>
  
      <div
        v-if="isFetchingPromoterAccounts && promoterAccounts.length === 0"
        class="loading-container"
      >
        <am2-loading-spinner size="48px" />
      </div>
      <am2-table
        v-else
        :heads="tableHeadings"
        class="message-table"
        :rows="promoterAccounts"
        has-sticky-header
        enable-row-click
        @rowClick="handlePromoterRowClick"
      >
        <div
          slot="oid"
          slot-scope="{ data }"
          class="number-cell"
        >
          <div class="body-container">
            <ar-text
              size="xs"
              :text="data.oid"
            />
          </div>
        </div>
        <div
          slot="email"
          slot-scope="{ data }"
          class="message-cell"
        >
          <div class="body-container">
            <ar-text
              size="xs"
              :text="data.emailAddress"
            />
          </div>
        </div>
        <div
          slot="name"
          slot-scope="{ data }"
          class="message-cell"
        >
          <div class="body-container">
            <ar-text
              size="xs"
              :text="data.name"
            />
          </div>
        </div>
        <div
          slot="country"
          slot-scope="{ data }"
          class="message-cell"
        >
          <div class="body-container">
            <ar-text
              size="xs"
              :text="data.country"
            />
          </div>
        </div>
        <div
          slot="companyCategory"
          slot-scope="{ data }"
          class="message-cell"
        >
          <div class="body-container">
            <ar-text
              size="xs"
              :text="data.companyCategory"
            />
          </div>
        </div>
        <div
          slot="sysCtime"
          slot-scope="{ data }"
          class="message-cell"
        >
          <div class="body-container">
            <ar-text
              size="xs"
              :text="formattedDate(data.sysCtime)"
            />
          </div>
        </div>
      </am2-table>
    </div>

  </section>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import { generateMessageCenterDateCopy } from '@/utils/date';

export default {
  name: 'Companies',
  layout: 'default',

  data: () => ({
    searchString: '',
  }),

  computed: {
    ...mapState({
      promoterAccounts: state => state.admin.promoterAccounts,
      isFetchingPromoterAccounts: state => state.admin.isFetchingPromoterAccounts,
    }),

    ...mapGetters ({
      isAdminAccount: 'auth/isAdminAccount'
    }),

    tableHeadings() {
      return [
        {
          name: 'Oid',
          key: 'oid',
          format: 'oid',
          align: 'left',
          width: 75,
        },
        {
          name: 'Email',
          key: 'email',
          format: 'email',
          align: 'left',
          width: 233,
        },
        {
          name: 'Name',
          key: 'name',
          format: 'name',
          align: 'left',
          width: 162,
        },
        {
          name: 'Country',
          key: 'country',
          format: 'country',
          align: 'left',
          width: 75,
        },
        {
          name: 'Category',
          key: 'companyCategory',
          format: 'companyCategory',
          align: 'left',
          width: 162,
        },
        {
          name: 'Created',
          key: 'sysCtime',
          format: 'sysCtime',
          align: 'left',
          width: 190,
        }
      ];
    },
  },

  mounted() {
    if (!this.isAdminAccount) {
      this.$router.push({
        path: '/audience',
      });
    }
  },

  methods: {
    ...mapActions([
      'admin/FETCH_MORE_PROMOTER_ACCOUNTS',
    ]),

    handleCreatePromoterClick() {
      this.$router.push({
        path: `/admin/accounts/new/`
      });
    },

    formattedDate(date) {
      return generateMessageCenterDateCopy(date)
    },

    handlePromoterRowClick(promoter) {
      this.$router.push({
        path: `/admin/companies/${promoter.oid}/edit/overview`
      });
    },

    fetchMoreData() {
      if (!this.isFetchingPromoterAccounts) {
        this.fetchPromoterAccounts()
      }
    },

    serverSideSearch(search) {
      this.searchString = search;
      this.fetchPromoterAccounts(true)
    },

    fetchPromoterAccounts(reload=false) {
      this['admin/FETCH_MORE_PROMOTER_ACCOUNTS']({
        select: 'name,oid,emailAddress,country,companyCategory,sysCtime',
        reload,
        count: reload,
        filterString: this.searchString,
      });
    },
  },
}
</script>

<style lang="scss" scoped>
.message-cell, .number-cell {
  display: flex;
  align-items: center;
  justify-content: center;
}

.message-cell {
  padding: 0 12px;
  overflow: hidden;
  justify-content: flex-start;
}

.number-cell {
  padding: 0 17px;
}

.loading-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
</style>