<template>
  
    <section
      class="api-tokens"
      :style="{
        padding: $arMediaQuery.pageContent.minWidth('sm') ? '28px 30px 0' : '28px 12px 0',
      }"
    >
      <div>
        <ar-simple-button
          :outlined="true"
          :text="'Issue New Token'"
          :loading="false"
          :disabled="false"
          @click="handleIssueButtonClick"
        />
      </div>
      <section
        style="padding-top: 10px;"
        v-for="(apiToken) of apiTokenItems"
        :key="apiToken.id"
      >
        <am2-api-token-card
          v-if="apiToken.active"
          class="api-token-card"
          :token="apiToken.token"
          :createTime="apiToken.createTime"
          :active="apiToken.active"
          :id="apiToken.id"
        />
      </section>
    </section>
    
  </template>
  <script>
  import { getFormattedDateTimeWithTimezone } from '@/utils/date/';
  import { mapState, mapActions, mapGetters } from 'vuex';
  export default {
    components: {
    },
    name: 'ApiTokens',
    layout: 'default',
    data() {
      return {};
    },
    computed: {
      ...mapState({
        promoter: state => state.auth.account,
        path: state => state.route.path,
        isFetchingApiTokens: state => state.apiToken.isFetchingApiTokens,
        apiTokens: state => state.apiToken.apiTokens
      }),
      ...mapGetters({
        fetchAllApiTokens: 'apiToken/FETCH_ALL_API_TOKENS',
        issueApiToken: 'apiToken/ISSUE_API_TOKEN',
      }),
      
      apiTokenItems() {
        return this.apiTokens.map(apiToken => {
          return {
            id: apiToken.oid,
            token: apiToken.token,
            createTime: apiToken.sysCtime ? getFormattedDateTimeWithTimezone(new Date(apiToken.sysCtime)) : "",
            active: apiToken.sysActivep
          };
        });
      }
    },
    mounted() {
      this['apiToken/FETCH_ALL_API_TOKENS']();
    },
    methods: {
      ...mapActions([
        'apiToken/FETCH_ALL_API_TOKENS',
        'apiToken/ISSUE_API_TOKEN',
        'apiToken/REVOKE_API_TOKEN'
      ]),
      async handleIssueButtonClick() {
        await this['apiToken/ISSUE_API_TOKEN']();
      }
    }
  };
  </script>
  <style lang="scss" scoped>
  .api-tokens {
    max-width: 1200px;
    margin: 0 auto;
    .integration-card {
      margin-bottom: 10px;
    }
  }
  </style>