<template>
  <div v-if="isFetchingOverviewMessages">
    <table :style="{width: '100%', marginTop: '24px'}">
      <div class="tr" v-for="n in 12" :key="n" :style="{display: 'flex'}">
        <div
          class="td"
          :style="{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            paddingLeft: '12px',
            paddingRight: '12px',
            width: `50%`,
            minHeight: `72px`,
          }"
        >
          <am2-typography-skeleton
            size="md"
            :level="n"
            :style="{ width: `100%`, height: '32px', }"
          />
        </div>
        <div
          class="td"
          :style="{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            paddingLeft: '12px',
            paddingRight: '12px',
            width: `50%`,
            minHeight: `50px`,
          }"
        >
          <am2-typography-skeleton
            size="md"
            :level="n"
            :style="{ width: `100%`, height: '32px', }"
          />
        </div>
      </div>
    </table>
  </div>
  <div
    v-else-if="noMessages"
    :style="{
      marginTop: '16px',
    }"
  >
    <am2-no-content-section
      icon-name="ar-message-in-circle"
      :header="noContentHeader"
      :body="noContentBody"
    />
  </div>
  <div v-else>
    <!-- In-progress -->
    <div v-if="inProgress.length > 0" class="table-wrapper">
      <div class="table-header">
        <ar-text
          size="xs"
          text="Sending"
          weight="bold"
        />
      </div>
      <am2-messages-table
        :head="getTableHeadings('inProgress')"
        :messages="inProgress"
        :loading="isFetchingOverviewMessages && inProgress.length === 0"
        :style="{
          marginTop: '16px',
        }"
        @cloneMessage="handleMessageAdded"
        @archiveMessage="handleMessageArchivied"
        @cancelMessage="handleMessageCancelled"
        @openMessagePreviewModal="openMessagePreviewModal"
      />
    </div>

    <!-- Drafts -->
    <div v-if="drafts.length > 0" class="table-wrapper">
      <div class="table-header">
        <ar-text
          size="xs"
          text="Recent Drafts"
          weight="bold"
        />
        <ar-text
          :text="`See all drafts${draftsCount > 0 ? ` (${draftsCount})` : ''}`"
          size="xs"
          class="header-link"
          @click.native="setMessageFilter('draft')"
        />
      </div>
      <am2-messages-table
        :head="getTableHeadings('draft')"
        :messages="drafts"
        :loading="isFetchingOverviewMessages && drafts.length === 0"
        :style="{
          marginTop: '16px',
        }"
        @cloneMessage="handleMessageAdded"
        @archiveMessage="handleMessageArchivied"
        @cancelMessage="handleMessageCancelled"
        @openMessagePreviewModal="openMessagePreviewModal"
      />
    </div>

    <!-- Scheduled -->
    <div v-if="scheduled.length > 0" class="table-wrapper">
      <div class="table-header">
        <div class="table-header-inline">
          <ar-text
            size="xs"
            text="Scheduled"
            weight="bold"
          />
          <ar-text
            size="xs"
            :text="`(${scheduledCount} message${scheduledCount > 1 ? 's' : ''})`"
            :style="{
              color: $arStyle.color.skyBlueGrey700,
            }"
          />
        </div>
      </div>
      <am2-messages-table
        :head="getTableHeadings('scheduled')"
        :messages="scheduled"
        :loading="isFetchingOverviewMessages && scheduled.length === 0"
        :style="{
          marginTop: '16px',
        }"
        @cloneMessage="handleMessageAdded"
        @archiveMessage="handleMessageArchivied"
        @cancelMessage="handleMessageCancelled"
        @openMessagePreviewModal="openMessagePreviewModal"
      />
    </div>

    <!-- Sent -->
    <div v-if="sent.length > 0" class="table-wrapper">
      <div class="table-header">
        <ar-text
          size="xs"
          text="Recently sent"
          weight="bold"
        />
        <ar-text
          :text="`See all sent${sentCount > 0 ? ` (${sentCount})` : ''}`"
          size="xs"
          class="header-link"
          @click.native="setMessageFilter('completed')"
        />
      </div>
      <am2-messages-table
        :head="getTableHeadings('sent')"
        :messages="sent"
        :loading="isFetchingOverviewMessages && sent.length === 0"
        :style="{
          marginTop: '16px',
        }"
        @cloneMessage="handleMessageAdded"
        @archiveMessage="handleMessageArchivied"
        @cancelMessage="handleMessageCancelled"
        @openMessagePreviewModal="openMessagePreviewModal"
      />
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex';
import { arraysEqualUnsorted } from '@/utils/helpers/';

export default {
  name: 'OverviewTables',

  props: {
    searchString: {
      type: String,
      default: null,
    },
    currentMessageType: {
      type: String,
      default: 'all',
    },
  },

  watch: {
    messageListsOids(curr, old) {
      if (!arraysEqualUnsorted(curr, old) && curr.length > 0) {
        this.fetchMoreMessageLists();
      }
    },
    searchString(curr, old) {
      if (curr !== old) {
        this.reloadData();
      }
    },
    currentMessageType(curr, old) {
      if (curr !== old) {
        this.reloadData();
      }
    },
  },

  computed: {
    ...mapState({
      messagesOverview: state => state.message.messagesOverview,
      isFetchingOverviewMessages: state => state.message.isFetchingOverviewMessages,
      hasFetchOverviewMessagesFailed: state => state.message.hasFetchOverviewMessagesFailed,
      messageLists: state => state.messageList.messageLists,
    }),

    drafts() {
      return this.addMessageList(this.messagesOverview['draft']?.rows || []);
    },

    draftsCount() {
      return this.messagesOverview['draft']?.count || 0;
    },

    sent() {
      return this.addMessageList(this.messagesOverview['completed']?.rows || []);
    },

    sentCount() {
      return this.messagesOverview['completed']?.count || 0;
    },

    inProgress() {
      return this.addMessageList(this.messagesOverview['in-progress']?.rows || []);
    },

    inProgressCount() {
      return this.messagesOverview['in-progress']?.count || 0;
    },

    scheduled() {
      return this.addMessageList(this.messagesOverview['scheduled']?.rows || []);
    },

    scheduledCount() {
      return this.messagesOverview['scheduled']?.count || 0;
    },

    noMessages() {
      return !this.isFetchingOverviewMessages
        && (this.hasFetchOverviewMessagesFailed ||
        ( this.drafts.length === 0
          && this.sent.length === 0
          && this.inProgress.length === 0
          && this.scheduled.length === 0 )
        );
    },

    currentMessageListsOid() {
      return this.messageLists.map(ml => ml.oid);
    },

    messageListsOids() {
      if (!this.messagesOverview) return [];

      // messagesOverview is an object of message lists
      return Object.entries(this.messagesOverview).reduce((mloAcc, [k, messages]) => {

        // Reduce the message list and then accumulate it
        const msgListOid = (messages?.rows || []).reduce((msgAcc, message) => {
          const messageListOid = message.meta.messageListOid;
          // Check that messageListOid is not null, and is not already loaded
          return messageListOid && !this.currentMessageListsOid.includes(messageListOid) ?
            [...msgAcc, messageListOid] : msgAcc;
        }, []);

        return [...mloAcc, ...msgListOid];
      }, []);
    },

    noContentHeader() {
      if (this.currentMessageType === "sms") 
        return "Create an SMS";
      else if (this.currentMessageType === "email")
        return "Create an email"
      else
        return "Create a message";
    },

    noContentBody() {
      if (this.currentMessageType === "sms") 
        return "Create and send your first SMS to see it here";
      else if (this.currentMessageType === "email")
        return "Create and send your first email to see it here"
      else
        return "Send your first email or SMS message";
    }
  },

  created() {
    this['message/RESET_OVERVIEW_MESSAGES']();
    this.reloadData();
  },

  beforeDestroy() {
    this['message/RESET_OVERVIEW_MESSAGES']();
  },

  methods: {
    ...mapActions([
      'message/FETCH_MESSAGES_OVERVIEW',
      'messageList/FETCH_MORE_MESSAGE_LISTS',
    ]),
    ...mapMutations([
      'message/RESET_OVERVIEW_MESSAGES',
    ]),

    async reloadData() {
      const provider = this.currentMessageType === 'all' ? null : this.currentMessageType;

      this['message/FETCH_MESSAGES_OVERVIEW']({
        provider,
        search: this.searchString,
      });
    },

    async fetchMoreMessageLists() {
      if (this.messageListsOids.length === 0) return;

      this['messageList/FETCH_MORE_MESSAGE_LISTS']({
        oids: this.messageListsOids,
        userDefined: true,
      });
    },

    setMessageFilter(key) {
      this.$emit('setMessageFilter', key);
    },

    handleMessageAdded() {
      this.reloadData();
      this.$emit('cloneMessage');
    },

    handleMessageCancelled() {
      setTimeout(() => {
        this.reloadData();
      }, 1000);
      this.$emit('cancelMessage');
    },

    handleMessageArchivied() {
      // Archive is slower than duplicate, so we need to wait a bit before
      // reloading data to avoid getting the same data again
      setTimeout(() => {
        this.reloadData();
      }, 1000);
      this.$emit('archiveMessage');
    },

    openMessagePreviewModal(data) {
      this.$emit('openMessagePreviewModal', data)
    },

    addMessageList(messages) {
      return messages.map((msg) => {
        const messageListOid = msg.meta.messageListOid;
        const messageList = this.messageLists.find(ml => ml.oid === messageListOid);

        if (messageList)
          return { ...msg, messageList };
        return msg;
      });
    },

    getTableHeadings(tableType) {
      const commonHeadings = [
        {
          name: 'Message',
          key: 'message',
          format: 'message',
        },
      ];
      const tableHeadings = {
        inProgress: [
          ...commonHeadings,
          {
            name: '',
            key: 'status',
            format: 'status',
          },
          {
            name: '',
            key: 'menu',
            format: 'menu',
          },
        ],
        scheduled: [
          ...commonHeadings,
          {
            name: '',
            key: 'menu',
            format: 'menu',
          },
        ],
        draft: [
          ...commonHeadings,
          {
            name: 'Edited',
            key: 'edited',
            format: 'edited',
          },
          {
            name: '',
            key: 'menu',
            format: 'menu',
          },
        ],
        sent: [
          ...commonHeadings,
          {
            name: 'Recipients',
            key: 'recipients',
            format: 'recipients',
          },
          {
            name: 'Opened',
            key: 'opens',
            format: 'opens',
          },
          {
            name: 'Clicked',
            key: 'clicks',
            format: 'clicks',
          },
          {
            name: 'Sent',
            key: 'sent',
            format: 'sent',
          },
          {
            name: '',
            key: 'menu',
            format: 'menu',
          },
        ],
      };

      return tableHeadings[tableType] || [];
    },
  },
}
</script>

<style lang="scss" scoped>
.table-wrapper {
  margin-top: 32px;

  .table-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;

    &-inline {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: start;
      gap: 5px;
    }

    .header-link {
      color: $purple500;
      cursor: pointer;
      text-decoration: underline;
    }
  }
}
</style>
