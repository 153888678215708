<template>
  <portal to="modal" :disabled="!opened" :style="{ position: 'absolute' }">
    <ar-modal
      :is-open="opened"
      header="Design your email"
      :width="templateType === 'beefree' && !isAbEnabled ? '1068px' : '728px'"
      :mobile="$arMediaQuery.window.maxWidth('xs')"
      @close="handleModalClose"
      class="design-email-modal"
    >
      <div slot="body">
        <div  class="body">
          <div class="design-email-card legacy-template-section" @click="handleLegacyTemplateClick" v-if="templateType === 'beefree' && !isAbEnabled">
            <img src="~/assets/images/message-center/use-a-template.png" />
            <ar-text class="title" size="sm" weight="bold" text="Use a LEGACY template" />
            <ar-text class="subtitle" multiple-lines size="xs" text="Select a template to open in the old email builder." />
          </div>

          <div class="design-email-card design-from-scratch" @click="handleDesignFromScratchClick">
            <div class="new-email-builder-tag" v-if="templateType === 'beefree'">
              <span class="new-email-builder-tag-text">NEW EMAIL BUILDER</span>
            </div>
            <img src="~/assets/images/message-center/design-from-scratch.png" />
            <ar-text class="title" size="sm" weight="bold" :text="'Design from scratch'" />
            <ar-text class="subtitle" multiple-lines size="xs" :text="'Start with a blank canvas if you already have a design in mind.'" />
          </div>

          <div class="design-email-card template-section" @click="handleTemplateClick">
            <div class="new-email-builder-tag" v-if="templateType === 'beefree'">
              <span class="new-email-builder-tag-text">NEW EMAIL BUILDER</span>
            </div>
            <img v-if="templateType === 'beefree'" src="~/assets/images/message-center/use-a-new-template.png" />
            <img v-else src="~/assets/images/message-center/use-a-template.png" />
            <ar-text class="title" size="sm" weight="bold" :text="templateType === 'beefree' ? 'Use a NEW template' : 'Use a template'" />
            <ar-text class="subtitle" multiple-lines size="xs" :text="templateType === 'beefree' ? 'Select a template to open in the new email builder.' : 'Select from your saved templates or get start with one of ours.'" />
          </div>
        </div>

        <div v-if="templateType === 'beefree' && !isAbEnabled" class="notification">
          <ar-snackbar
            type="general"
            message="We are currently moving all old templates to our new email builder. If an old template is not visible in our new email builder you can still access it via the old builder. <b>Please create new templates in the new email builder.</b>"
            :style="{ width: '100%' }"
          />
        </div>
      </div>
    </ar-modal>
  </portal>
</template>

<script>
import {mapGetters} from 'vuex'

export default {
  name: 'DesignEmailModal',
  components: {},
  props: {
    opened: {
      type: Boolean,
      default: false
    },
    templateType: {
      type: String,
      default: 'beefree',
    }
  },
  data() {
    return {
      template: null,
      emailHtml: null,
      showSelectEmailTemplateSection: false
    };
  },

  computed: {
    ...mapGetters({
      isFeatureEnabled: 'auth/isFeatureEnabled',
    }),

    isAbEnabled() {
      return this.isFeatureEnabled(['messages', 'abEmails'])
    },
  },

  watch: {
    opened(newVal) {
      if (newVal) {
        this.initEmailEditor();
      }
    }
  },

  methods: {
    initEmailEditor() {},
    handleModalClose() {
      this.$emit('close');
    },
    handleDesignFromScratchClick() {
      this.$emit('designFromScratch');
    },
    handleTemplateClick() {
      this.$emit('template');
    },
    handleLegacyTemplateClick() {
      this.$emit('legacyTemplate')
    }
  }
};
</script>

<style lang="scss" scoped>
.design-email-modal {
  .body {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px;
    margin: 48px;

    .title {
      margin-bottom: 6px;
    }
    .subtitle {
      color: $skyBlueGrey700;
    }

    .design-email-card {
      cursor: pointer;
      border: 1px solid $blueGrey500;
      border-radius: 4px;
      background-color: #FFF;

      width: 292px;
      padding: 24px;

      img {
        margin-bottom: 16px;
      }

      &:hover {
        color: $purple500;
        border: 1px solid $purple500;
        box-shadow: 1px 2px 8px rgba(0, 0, 0, 0.07);
        .title {
          color: $purple500;
        }
        .subtitle {
          color: $blueGrey800;
        }
      }
    }

    .new-email-builder-tag {
      position: relative;
      top: 12px;
      left: 12px;
      height: 0px !important;

      &-text {
        background-color: $purple500;
        font-size: 12px;
        color: #FFF;
        padding: 2px 6px 2px 6px;
        font-weight: 500;
        border-radius: 3px;
      }
    }
  }

  .notification {
    margin: 0 48px 48px 48px;
  }
}
</style>
