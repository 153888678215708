<template>
  <div
    class="table-control-section"
  >
    <div class="table-control-wrapper">
      <div class="table-control-container">
        <slot name="tablecontrol">
          <am2-search
            class="item fans-search"
            fan-appellation="recipient"
            :placeholder="searchPlaceholder"
            v-model="searchString"
            :style="{
              height: '40px',
            }"
            @enter="handleSearch"
            @clear="handleSearchClear"
          />
          <am2-simple-button-dropdown
            class="item dropdown-button"
            :button-props="{
                type: 'grey',
                outlined: true,
                style: {
                  height: '40px',
                  width: '100%'
                },
                iconName: 'ellipsis',
                iconDistance: '0px',
                iconProps: {
                  height: '4px',
                  width: '18px',
                  color: $arStyle.color.skyBlueGrey800,
                },
                customStyleAttributes: {
                  padding: '0 10px',
                },
              }"
            :items="dropdownItems"
            :dropdown-max-height="$arMediaQuery.window.maxWidth('xs') ? '50vh' : 'calc(100vh - 207px)'"
            :dropdown-style="{
                width: '180px',
              }"
            @select="handleDropdownItemClick"
          />
        </slot>
      </div>
    </div>
  </div>
</template>
<script>
import accounting from 'accounting'
export default {
  name: 'TableControl',
  props: {
    recipientCount: {
      type: Number,
      default: 0
    },
    dropdownItems: {
      type: Array,
      default: null
    }
  },
  data() {
    return {
      searchString: null
    }
  },
  computed: {
    searchPlaceholder() {
      if (!this.recipientCount || this.$arMediaQuery.window.maxWidth('xs')) {
        return 'Search'
      }
      return `Search ${this.recipientCountText}`
    },
    // Cleans up the fans count into a nice, human-readable string
    recipientCountText() {
      if (!this.recipientCount || this.recipientCount === 0) {
        return 'recipients'
      }
      if (!this.recipientCount === 1) {
        return '1 recipient'
      }
      return `${accounting.formatNumber(this.recipientCount)} recipients`
    },
  },
  methods: {
    handleSearch() {
      this.$emit('search', this.searchString)
    },
    handleSearchClear() {
      this.$emit('clear')
    },
    handleDropdownItemClick(item) {
      item.action()
    }
  },
}
</script>
<style lang="scss" scoped>

@mixin flex-row {
  display: flex;
  flex-flow: row nowrap;
}

.table-control-section {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  width: 100%;
  background: none;

  .table-control-wrapper {
    @include flex-row;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 1200px;
    background: white;
  
    .table-control-container {
      width: 100%;
      padding: 14px;
      @include flex-row;
      align-items: center;
      justify-content: flex-start;
      border: 1px solid $skyBlueGrey500;
      border-bottom: none;
      border-top-right-radius: 5px;
      border-top-left-radius: 5px;
      
  
      .fans-search {
        flex-grow: 1;
        margin-right: 10px;
      }
    }
  }
}
</style>