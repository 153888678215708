var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.isFetchingOverviewMessages)?_c('div',[_c('table',{style:({width: '100%', marginTop: '24px'})},_vm._l((12),function(n){return _c('div',{key:n,staticClass:"tr",style:({display: 'flex'})},[_c('div',{staticClass:"td",style:({
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-start',
          paddingLeft: '12px',
          paddingRight: '12px',
          width: "50%",
          minHeight: "72px",
        })},[_c('am2-typography-skeleton',{style:({ width: "100%", height: '32px', }),attrs:{"size":"md","level":n}})],1),_vm._v(" "),_c('div',{staticClass:"td",style:({
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-start',
          paddingLeft: '12px',
          paddingRight: '12px',
          width: "50%",
          minHeight: "50px",
        })},[_c('am2-typography-skeleton',{style:({ width: "100%", height: '32px', }),attrs:{"size":"md","level":n}})],1)])}),0)]):(_vm.noMessages)?_c('div',{style:({
    marginTop: '16px',
  })},[_c('am2-no-content-section',{attrs:{"icon-name":"ar-message-in-circle","header":_vm.noContentHeader,"body":_vm.noContentBody}})],1):_c('div',[(_vm.inProgress.length > 0)?_c('div',{staticClass:"table-wrapper"},[_c('div',{staticClass:"table-header"},[_c('ar-text',{attrs:{"size":"xs","text":"Sending","weight":"bold"}})],1),_vm._v(" "),_c('am2-messages-table',{style:({
        marginTop: '16px',
      }),attrs:{"head":_vm.getTableHeadings('inProgress'),"messages":_vm.inProgress,"loading":_vm.isFetchingOverviewMessages && _vm.inProgress.length === 0},on:{"cloneMessage":_vm.handleMessageAdded,"archiveMessage":_vm.handleMessageArchivied,"cancelMessage":_vm.handleMessageCancelled,"openMessagePreviewModal":_vm.openMessagePreviewModal}})],1):_vm._e(),_vm._v(" "),(_vm.drafts.length > 0)?_c('div',{staticClass:"table-wrapper"},[_c('div',{staticClass:"table-header"},[_c('ar-text',{attrs:{"size":"xs","text":"Recent Drafts","weight":"bold"}}),_vm._v(" "),_c('ar-text',{staticClass:"header-link",attrs:{"text":("See all drafts" + (_vm.draftsCount > 0 ? (" (" + _vm.draftsCount + ")") : '')),"size":"xs"},nativeOn:{"click":function($event){return _vm.setMessageFilter('draft')}}})],1),_vm._v(" "),_c('am2-messages-table',{style:({
        marginTop: '16px',
      }),attrs:{"head":_vm.getTableHeadings('draft'),"messages":_vm.drafts,"loading":_vm.isFetchingOverviewMessages && _vm.drafts.length === 0},on:{"cloneMessage":_vm.handleMessageAdded,"archiveMessage":_vm.handleMessageArchivied,"cancelMessage":_vm.handleMessageCancelled,"openMessagePreviewModal":_vm.openMessagePreviewModal}})],1):_vm._e(),_vm._v(" "),(_vm.scheduled.length > 0)?_c('div',{staticClass:"table-wrapper"},[_c('div',{staticClass:"table-header"},[_c('div',{staticClass:"table-header-inline"},[_c('ar-text',{attrs:{"size":"xs","text":"Scheduled","weight":"bold"}}),_vm._v(" "),_c('ar-text',{style:({
            color: _vm.$arStyle.color.skyBlueGrey700,
          }),attrs:{"size":"xs","text":("(" + _vm.scheduledCount + " message" + (_vm.scheduledCount > 1 ? 's' : '') + ")")}})],1)]),_vm._v(" "),_c('am2-messages-table',{style:({
        marginTop: '16px',
      }),attrs:{"head":_vm.getTableHeadings('scheduled'),"messages":_vm.scheduled,"loading":_vm.isFetchingOverviewMessages && _vm.scheduled.length === 0},on:{"cloneMessage":_vm.handleMessageAdded,"archiveMessage":_vm.handleMessageArchivied,"cancelMessage":_vm.handleMessageCancelled,"openMessagePreviewModal":_vm.openMessagePreviewModal}})],1):_vm._e(),_vm._v(" "),(_vm.sent.length > 0)?_c('div',{staticClass:"table-wrapper"},[_c('div',{staticClass:"table-header"},[_c('ar-text',{attrs:{"size":"xs","text":"Recently sent","weight":"bold"}}),_vm._v(" "),_c('ar-text',{staticClass:"header-link",attrs:{"text":("See all sent" + (_vm.sentCount > 0 ? (" (" + _vm.sentCount + ")") : '')),"size":"xs"},nativeOn:{"click":function($event){return _vm.setMessageFilter('completed')}}})],1),_vm._v(" "),_c('am2-messages-table',{style:({
        marginTop: '16px',
      }),attrs:{"head":_vm.getTableHeadings('sent'),"messages":_vm.sent,"loading":_vm.isFetchingOverviewMessages && _vm.sent.length === 0},on:{"cloneMessage":_vm.handleMessageAdded,"archiveMessage":_vm.handleMessageArchivied,"cancelMessage":_vm.handleMessageCancelled,"openMessagePreviewModal":_vm.openMessagePreviewModal}})],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }