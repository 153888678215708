var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{directives:[{name:"infinite-scroll",rawName:"v-infinite-scroll",value:(_vm.loadMoreTemplates),expression:"loadMoreTemplates"}],staticClass:"templates-page",attrs:{"infinite-scroll-disabled":"infiniteScrollDisabled","infinite-scroll-distance":10}},[_c('am2-put-email-template-name-modal',{attrs:{"opened":_vm.displayRenameEmailTemplateModal,"loading":_vm.isUpdatingSelectedEmailTemplate,"header":"Rename Email Template","button-copy":"Save","default-name":_vm.emailTemplateToRename && _vm.emailTemplateToRename.meta.name,"default-description":_vm.emailTemplateToRename && _vm.emailTemplateToRename.meta.description},on:{"confirm":_vm.handleRenameEmailTemplateConfirm,"cancel":_vm.handleRenameEmailTemplateCancel}}),_vm._v(" "),_c('am2-put-email-template-name-modal',{attrs:{"opened":_vm.displayNewEmailTemplateModal,"button-copy":"Next"},on:{"confirm":_vm.handleNewEmailTempalteConfirm,"cancel":_vm.handleNewEmailTempalteCancel}}),_vm._v(" "),_c('div',{class:[
      'content',
      _vm.$arMediaQuery.pageContent.maxWidth('sm') && 'sm-max' ]},[_c('am2-top',{style:({
        alignItems: 'flex-start',
      }),attrs:{"searchLeft":{
        action: _vm.handleTemplatesSearch,
        placeholder: 'Search templates',
        style: {
          width: _vm.searchBoxWidth,
          minWidth: '215px'
        }
      }}}),_vm._v(" "),_c('am2-tabs',{style:({
        marginTop: '22px',
      }),attrs:{"selected-tab-index":_vm.selectedTemplateSourceIndex,"items":_vm.templateSourceItems},on:{"select":_vm.handleTemplateSourceSelect}}),_vm._v(" "),(_vm.hasNoDisplayedEmailTemplates)?_c('div',{staticClass:"no-templates-section"},[(_vm.searchString)?_c('am2-no-content-section',{attrs:{"header":"No templates found","header-size":"sm"}}):_c('am2-no-content-section',{attrs:{"icon-name":"ar-template-in-circle","header":"Create an email template","button-text":"New template"},on:{"buttonClick":_vm.handleCreateTemplateClick}})],1):_vm._e(),_vm._v(" "),(_vm.hasNoUnlayerTemplates)?_c('am2-no-content-section',{staticClass:"no-templates-section",attrs:{"icon-name":"ar-template-in-circle","header":"No templates found"}}):_vm._e(),_vm._v(" "),_c('div',{staticClass:"templates-wrapper"},[_vm._l((_vm.emailTemplates),function(emailTemplate){return _c('am2-template-card',{directives:[{name:"show",rawName:"v-show",value:(_vm.filterEmailTemplate(emailTemplate) && !_vm.isFetchingEmailTemplates),expression:"filterEmailTemplate(emailTemplate) && !isFetchingEmailTemplates"}],key:emailTemplate.oid,class:[
          'template-card',
          _vm.$arMediaQuery.pageContent.maxWidth('lg') && 'lg-max',
          _vm.$arMediaQuery.pageContent.maxWidth('md') && 'md-max',
          _vm.$arMediaQuery.pageContent.maxWidth('xs') && 'xs-max' ],attrs:{"name":emailTemplate.meta.name,"description":emailTemplate.meta.description,"template":emailTemplate.meta.template,"template-html":emailTemplate.meta.templateType === 'unlayer' ? _vm.getUnlayerHtml(emailTemplate.meta.template) : emailTemplate.meta.url,"type":emailTemplate.meta.templateType,"thumbnail-url":emailTemplate.meta.thumbnailUrl,"allowed-actions":['edit', 'rename', 'delete']},on:{"edit":function($event){return _vm.handleEmailTemplateEdit(emailTemplate)},"rename":function($event){return _vm.handleEmailTemplateRename(emailTemplate)},"delete":function($event){return _vm.handleEmailTemplateDelete(emailTemplate)},"click":function($event){return _vm.handleEmailTemplateEdit(emailTemplate)}}})}),_vm._v(" "),_vm._l((_vm.unlayerTemplates),function(unlayerTemplate){return _c('am2-template-card',{directives:[{name:"show",rawName:"v-show",value:(_vm.filterUnlayerEmailTemplate(unlayerTemplate)),expression:"filterUnlayerEmailTemplate(unlayerTemplate)"}],key:unlayerTemplate.id,class:[
          'template-card',
          _vm.$arMediaQuery.pageContent.maxWidth('lg') && 'lg-max',
          _vm.$arMediaQuery.pageContent.maxWidth('md') && 'md-max',
          _vm.$arMediaQuery.pageContent.maxWidth('xs') && 'xs-max' ],attrs:{"name":unlayerTemplate.name,"template":unlayerTemplate.design,"template-html":_vm.getUnlayerHtml(unlayerTemplate.design),"type":"unlayer","allowed-actions":['sendMessage', 'createTemplate']},on:{"sendMessage":function($event){return _vm.handleSendMessageFromUnlayerClick(unlayerTemplate)},"createTemplate":function($event){return _vm.handleCreateTemplateFromEditorClick(unlayerTemplate)},"click":function($event){return _vm.handleSendMessageFromUnlayerClick(unlayerTemplate)}}})}),_vm._v(" "),_vm._l((_vm.beefreeTemplates),function(beefreeTemplate){return _c('am2-template-card',{directives:[{name:"show",rawName:"v-show",value:(_vm.filterBeefreeEmailTemplate(beefreeTemplate)),expression:"filterBeefreeEmailTemplate(beefreeTemplate)"}],key:beefreeTemplate.id,class:[
          'template-card',
          _vm.$arMediaQuery.pageContent.maxWidth('lg') && 'lg-max',
          _vm.$arMediaQuery.pageContent.maxWidth('md') && 'md-max',
          _vm.$arMediaQuery.pageContent.maxWidth('xs') && 'xs-max' ],attrs:{"name":beefreeTemplate.name,"template":beefreeTemplate.design,"type":"beefree","allowed-actions":['sendMessage', 'createTemplate'],"template-html":beefreeTemplate.url,"thumbnailUrl":beefreeTemplate.thumbnailUrl},on:{"sendMessage":function($event){return _vm.handleSendMessageFromBeefreeClick(beefreeTemplate)},"createTemplate":function($event){return _vm.handleCreateTemplateFromEditorClick(beefreeTemplate)},"click":function($event){return _vm.handleSendMessageFromBeefreeClick(beefreeTemplate)}}})}),_vm._v(" "),_vm._l((_vm.emailTemplatesSkeletonCardCount),function(n){return _c('am2-card-skeleton',{key:n,class:[
          'template-card',
          _vm.$arMediaQuery.pageContent.maxWidth('lg') && 'lg-max',
          _vm.$arMediaQuery.pageContent.maxWidth('md') && 'md-max',
          _vm.$arMediaQuery.pageContent.maxWidth('xs') && 'xs-max' ],attrs:{"type":"email-template"}})})],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }