<template>
  <section>

    <am2-heading
      type="h1"
      size="lg"
      title="CSV Import Utilities"
      class="u-margin-top-6 u-margin-bottom-2" />
    <ar-text
      class="u-margin-bottom-8"
      multiple-lines
      size="xs"
      text="Use the embedded OneScheme import tool to validate and modify CSV files before importing them into Audience Republic.
            Click the 'Export' button on the final page to download a CSV file with any changes applied." />

    <div class="import-page-wrapper">
      <am2-heading
        type="h3"
        title="Select a CSV import template"
        size="md"
        :style="{ marginBottom: '10px' }" />

      <ar-simple-select
        class="select"
        enable-filter
        :items="csvImportTemplates"
        placeholder="Please select a template"
        @select="handleTemplateSelect"
        :style="{ width: '500px' }"
      />

      <ar-simple-button
        v-if="selectedTemplateCode"
        text="Launch OneSchema Import"
        :style="{ margin: '15px 0 30px' }"
        @click="handleImportClick"
      />

    </div>

  </section>

</template>

<script>
  import { mapGetters, mapActions } from 'vuex';
  import oneschemaImporter from '@oneschema/importer'

  const importer = oneschemaImporter({
    clientId: "22ce1d32-714f-4fe0-8fee-7626901be476",
    importConfig: { type: "local" },
    userJwt: "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiIyMmNlMWQzMi03MTRmLTRmZTAtOGZlZS03NjI2OTAxYmU0NzYiLCJ1c2VyX2lkIjoiPFVTRVJfSUQ-In0.E9-q-CQngg76ZDh8-ySAZJ5rEhp6juPoM7uc_YQrjmE",
    styles: {
      width: '100vw',
      height: '100vh',
      border: 'none',
      position: 'absolute',
      right: '0',
      top: '0',
      'z-index': '1000',
    }
  })

  export default {
    name: 'CSVImport',
    layout: 'default',

    data: () => ({
      selectedTemplateCode: null,
      csvImportTemplates: [{
        name: 'Contacts Only',
        code: 'contact_only'
      }, {
        name: 'Contacts Only (US)',
        code: 'contact_only_US'
      }, {
        name: 'Events Only',
        code: 'events_only'
      }, {
        name: 'Events Only (US)',
        code: 'events_only_US'
      }, {
        name: 'Multi Event + Ticket Imports',
        code: 'multi_events_and_tickets'
      }, {
        name: 'Multi Event + Ticket Imports (US)',
        code: 'multi_events_and_tickets_US'
      }, {
        name: 'Multi Event + POS Orders',
        code: 'multi_event_pos_orders'
      }, {
        name: 'Multi Event + POS Orders (US)',
        code: 'multi_event_pos_orders_US'
      }, {
        name: 'Ticket Orders - Single Event',
        code: 'ticket_orders_single_event'
      }, {
        name: 'Ticket Orders - Single Event (US)',
        code: 'ticket_orders_single_event_US'
      }]
    }),

    computed: {
      ...mapGetters ({
        isAdminAccount: 'auth/isAdminAccount'
      })
    },

    created() {
      if (!this.isAdminAccount) {
        this.$router.push({
          path: '/audience',
        });
      }
    },

    methods: {
      ...mapActions([
      ]),

      handleTemplateSelect(template) {
        this.selectedTemplateCode = template.code;
      },

      handleImportClick() {
        console.log('Launching with: ' + this.selectedTemplateCode);
        importer.launch({ templateKey: this.selectedTemplateCode });

        importer.on("success", (data) => {
          // TODO: handle success
          console.log(data)
        });

        importer.on("cancel", () => {
          // TODO: handle cancel
        });

        importer.on("error", (message) => {
          // TODO: handle errors
          console.log(message)
        });
      },
    },
  };
</script>

<style lang="scss" scoped>
  .import-page-wrapper {
    margin: 30px;

    .rotation {
      animation: rotator 3s infinite linear;
    }
    @keyframes rotator {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(359deg);
      }
    }
  }

  .oneschema-iframe {
    width: 100vw;
    height: 100vh;
    border: none;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1000;
  }
</style>
