<template>
  <am2-card-container
    layout="regular"
    :style="{
      marginBottom: $arMediaQuery.pageContent.minWidth('md') ? '24px' : '12px'
    }"
  >
    <div class="message-recipients-overview-stats">
      <div class="message-recipients-overview-stats-header">
        <ar-text
          class="name"
          :text="`Sent to ${formattedTotal} recipient${overviewStats.total > 1 ? 's' : ''}`"
          size="sm"
        />
        <div class="report-link" @click="handleReportClick">
          <ar-text
            class="name"
            text="View recipients"
            size="xs"
            :style="{
              color: $arStyle.color.purple500
            }"
            data-test-id="recipients-overview-view-recipients-link"
          />
        </div>
      </div>

      <div class="message-recipients-overview-stats-body">
        <RecipientsOverviewRate
          v-if="!isSms"
          :total="overviewStats.total"
          :value="overviewStats.opened"
          title="Open rate"
          link
          @click="goToRecipients('opened')"
          :tooltip="`% of ${isSms ? 'sms' : 'emails'} opened`"
          data-test-id="recipients-overview-open-rate"
        />
        <div v-if="!isSms" class="stats-separator"></div>
        <RecipientsOverviewRate
          :total="overviewStats.total"
          :value="overviewStats.clicked"
          title="Click rate"
          link
          @click="goToRecipients('clicked')"
          :tooltip="`% of recipients who clicked on a link in the ${isSms ? 'sms' : 'email'}`"
          data-test-id="recipients-overview-click-rate"
        />
        <div v-if="!isSms" class="stats-separator"></div>
        <SinglePercentRate
          v-if="!isSms"
          class="single"
          title="Click to open rate"
          :tooltip="`% of recipients who clicked a link in the ${isSms ? 'sms' : 'email'} after opening it`"
          :value="overviewStats.ctor"
        />
      </div>
    </div>

    <div v-if="isAbEmail" class="message-recipients-overview-stats__ab">
      <div class="message-recipients-overview-stats__ab-text">
        <am2-tag type="purple500" shape="rectangle" text="A/B" text-size="10px" />
        <ar-text
          v-if="abPreviewWinner && abType === 'subject'"
          :text="`Test ${abPreviewWinner}'s subject line resulted in a higher open rate`"
          size="xs"
        />
        <ar-text
          v-else-if="abPreviewWinner && abType === 'design'"
          :text="`Test ${abPreviewWinner}'s design resulted in a higher click rate`"
          size="xs"
        />
        <ar-text
          v-else-if="abType === 'subject'"
          :text="`Your subject line test is tied with equal open rates`"
          size="xs"
        />
        <ar-text
          v-else-if="abType === 'design'"
          :text="`Your email design test is tied with equal click rates`"
          size="xs"
        />
      </div>
      <ar-link-button
        text="View report"
        :text-props="{
          size: 'xs'
        }"
        @click="handleAbReviewClick"
      />
    </div>
  </am2-card-container>
</template>

<script>
import RecipientsOverviewRate from './recipients-overview-rate.vue';
import SinglePercentRate from './single-percent-rate.vue';
import accounting from 'accounting';

export default {
  name: 'RecipientsOverviewStats',
  components: { RecipientsOverviewRate, SinglePercentRate },

  props: {
    overviewStats: {
      type: Object,
      default: () => {}
    },
    oid: {
      type: Number,
      default: null
    },
    isSms: {
      type: Boolean,
      default: false
    },
    isAbEmail: {
      type: Boolean,
      default: false
    },
    abPreviewWinner: {
      type: String,
      default: ''
    },
    abType: {
      type: String,
      default: ''
    },
    abVariant: {
      type: String,
      default: 'all'
    },
  },

  computed: {
    formattedTotal() {
      return accounting.formatNumber(this.overviewStats.total);
    }
  },

  methods: {
    handleReportClick() {
      const params = {path: 'recipients'}
      if (this.abVariant !== 'all') {
        params.query = {ab: this.abVariant}
      }
      this.$router.push(params);
    },

    handleAbReviewClick() {
      this.$router.push({
        path: 'ab'
      });
    },

    goToRecipients(viewBy) {
      const params = {
        path: `/message-center/messages/${this.$route.params.oid}/view/recipients`,
        query: { viewBy }
      }
      if (this.abVariant !== 'all') {
        params.query.ab = this.abVariant;
      }

      this.$router.push(params);
    }
  }
};
</script>

<style lang="scss" scoped>
.message-recipients-overview-stats {
  display: flex;
  flex-direction: column;
  position: relative;
  margin: 32px;

  &-header {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 1.2em;
  }

  &-body {
    width: 100%;
    display: flex;
    flex-direction: row;
  }

  .single {
    padding-left: 50px;
    padding-right: 25px;
  }

  .report-link {
    color: $purple500;
    text-decoration: underline;
    cursor: pointer;
  }

  .stats-separator {
    width: 3px;
    background-color: $blueGrey400;
  }

  &__ab {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 16px;
    margin: 32px;
    background: $purple100;
    border-radius: 4px;
  }

  &__ab-text {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
  }
}
</style>
