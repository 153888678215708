var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('portal',{style:({ position: 'absolute' }),attrs:{"to":"modal","disabled":!_vm.opened}},[_c('ar-modal',{staticClass:"email-editor-modal",attrs:{"is-open":_vm.opened,"header":"Edit Email Template","width":"100%","height":"100%","mobile":_vm.$arMediaQuery.window.maxWidth('xs')},on:{"close":_vm.handleModalClose}},[_c('div',{staticClass:"body",attrs:{"slot":"body"},slot:"body"},[_c('div',{staticClass:"top-right-tools"},[_c('ar-link-button',{attrs:{"data-test-id":"automation-email-select-template-link","text":"Select Template","color":_vm.$arStyle.color.$purple500,"textProps":{
            size: 'xs',
          },"disabled":_vm.showSelectEmailTemplateSection || _vm.showSelectEventSection},on:{"click":_vm.handleSelectTemplateSelector}}),_vm._v(" "),(_vm.templateType === 'beefree')?_c('ar-link-button',{staticClass:"u-margin-left-5",attrs:{"data-test-id":"automation-email-select-template-link","text":"Import Dynamic Tags","color":_vm.$arStyle.color.$purple500,"textProps":{
            size: 'xs',
          },"disabled":_vm.showSelectEmailTemplateSection || _vm.showSelectEventSection},on:{"click":_vm.handleImportDynamicTagsSectionClick}}):_vm._e()],1),_vm._v(" "),_c('EmailEditor',{ref:"email-editor",staticClass:"email-editor",attrs:{"id":"automation-email-editor","type":_vm.chosenEmailEditor,"show-dynamic-tags-tool":false,"location":_vm.location},on:{"templateUpdate":_vm.handleDesignOutput,"emailHtmlUpdate":_vm.handleHtmlOutput,"selectEventClick":_vm.handleSelectEventSectionClick,"selectDynamicTag":_vm.handleSelectDynamicTagsSectionClick}}),_vm._v(" "),_c('am2-select-email-template-section',{directives:[{name:"show",rawName:"v-show",value:(_vm.showSelectEmailTemplateSection),expression:"showSelectEmailTemplateSection"}],attrs:{"id":"automation-email-template-section","template-type":_vm.chosenEmailEditor},on:{"select":function (template, templateType, emailHtml) { return _vm.handleTemplateSelect(template, templateType, emailHtml); }}}),_vm._v(" "),_c('am2-select-events-section',{directives:[{name:"show",rawName:"v-show",value:(_vm.showSelectEventSection),expression:"showSelectEventSection"}],attrs:{"id":"automation-event-section","template-type":_vm.chosenEmailEditor},on:{"select":function (event) { return _vm.handleEventSelect(event); }}}),_vm._v(" "),_c('am2-import-dynamic-tags-section',{directives:[{name:"show",rawName:"v-show",value:(_vm.showImportDynamicTagsSection),expression:"showImportDynamicTagsSection"}],attrs:{"is-show":_vm.showImportDynamicTagsSection,"id":"automation-event-section","template-type":_vm.chosenEmailEditor},on:{"import":_vm.handleDynamicTagsImport}}),_vm._v(" "),_c('am2-select-dynamic-tags-section',{directives:[{name:"show",rawName:"v-show",value:(_vm.showSelectDynamicTagsSection),expression:"showSelectDynamicTagsSection"}],attrs:{"dynamic-tags":_vm.dynamicTagsForBeefree},on:{"select":_vm.handleDynamicTagsSelect}})],1),_vm._v(" "),_c('div',{staticClass:"footer",attrs:{"slot":"footer"},slot:"footer"},[_c('ar-simple-button',{style:({
          width: '140px',
        }),attrs:{"text":"Cancel","outlined":"","data-test-id":"automation-email-footer-cancel-button"},on:{"click":_vm.handleCancelClick}}),_vm._v(" "),_c('ar-simple-button',{directives:[{name:"show",rawName:"v-show",value:(!(_vm.showSelectEmailTemplateSection || _vm.showSelectEventSection || _vm.showImportDynamicTagsSection || _vm.showSelectDynamicTagsSection)),expression:"!(showSelectEmailTemplateSection || showSelectEventSection || showImportDynamicTagsSection || showSelectDynamicTagsSection)"}],style:({
          width: '140px',
        }),attrs:{"text":"Done","data-test-id":"automation-email-footer-done-button"},on:{"click":_vm.handleDoneClick}})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }