<template>
  <RendererFrame
    :is-readonly="!disableReadMode && isReadOnly"
    :title="filterCriteria.title"
    :filter-tooltip="filterTooltip"
    :subtitle="expectedPredicateTitle"
    :predicate-as-value="predicateAsValue"
    :readonly-items="readonlyItems"
    :loading="isFetchingItems"
    :apply-disabled="!isEditConditionComplete"
    :condition-button-text="conditionButtonText"
    :hide-button="hideButton"
    @confirm="handleConfirmClick"
    @cancel="handleCancelClick"
    @delete="handleDeleteClick"
    @openChangeMode="isReadOnly = false"
  >
    <div slot="content" class="condition-search-picker">
      <AdditionalConstraintRenderer
        class="mandatory-constraints u-margin-bottom-2"
        v-if="criteriaHasMandatoryConstraints"
        :filter-condition="filterCondition"
        :filter-constraints="filterCriteria.constraints"
        mandatory-constraints
        @constraintUpdate="handleConstraintUpdate"
      />
      <div v-if="!criteriaHasMandatoryConstraints || criteriaHasFilledMandatoryConstraint">
        <div class="function-wrapper">
          <ar-simple-select
            :items="predicatesList"
            :default-select-index="predicateSelectedIndex"
            :style="{ height: '40px' }"
            @select="handlePredicateSelect"
            data-test-id="filter-predicate-selector"
          />
          <am2-search
            v-if="showItems"
            :placeholder="searchPlaceholder"
            :auto-focus="shouldAutoFocus"
            class="search"
            @input="handleSearchStringChange"
            data-test-id="filter-search-input"
          />
        </div>
        <AdditionalConstraintRenderer
          class="optional-constraints u-margin-bottom-3"
          v-if="criteriaHasConstraints"
          :filter-condition="filterCondition"
          :filter-constraints="filterCriteria.constraints"
          @constraintUpdate="handleConstraintUpdate"
        />
        <div
          v-if="showItems"
          :style="{ minHeight: isFetchingItems ? '16px' : null }"
          class="options-wrapper"
        >
          <div class="loading-bubble-wrapper">
            <am2-loading-bubble v-if="isFetchingItems" />
          </div>
          <div
            :class="{
            'options-content': true,
            disabled: isFetchingItems,
          }"
          >
            <div
              v-for="item of displayedCandidateItems"
              :key="item[valueKey]"
              class="checkbox-section"
              :data-test-id="`filter-csp-item-${item.safeName}`"
            >
              <ar-checkbox
                :ref="`checkbox-${item.oid}`"
                class="checkbox"
                v-model="itemTickedMap[item[valueKey]]"
                @change="handleCheckBoxChange(item[valueKey], item)"
                :data-test-id="`filter-csp-checkbox-${item.safeName}`"
              />
              <div class="checkbox-label-section" @click="handleCheckboxLabelClick(item.oid)">
                <div class="checkbox-label-first-line">
                  <ar-text
                    size="xs"
                    v-bind:text="item.name || 'Has been removed'"
                    v-tooltip.top="(item.name || 'Has been removed' | capitalize).length > 26 ? {
                    content: item.name || 'Has been removed' | capitalize
                  } : null"
                    :style="{
                      width: 'calc(100% - 60px)',
                      color: !item.name ? $arStyle.color.red500 : null,
                      margin: '0 8px 4px 0',
                    }"
                  />
                  <ar-text
                    v-if="calculateItemStat(item) !== null"
                    size="xs"
                    :text="calculateItemStat(item)"
                    align="right"
                    :style="{
                      width: '60px',
                      color: $arStyle.color.blueGrey700,
                    }"
                  />
                </div>
                <ar-text
                  v-if="!!item.location"
                  id="filter-purchased-event-location"
                  size="xxs"
                  :text="item.location"
                  :style="{
                    color: $arStyle.color.blueGrey600,
                    marginBottom: '2px',
                  }"
                />
                <ar-text
                  v-if="checkboxSubtitleType === 'date'"
                  size="xxxs"
                  :text="getDateString(item.startDate, item.endDate, item.timeZone)"
                  :style="{
                    color: $arStyle.color.blueGrey600,
                  }"
                />
                <ar-text
                  v-else-if="checkboxSubtitleType === 'eventName'"
                  size="xxxs"
                  :text="item.event && item.event.name"
                  :style="{
                    color: $arStyle.color.blueGrey600,
                  }"
                />
              </div>
            </div>
            <span
              v-if="candidateItemsLength > displayedCandidateItems.length"
              class="show-all"
              data-test-id="filter-show-all-link-true"
              @click="isShowAll = true"
            >
            Show all ({{ candidateItemsLength }})
          </span>
            <span
              v-else-if="
              Object.keys(itemTickedMap).length !== displayedCandidateItems.length
              && displayedCandidateItems.length > 5
            "
              class="show-all"
              data-test-id="filter-show-all-link-false"
              @click="isShowAll = false"
            >
            Show less
          </span>
          </div>
        </div>
      </div>
    </div>
  </RendererFrame>
</template>

<script>
/**
 * TODO: The logic of this componet is getting tricky, we will definitely need to improve it
 */
import { mapState } from 'vuex';
import moment from 'moment';
import { debounce } from 'debounce';
import { clone } from '@/utils/helpers/';
import { filterCriteriaHasConstraint, filterCriteriaHasMandatoryConstraint } from '@/utils/filter';
import { filterCriteriaMandatoryConstraintsFilled } from '~/utils/filter';
import RendererFrame from '../RendererFrame'
import AdditionalConstraintRenderer from '../RendererFrame/AdditionalConstraintRenderer'

const selectString = 'name,startDate,endDate,timeZone,ticketStats,event,capacity,summaryStatsSnapshot,count,location';

export default {
  title: 'ConditionSearchPicker',
  components: {
    RendererFrame,
    AdditionalConstraintRenderer,
  },
  props: {
    filterCriteria: {
      type: Object,
      default: () => ({}),
    },
    filterCondition: {
      type: Object,
      default: () => ({}),
    },
    conditionButtonText: {
      type: String,
      default: 'Apply filter'
    },
    hideButton: {
      type: Boolean,
      default: false
    },
    disableReadMode: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      cachedFilterCondition: this.createEditFilterCondition(this.filterCondition),
      editFilterCondition: this.createEditFilterCondition(this.filterCondition),
      isShowAll: false,
      isReadOnly: false,
      candidateItems: [],
      chosenItems: [],
      itemTickedMap: {},
      isFetchingItems: false,
      showLessCount: 0,
      showItems: true,
      searchString: null,
    };
  },
  computed: {
    ...mapState({
      auth: state => state.auth,
      segment: state => state.segment,
    }),
    shouldAutoFocus() {
      return !this.disableReadMode
    },
    isEditConditionComplete() {
      return this.$arUtils.segment.isSegmentConditionComplete(this.editFilterCondition);
    },
    isCachedConditionComplete() {
      return this.$arUtils.segment.isSegmentConditionComplete(this.cachedFilterCondition);
    },
    filterTooltip() {
      return this.filterCriteria?.description;
    },
    candidateItemsLength() {
      if (this.filterCriteria?.resource !== "segment") {
        return this.candidateItems.length;
      }
      let filteredCandidateItems;
      const candidateItemsOidsWithSegment = this.candidateItems.filter( item => {
        let hasSegment = false;
        item.filter.conditions.forEach( condition => {
          if (condition?.name === "segment") hasSegment = true;
        });
        return hasSegment;
      }).map( item => item.oid);
      filteredCandidateItems = this.candidateItems.filter(item => {
        if (candidateItemsOidsWithSegment.length > 0 && candidateItemsOidsWithSegment.indexOf(item.oid) > -1) {
          return false;
        }
        if (!this.searchString) {
          return true;
        }
        return item.name.includes(this.searchString);
      });
      return filteredCandidateItems.length;
    },
    searchPlaceholder() {
      if (this.filterCriteria.resource === 'events') {
        return 'Search events';
      } else if (this.filterCriteria.resource === 'campaigns') {
        return 'Search campaigns';
      } else if (this.filterCriteria.resource === 'pages') {
        return 'Search Facebook pages';
      } else if (this.filterCriteria.resource === 'tags') {
        return 'Search tags';
      } else if (this.filterCriteria.resource === 'ticketClasses') {
        return 'Search ticket classes';
      }
      return "Search";
    },
    valueKey() {
      if (this.filterCriteria.resource === 'events') {
        return 'oid';
      } else if (this.filterCriteria.resource === 'campaigns') {
        return 'oid';
      } else if (this.filterCriteria.resource === 'pages') {
        return 'puid';
      } else {
        return 'oid';
      }
    },
    checkboxSubtitleType() {
      if (this.filterCriteria.resource === 'events') {
        return 'date';
      } else if (this.filterCriteria.resource === 'campaigns') {
        return 'eventName';
      } else if (this.filterCriteria.resource === 'ticketClasses') {
        return 'eventName';
      } else {
        return null;
      }
    },
    displayedCandidateItems() {
      let candidateItems;

      let filteredCandidateItems = [];
      if (this.filterCriteria?.resource === "segment") {
        filteredCandidateItems = this.candidateItems.filter(item => {
          if (item.name === '_scratch_') return false;
          if (item.name === '_internal_') return false;
          if (item.oid === this.segment?.internalSegment?.oid) return false;
          if (item.oid === this.segment?.scratchSegment?.oid) return false;

          if (!this.searchString) {
            return true;
          }
          return item.name.includes(this.searchString);
        });
      } else {
        filteredCandidateItems = this.candidateItems;
      }


      if (this.isShowAll) {
        candidateItems = filteredCandidateItems;
      } else {
        candidateItems = filteredCandidateItems.slice(
          0,
          this.showLessCount,
        );
      }
      // Tasks don't store their name in item.name - we need to check meta and get it from there if it exists
      return candidateItems.map(item => {
        let candidateName = '';
        if (item.customerName) {
          candidateName = item.customerName;
        } else if (item?.meta?.messageBody?.subject) {
          candidateName = item?.meta.messageBody.subject;
        } else if (typeof item?.meta?.messageBody === 'string') {
          candidateName = item?.meta.messageBody;
        } else if (item?.meta?.messageBody?.preview) {
          candidateName = item.meta.messageBody.preview;
        } else if (typeof item.name === 'string') {
          candidateName = item.name;
        }
        item.name = candidateName;
        item.safeName = candidateName.replaceAll(/ /g, '-').replaceAll(/"/g, '-').replaceAll(/'/g, '-');
        return item;
      });
    },
    expectedPredicateTitle() {
      const clickedPredicate = this.filterCriteria.data.predicates.find(predicate => predicate.value === this.editFilterCondition.data.condition);
      if (this.isReadOnly && clickedPredicate && ["any", "all", "none"].indexOf(clickedPredicate.value) > -1) return null;
      return clickedPredicate ? clickedPredicate.title : null;
    },
    predicateAsValue() {
      const clickedPredicate = this.filterCriteria.data.predicates.find(predicate => predicate.value === this.editFilterCondition.data.condition);
      if (this.isReadOnly && clickedPredicate && ["any", "all", "none"].indexOf(clickedPredicate.value) > -1) return clickedPredicate.title;
      return null;
    },
    predicatesList() {
      return this.filterCriteria.data.predicates.map(predicate => ({
        name: predicate.title,
        class: 'bold',
        value: predicate.value,
      }));
    },
    predicateSelectedIndex() {
      for (let i = 0; i < this.predicatesList.length; i += 1) {
        if (this.predicatesList[i].value === this.editFilterCondition.data.condition) {
          return i;
        }
      }
      return -1;
    },
    readonlyItems() {
      if (this.chosenItems.length === 0) {
        return null;
      }
      const sortedChosenItems = this.sortArrayByDate(this.chosenItems);
      return sortedChosenItems.map(item => {
        let name = item.name;
        if (item.name === 'fan-message' && item?.meta?.messageBody?.subject) {
          name = item.meta.messageBody.subject;
        } else if (item.name === 'fan-message' && item?.meta?.messageBody) {
          name = item.meta.messageBody;
        }
        return {
          name,
          date: this.getDateString(
            item.startDate,
            item.endDate,
            item.timeZone,
          ),
        };
      });
    },
    criteriaHasConstraints() {
      return filterCriteriaHasConstraint(this.filterCriteria);
    },
    criteriaHasMandatoryConstraints() {
      return filterCriteriaHasMandatoryConstraint(this.filterCriteria);
    },
    criteriaHasFilledMandatoryConstraint() {
      return filterCriteriaMandatoryConstraintsFilled(this.filterCriteria, this.editFilterCondition);
    },
  },

  watch: {
    isReadOnly(val) {
      if (val) {
        this.setShowLessCount();
      } else {
        this.fetchCandidateItems('');
      }
    },
    isShowAll(val) {
      if (!val) {
        this.setShowLessCount();
        this.sortCandidateItems();
      }
    },
    chosenItems(val) {
      this.$emit('resourcesUpdate', this.filterCriteria.resource, val)
    },
  },

  async created() {
    if (this.$arUtils.segment.isSegmentConditionComplete(this.editFilterCondition)) {
      // Call api to fetch items from expected items as our initial `candidateItems` and generate 'itemTickedMap'
      this.chosenItems = await this.fetchExpectedItems();
      this.candidateItems = clone(this.chosenItems);
      this.itemTickedMap =  this.getTickedItemMap(this.editFilterCondition.data.values || []);
      // Sort it and set `showLessCount`, also readonly by default
      this.sortCandidateItems();
      this.setShowLessCount();
      this.isReadOnly = true;
    } else {
      this.fetchCandidateItems('');
      this.showLessCount = 5;
    }

    this.debouncedReview = debounce(this.review, 500);
  },

  methods: {
    // Converts expected data values into Strings, in case they're being stored as INTs
    createEditFilterCondition(filterCondition) {
      const newFilterCondition = clone(filterCondition);
      return {
        ...newFilterCondition,
        data: {
          condition: newFilterCondition.data.condition,
          values: newFilterCondition.data.values.map( val => this.valueKey === 'puid' ? val.toString() : val ),
        }
      };
    },
    sortArrayByDate(array) {
      let field;
      if (this.filterCriteria.resource === 'events') {
        return array.sort((a, b) => {
          return moment(a[field]).isAfter(moment(b.startDate)) ? -1 : 1;
        });
      } else if (this.filterCriteria.resource === 'campaigns') {
        return array.sort((a, b) => {
          return moment(a[field]).isAfter(moment(b.sysCtime)) ? -1 : 1;
        });
      } else if (this.filterCriteria.resource === 'tags') {
        return array.sort((a, b) => {
          if (a.external && !b.external) {
            return -1;
          }
          return a - b;
        });
      } else {
        return array;
      }
    },
    sortCandidateItems() {
      let tickedItems = [];
      let untickedItems = [];
      const tickedItemValues = this.getTickedItemValues();

      this.candidateItems.forEach(item => {
        if (tickedItemValues.includes(item[this.valueKey])) {
          tickedItems.push(item);
        } else {
          untickedItems.push(item);
        }
      });
      tickedItems = this.sortArrayByDate(tickedItems);
      untickedItems = this.sortArrayByDate(untickedItems);
      this.candidateItems = [...tickedItems, ...untickedItems];
    },
    calculateItemStat(item) {
      if (this.filterCriteria.resource === 'events') {
        if (item.ticketStats[0]?.attendees) {
          return item.ticketStats[0]?.attendees;
        } else {
          return 0;
        }
      } else if (this.filterCriteria.resource === 'campaigns') {
        if (item.summaryStatsSnapshot.allRegistrations) {
          return item.summaryStatsSnapshot.allRegistrations;
        } else {
          return 0;
        }
      } else if (this.filterCriteria.resource === 'tags') {
        if (item.count) {
          return item.count;
        } else {
          return 0;
        }
      }
      return null;
    },
    getDateString(startDateStr, endDateStr, timeZone) {
      if (!startDateStr && !endDateStr && !timeZone) {
        return null;
      }
      let startDate;
      let endDate;
      if (timeZone) {
        startDate = startDateStr ? moment.utc(startDateStr).tz(timeZone) : moment.utc().tz(timeZone);
        endDate = endDateStr ? moment.utc(endDateStr).tz(timeZone) : startDate;
      } else {
        startDate = startDateStr ? moment.utc(startDateStr) : moment.utc();
        endDate = endDateStr ? moment.utc(endDateStr) : startDate;
      }

      const isMultiDay = startDate.day() !== endDate.day();
      const isMultiYear = startDate.year() !== endDate.year();

      const startDateMinutes = startDate.format('mm');
      const endDateMinutes = endDate.format('mm');
      let startTimeFormat;
      if (startDateMinutes === '00') {
        startTimeFormat = 'ha';
      } else {
        startTimeFormat = 'h:mma'
      }
      let endTimeFormat;
      if (endDateMinutes === '00') {
        endTimeFormat = 'ha';
      } else {
        endTimeFormat = 'h:mma'
      }


      if (isMultiDay && !isMultiYear) {
        return (
          startDate.format(`D MMM, ${startTimeFormat}`) +
          " — " +
          endDate.format(`D MMM YYYY, ${endTimeFormat}`)
        );
      } else if (isMultiYear) {
        return (
          startDate.format(`D MMM YYYY, ${startTimeFormat}`) +
          " — " +
          endDate.format(`D MMM YYYY, ${endTimeFormat}`)
        );
      }
      return startDate.format(`D MMM YYYY, ${startTimeFormat}`);
    },
    getTickedItemValues() {
      let tickedItemValues;
      if (this.valueKey === 'oid') {
        // We have to make sure it's integer instead of string
        tickedItemValues = Object.keys(this.itemTickedMap).map(value => parseInt(value, 10));
      } else {
        tickedItemValues = Object.keys(this.itemTickedMap);
      }
      return tickedItemValues;
    },
    getTickedItems() {
      const tickedItemValues = this.getTickedItemValues();
      return [...this.candidateItems].filter(item => {
        return tickedItemValues.includes(item[this.valueKey]);
      });
    },
    getTickedItemMap(values) {
      const itemTickedMap = {};
      values.forEach(value => {
        itemTickedMap[value] = true;
      });
      return itemTickedMap;
    },
    setShowLessCount() {
      this.showLessCount = this.chosenItems.length > 5 ? this.chosenItems.length : 5;
    },
    review() {
      this.$emit('apply', clone(this.editFilterCondition));
    },
    reviewExpectedData() {
      this.$emit('apply', clone(this.editFilterCondition));
    },
    handleCheckboxLabelClick(oid) {
      this.$refs[`checkbox-${oid}`][0].toggle()
    },
    handleConfirmClick() {
      this.isReadOnly = true;
      // set candidateItems, so you will see all tickedItems on top of the list next time you edit
      this.candidateItems = this.getTickedItems();
      this.sortCandidateItems();
      this.cachedFilterCondition = clone(this.editFilterCondition);
    },
    handleDeleteClick() {
      this.$emit('delete');
    },
    handleCancelClick() {
      if (this.isCachedConditionComplete) {
        if (!this.disableReadMode) {
          this.isReadOnly = true;
          this.editFilterCondition = clone(this.cachedFilterCondition);
          this.itemTickedMap =  this.getTickedItemMap(this.editFilterCondition.data.values || []);
          this.chosenItems = this.getTickedItems();
          this.reviewExpectedData();
        } else {
          this.$emit('delete');
        }
      } else {
        this.$emit('delete');
      }
    },
    handleCheckBoxChange(checkValue, item) {
      // clean map
      if (!this.itemTickedMap[checkValue]) {
        delete this.itemTickedMap[checkValue];
        this.chosenItems = this.chosenItems.filter(item => item[this.valueKey] !== checkValue);
      } else {
        this.chosenItems = [...this.chosenItems, item];
      }

      this.editFilterCondition.data.values = this.getTickedItemValues();

      this.debouncedReview();
    },
    handlePredicateSelect(select) {
      if (["any", "none", "all"].indexOf(select.value) > -1) {
        this.itemTickedMap = {};
        this.chosenItems = [];
        this.editFilterCondition.data.values = [];
        this.showItems = false;
      } else {
        this.showItems = true;
      }
      this.$set(this.editFilterCondition.data, 'condition', select.value);
      this.review();
    },
    handleSearchStringChange(val) {
      this.searchString = val;
      if (this.filterCriteria?.resource !== "segment") {
        this.fetchCandidateItems(val);
      }
    },
    async doAxiosGet(url) {
      return await this.$axios.get(url);
    },
    async fetchExpectedItems() {
      const expectedValues = this.editFilterCondition.data.values;
      this.isFetchingItems = true;
      let url = this.returnFetchUrl(null, expectedValues);

      const { data } = await this.doAxiosGet(url);
      let rows = !!data?.rows ? data.rows : data;
      rows.map(row => ({
        ...row,
        [this.valueKey]: this.valuekey === 'puid' ? row[this.valuekey].toString() : row[this.valuekey],// Typecast puids into strings
      }));
      this.isFetchingItems = false;

      // Sometimes $filter doesn't work, e.g: Facebook Pages, we have to double filter it
      const chosenItems = rows.filter(item => {
        return expectedValues.indexOf(item[this.valueKey]) > -1;
      });

      const chosenItemOids = chosenItems.map(item => item[this.valueKey]);
      // In case the item you selected was removed, important.
      let hasRemovedItemCount = 0;
      expectedValues.forEach(value => {
        if (chosenItemOids.indexOf(value) === -1) {
          hasRemovedItemCount += 1;
        }
      });

      // ****** Very important, if found, help user to apply filter without the removed items ********
      if (hasRemovedItemCount) {
        // All removed, just delete it
        if (hasRemovedItemCount === expectedValues.length) {
          this.$emit('delete');
        } else {
          this.editFilterCondition.data.values = chosenItemOids;
          this.cachedFilterCondition = clone(this.editFilterCondition);
          this.$emit('apply', clone(this.editFilterCondition));
        }
      }

      return chosenItems;
    },

    returnFetchUrl(fetchVal, expectedValues) {
      let url = null;
      const iLike = encodeURIComponent(`ILIKE "%${fetchVal}%"`);
      const { promoterOid } = this.auth.account;
      const resource = this.filterCriteria?.resource;
      const source = this.filterCriteria.data?.source;

      switch (resource) {
        case "optInForms":
          url = !!source
            ? `${source}?$select=${selectString}&$top=200&$orderby=sys_ctime%20desc&$filter=type="opt-in"`
            : `/promoter/${promoterOid}/campaign?$top=200&$select=oid,name,count&$orderby=sys_ctime%20desc&$filter=type="opt-in" AND type != "rsvp"`;
          if (!!fetchVal) url = `${url} AND name ${iLike}`;
          break;
        case "events":
          url = !!source
            ? `${source}?$select=${selectString}&$top=200&$orderby=start_date%20desc&$filter=userDefined`
            : `/promoter/${promoterOid}/event?$select=${selectString}&$top=200&$orderby=start_date%20desc&$filter=userDefined`;
          if (!!fetchVal) url = `${url} AND (name ${iLike} OR location ${iLike})`;
          break;
        case "campaigns":
          url = !!source
            ? `${source}?$select=${selectString}&$top=200&$orderby=sys_ctime%20desc&$filter=type != "opt-in" AND type != "rsvp"`
            : `/promoter/${promoterOid}/campaign?$top=200&$select=${selectString}&$orderby=sys_ctime%20desc&$filter=type != "opt-in" AND type != "rsvp"`;
          if (!!fetchVal) url = `${url} AND name ${iLike}`;
          break;
        case "pages":
          url = !!source
            ? `${source}?$top=200&$orderby=oid%20desc`
            : `/promoter/${promoterOid}/integration-subscription?$top=200&$orderby=oid%20desc`;
          if (!!fetchVal) url = `${url}&$filter=app%3Dmessenger AND name ${iLike}`;
          break;
        case "tags":
          url = !!source
            ? `${source}?$top=550&$orderby=count%20desc`
            : `/promoter/${promoterOid}/tag?$top=550&$orderby=external%20desc,count%20desc`;
          if (!!fetchVal) url = `${url}&$filter=name ${iLike}`;
          break;
        case "ticketClasses":
          url = !!source
            ? `${source}?$top=550&$count=true&$orderby=count%20desc`
            : `/promoter/${promoterOid}/ticket-class?$top=550&$count=true&$orderby=name%20desc`;
          if (!!fetchVal) url = `${url}&$filter=name ${iLike}`;
          break;
        case "segment":
          url = `promoter/${promoterOid}/filter-group/?$top=all&$filter=(userDefined+%3D+true+AND+version+%3D+2)&$select=oid,name,meta,filter,userDefined,favourite&$orderby=favourite+desc,oid+desc`;
          break;
        case "listSubscriptions":
        case "listUnsubscriptions":
          var filter = `promoterOid = ${promoterOid} AND userDefined AND filter_group_oid IS NULL`;
          if (!!fetchVal) filter = `${filter} AND name ${iLike}`;
          url = !!source
            ? `${source}?$top=300&$orderby=count%20desc`
            : `/promoter/${promoterOid}/message-list?$top=300&$orderby=oid%20desc&$filter=${filter}`;
          break;
        case "messagesMarkedAsSpam":
        case "messagesBounced":
          url = !!source
            ? `${source}?$top=200&$orderby=count%20desc`
            : `/promoter/${promoterOid}/fan-message-search?$top=all&$skip=0&$orderby=started+DESC+NULLS+LAST&$provider=email&$status=completed`;
          if (!!fetchVal) url = `${url}&$search=${encodeURIComponent(fetchVal)}`;
          break;
        case "messagesOpened":
        case "messagesNotOpened":
        case "messagesSent":
        case "messagesClicked":
          url = !!source
            ? `${source}?$top=200&$orderby=count%20desc`
            : `/promoter/${promoterOid}/fan-message-search?$top=all&$skip=0&$orderby=started+DESC+NULLS+LAST&$status=completed`;
          if (!!fetchVal) url = `${url}&$search=${encodeURIComponent(fetchVal)}`;
          break;
        case "messagesUndelivered":
          url = !!source
            ? `${source}?$top=200&$count=true&$orderby=count%20desc`
            : `/promoter/${promoterOid}/fan-message-search?$count=true&$top=all&$skip=0&$orderby=started+DESC+NULLS+LAST&$status!=draft`;
          if (!!fetchVal) url = `${url}&$search=${encodeURIComponent(fetchVal)}`;
          break;
        default:
          url = !!source
            ? `${source}?$top=200&$select=${selectString}&$orderby=oid%20desc`
            : null;
          if (!!fetchVal && !!url) url = `${url}&$filter=name ${iLike}`;
          break;
      }

      // For now, we don't want to include oids when fetching messages, as the API doesn't support
      // this yet. Once message filtering is properly supported, we can add it back in.
      if (!!expectedValues
        && expectedValues.length > 0
        && !!url
        && [
          "messagesOpened",
          "messagesNotOpened",
          "messagesSent",
          "messagesClicked",
          "messagesBounced",
          "messagesMarkedAsSpam",
          "messagesUndelivered",
          "segment"
        ].indexOf(resource) < 0)
      {
        let expectedValuesFilter = '';
        if (expectedValues.length > 0) {
          const query = expectedValues.reduce((str, value, index) => {
            str += `${this.valueKey}=${value}`;
            if (index + 1 !== expectedValues.length) {
              str += ` OR `;
            }
            return str
          }, '');
          expectedValuesFilter = expectedValuesFilter ? `${expectedValuesFilter} AND ${query}` : query;
        }
        if (!!expectedValuesFilter && expectedValuesFilter.length > 0) {
          if (url.indexOf('&$filter=') === -1) {
            url = `${url}&$filter=${expectedValuesFilter}`;
          } else {
            url = `${url} AND ${expectedValuesFilter}`
          }
        };
      }

      return url;
    },


    handleConstraintUpdate(item) {
      const { constraint, key, values } = item;
      this.$set(this.editFilterCondition.constraints[constraint], 'condition', key);
      if (!!values) this.$set(this.editFilterCondition.constraints[constraint], 'values', values);
      this.review();
    },

    async fetchCandidateItems(val) {
      let items = [];
      const url = this.returnFetchUrl(val);

      // In near future, some sourceNames will not require an additional fetch, and will instead source the data from the frontend itself (Eg list of countries)
      if (!!url) {
        const { data } = await this.doAxiosGet(url);
        items = !!data?.rows ? data.rows : data;
      }

      // Remove the options already ticked
      const itemTickedMapOids = this.getTickedItemValues();
      const comingDatas = items.filter(item => {
        const valueInInteger = item[this.valueKey];
        if (!itemTickedMapOids.includes(valueInInteger)) {
          return item;
        }
      });

      const tickedItems = this.getTickedItems();
      this.candidateItems = [...tickedItems, ...comingDatas];
      this.sortCandidateItems();
    },
  },
};
</script>

<style lang="scss" scoped>
.condition-search-picker {
  display: flex;
  flex-direction: column;

  .function-wrapper {
    margin-top: 8px;
    margin-bottom: 17px;

    .search {
      margin-top: 10px;
      height: 40px;
    }
  }

  .options-wrapper {
    position: relative;

    .loading-bubble-wrapper {
      display: flex;
      justify-content: center;
    }

    .options-content {
      transition: all 0.3s;
      opacity: 1;

      &.disabled {
        opacity: 0.3;
        pointer-events: none;
      }

      .checkbox-section {
        display: flex;
        margin-bottom: 9px;
        align-items: flex-start;

        .checkbox {
          width: 20px;
        }

        .checkbox-label-section {
          cursor: pointer;
          width: calc(100% - 28px);
          margin-left: 8px;
          .checkbox-label-first-line {
            margin-top: 4px;
            display: flex;
            justify-content: space-between;
          }
        }
      }

      .show-all {
        display: inline-block;
        margin-top: 7px;
        font-size: 12px;
        line-height: 1em;
        color: $purple500;
        letter-spacing: 0;
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }
}
</style>
