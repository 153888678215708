<template>
  <div class="email-body-section">
    <am2-design-email-modal
      :opened="displayDesignEmailModal"
      @designFromScratch="handleDesignFromScratch"
      @template="handleFromTemplate"
      @legacyTemplate="handleFromLegacyTemplate"
      @close="handleDesignEmailClosed"
      :template-type="templateType"
    />

    <div ref="wrapper" class="wrapper">
      <div>
        <div v-if="!selectedEmailHtml" class="edit-email-section" :class="isAbDesign ? 'abEmail' : ''">
          <div class="abEmailSection" v-if="isAbDesign">
            <div class="abEmailSection__main">
              <ar-simple-select
                :style="{ width: '300px', height: '40px' }"
                enable-filter
                class="selector"
                :items="abOptions"
                @select="handleVariantSelectClick"
                :default-select-index="abSwitchIndex"
              />
              <ar-simple-button
                icon-name="edit"
                :icon-props="{
                width: '14px'
              }"
                text="Design email"
                outlined
                :style="{
                height: '40px',
                'margin-left': '16px'
              }"
                @click="handleDesignEmailClick"
              />
            </div>
            <div class="abEmailSection__error">
              <ar-text
                v-if="isEmptyError"
                :text="'A design is required'"
                class="error-subtext"
                weight="normal"
                size="12px"
                :style="{
                'margin-left': '16px'
              }"
              />
            </div>
          </div>

          <img src="~/assets/images/message-center/email-design-image.png" />

          <div v-if="!isAbDesign" class="u-display-flex u-flex-flow-column">
            <ar-simple-button
              icon-name="edit"
              :icon-props="{
                width: '14px'
              }"
              :text="'Design email'"
              outlined
              :style="{
                height: '40px'
              }"
              @click="handleDesignEmailClick"
            />
            <ar-text
              v-if="isEmptyError"
              :text="'A design is required'"
              class="error-subtext"
              weight="normal"
              size="12px"
              :style="{
                'margin-left': '16px'
              }"
            />
          </div>
        </div>
        <div v-else ref="wrapper" class="edit-email-section email-wrapper">
          <div class="abEmailSection" v-if="isAbDesign">
            <div class="abEmailSection__main">
              <ar-simple-select
                :style="{ width: '300px', height: '40px' }"
                enable-filter
                class="selector"
                :items="abOptions"
                @select="handleVariantSelectClick"
                :default-select-index="abSwitchIndex"
              />
              <ar-simple-button
                icon-name="edit"
                :icon-props="{
                width: '14px'
              }"
                text="Edit email"
                outlined
                :style="{
                height: '40px',
                'margin-left': '16px'
              }"
                @click="handleDesignEmailClick"
              />
            </div>
          </div>
          <div v-else class="design-button">
            <ar-simple-button
              icon-name="edit"
              :icon-props="{
                width: '14px'
              }"
              class="edit-email-button"
              text="Edit email"
              outlined
              :style="{
                margin: '24px',
                height: '40px',
                'margin-left': '16px'
              }"
              @click="handleDesignEmailClick"
            />
          </div>
          <div class="email" ref="emailBody" v-html="selectedEmailHtml" :style="{ zoom: computedZoom }"></div>
        </div>
      </div>
      <am2-switch-field-card
        v-if="isAbEmailFeatureEnabled"
        class="u-margin-top-5 ab-email-selector"
        title="A/B test your email"
        :size="'normal'"
        title-size="sm"
        subtitle="Test out variations in design or message details"
        align-center
        :value="isAbEmailEnabled"
        :is-controlled-value="true"
        @input="handleAbEmailChange"
      />
    </div>
      <AbModalOffConfirm
        :opened="isAbModalOffConfirmOpened"
        :ab-type="abType"
        @close="handleAbModalOffClose"
        @confirm="handleAbModalOffConfirm"
      />
  </div>
</template>
<script>

import AbModalOffConfirm from '~/components/modals/message/ab-modal-off-confirm'
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex';
export default {
  name: 'MessageDetailsDesignBlock',
  components: {
    AbModalOffConfirm,
  },
  props: {
    scratchEmailMessage: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      displayDesignEmailModal: false,
      isAbEmailEnabled: false,
      abOptions: [{ name: 'Design A', val: 'A' }, { name: 'Design B', val: 'B' }],
      selectedEmailHtml: '',
      locallySelectedVariant: 'A',
      isAbDesign: false,
      computedZoom: 1,
      isAbModalOffConfirmOpened: false,
      abType: '',
      isEmptyError: false,
    };
  },
  watch: {
    scratchEmailMessage: {
      handler() {
        if (this.scratchEmailMessage && this.scratchEmailMessage.abEmail) {
          this.isAbEmailEnabled = true;
        } else {
          this.isAbEmailEnabled = false;
        }
        if (
          this.scratchEmailMessage &&
          this.scratchEmailMessage.abEmail &&
          this.scratchEmailMessage.abEmailType === 'design'
        ) {
          this.isAbDesign = true;
        } else {
          this.isAbDesign = false;
        }
      },

      deep: true,
      immediate: true
    },
    'scratchEmailMessage.meta.presentation.template': {
      handler(newVal, oldVal) {
        if (newVal && newVal != oldVal) {
          this.handleDesignChange();
        }
      },
      immediate: true
    }
  },
  computed: {
    ...mapGetters({
      injectInfoToScratchEmail: 'message/injectInfoToScratchEmail',
      currentMessageSender: state => state.messageSender.currentMessageSender,
      isFeatureEnabled: 'auth/isFeatureEnabled'
    }),
    ...mapState({
      currentMessageSender: state => state.messageSender.currentMessageSender,
      emailVariants: state => state.message.scratchEmailMessageVariants,
      scratchEmail: state => state.message.scratchEmail,
      emailEditorSetting: state => state.auth.emailEditorSetting,
      selectedVariant: state => state.message.selectedVariant,
    }),

    isAbEmailFeatureEnabled() {
      return (
        this.isFeatureEnabled(['messages', 'abEmails']) &&
        this.scratchEmailMessage?.meta?.presentation?.templateType === 'beefree'
      );
    },

    templateType() {
      return this.scratchEmailMessage?.meta?.presentation?.templateType || null;
    },

    abSwitchIndex() {
      return this.selectedVariant === 'A' ? 0 : 1;
    }
  },
  methods: {
    ...mapActions([
      'emailTemplate/DOWNLOAD_BEEFREE_HTML_FROM_TEMPLATE',
      'message/SWITCH_AB_EMAIL',
      'message/GENERATE_SCRATCH_EMAIL_TEMPLATE'
    ]),
    ...mapMutations([
      'message/SWITCH_AB_EMAIL_STATE',
      'message/PATCH_SCRATCH_EMAIL_MESSAGE',
      'emailTemplate/RESET_EMAIL_TEMPLATES',
      'message/SET_SCRATCH_EMAIL_TEMPLATE',
      'message/RESET_SCRATCH_EMAIL_TEMPLATE',
      'message/SELECT_AB_EMAIL_MESSAGE',
    ]),
    async handleDesignEmailClick() {
      if (this.scratchEmailMessage.meta.presentation.template != null) {
        this.handleDesignFromScratch();
      } else {
        await this['message/PATCH_SCRATCH_EMAIL_MESSAGE']({
          meta: {
            presentation: {
              templateType: this.emailEditorSetting
            }
          }
        });

        this.displayDesignEmailModal = true;
      }
    },
    handleDesignEmailClosed() {
      this.displayDesignEmailModal = false;
    },
    handleDesignFromScratch() {
      this.displayDesignEmailModal = false;
      this.$emit('update');
    },
    async handleFromTemplate() {
      await this['emailTemplate/RESET_EMAIL_TEMPLATES']();
      this.displayDesignEmailModal = false;
      this.$emit('template');
    },
    async handleFromLegacyTemplate() {
      await this['message/PATCH_SCRATCH_EMAIL_MESSAGE']({
        meta: {
          presentation: {
            templateType: 'unlayer'
          }
        }
      });
      this.displayDesignEmailModal = false;
      this.$emit('template');
    },
    handleAbEmailChange(enabled) {
      if (enabled) {
        this.isAbEmailEnabled = true;
        this.$emit('abEmailChange', enabled);
      } else {
        if (this.emailVariants['A']) {
          this.abType = this.emailVariants['A'].abEmailType;
        }

        if (this.abType === 'design' && !this.emailVariants['B'].meta?.presentation?.template
        || this.abType === 'subject' && !this.emailVariants['B'].meta?.messageBody?.subject) {
          this.isAbEmailEnabled = false;
          this.$emit('abEmailChange', false);
          return
        }
        this.isAbEmailEnabled = true;
        this.isAbModalOffConfirmOpened = true;
      }
    },

    handleAbModalOffClose() {
      this.isAbModalOffConfirmOpened = false;
    },

    handleAbModalOffConfirm() {
      this.isAbModalOffConfirmOpened = false;
      this.isAbEmailEnabled = false;
      this['message/SELECT_AB_EMAIL_MESSAGE']('A')
      this.$emit('abEmailChange', false);
    },

    handleVariantSelectClick(item) {
      this.handleVariantChange(item.val);
    },

    handleVariantChange(variantKey) {
      this.$nextTick(() => {
        this.locallySelectedVariant = variantKey;
        this.$emit('variantChange', variantKey);
        this['message/RESET_SCRATCH_EMAIL_TEMPLATE']();
        this['message/SELECT_AB_EMAIL_MESSAGE'](variantKey);
      });
      this.$nextTick(() => {
        this.handleDesignChange();
      });
    },

    async handleDesignChange() {
      await this.$nextTick(async () => {
        if (this.scratchEmailMessage?.meta?.presentation?.templateType === 'beefree') {
          if (typeof this.scratchEmailMessage.meta.presentation.template === 'string') {
            const html = await this['emailTemplate/DOWNLOAD_BEEFREE_HTML_FROM_TEMPLATE']({
              design: this.scratchEmailMessage?.meta?.presentation?.template,
              previewHtml: this.generatedHtml?.previewHtml || null,
              footerHtml: this.generatedHtml?.footerHtml || null
            });

            this.selectedEmailHtml = html;
            this.$emit('unlayerChange', html);
            this['message/SET_SCRATCH_EMAIL_TEMPLATE'](this.selectedEmailHtml);
          } else {
            this.selectedEmailHtml = '';
          }
        } else {
          await this['message/GENERATE_SCRATCH_EMAIL_TEMPLATE'](this.scratchEmailMessage.meta.presentation.template);

          this.selectedEmailHtml = this.injectInfoToScratchEmail(this.currentMessageSender);

          this.$emit('unlayerChange', this.selectedEmailHtml);
        }
      });

      // console.log(this.$refs);
      // const childWidth = this.$refs['emailBody'].getBoundingClientRect().x;
      // const parentWidth = 506;
      // console.log(childWidth);
      // console.log(parentWidth);
      // this.computedZoom = parentWidth / childWidth;
    },
    handleCancel() {
      this.isAbEmailEnabled = false;
    },
    handleEmptyError() {
      this.isEmptyError = true;
    },
    async handleEmptyDynamicTagsFallback() {
      await this.handleDesignEmailClick()
    }
  }
};
</script>
<style lang="scss" scoped>
.email-body-section {
  border: 1px black;
  flex: 1;
  min-width: 500px;
  display: flex;
  flex-flow: row;
  overflow: hidden;
  height: 100%;
  background: #ffffff;
  padding-right: 96px;
  padding-top: 118px;
  flex-direction: column;

  .edit-email-section {
    flex: 1;
    position: relative;
    z-index: 0;
    display: flex;
    flex-direction: column;
    height: 100%;
    min-width: 500px;
    max-width: 506px;
    max-height: 554px;
    min-height: 554px;
    background: #f6f9fc;
    border-radius: 4px;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    border: none;
    gap: 16px;
    &.full-size {
      min-width: unset;
      max-width: 100%;
    }
  }
  .edit-email-section.abEmail {
    justify-content: flex-start;
  }
  .ab-email-selector {
    border: none;
    border-top: 1px solid $blueGrey500;
    border-radius: 0px;
    margin-top: 0px;
    max-width: 506px;
  }
  .wrapper {
    border: 1px solid $blueGrey500;
    border-radius: 4px;
  }
}
.abEmailSection {
  z-index: 1;
  display: flex;
  flex-direction: column;
  padding: 24px;
  &__main {
    display: flex;
    align-items: center;
  }
}
.edit-email-section.email-wrapper {
  justify-content: flex-start;
}
.edit-email {
  z-index: 1;
  display: flex;
  align-items: center;
  padding: 24px;
}
.email-wrapper {
  overflow: hidden;
}
.design-button {
  display: flex;
  align-self: flex-end;
  position: absolute;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0) 100%);
  width: 100%;
  justify-content: flex-end;
}
.email {
  pointer-events: none;
}
.error-subtext {
  color: $red500;
  margin-top: 8px;
}
</style>
