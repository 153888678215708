<template>
  <section
    :class="[
      'wrapper',
      'content',
      'content--centered',
      $arMediaQuery.pageContent.maxWidth('sm') && 'sm-max',
      $arMediaQuery.pageContent.maxWidth('xs') && 'xs-max',
    ]">

    <am2-step-link
      class="step-back-link"
      text="Back to integrations"
      @click="handleBackLinkClick"/>

    <!-- title -->
    <div :class="[
        'title-section',
        $arMediaQuery.pageContent.maxWidth('md') && 'md-max',
      ]">

      <div class="title-section-left">
        <am2-icon-title-section
          v-if="!!integration"
          :title="integration.name"
          :description="integration.description"
          :icon-props="{
            name: integration.provider,
            color: iconColor,
            height: '46px',
            width: '40px',
          }"
        />
      </div>
      <component
          :is="providerModal"
          :should-show="showModal"
          :is-waiting="isWaiting"
          :integration="integration"
          @complete="handleComplete"
          @cancel="handleCancel"
      />
      <div class="title-section-right" v-if="!isWaiting">
        
        <am2-integration-account-select
          v-if="!!providerAccounts && providerAccounts.length > 0 && !!integration && integration.provider !== 'see-tickets-uk'"
          :default-selected-integration-oid="providerAccounts[currentlySelectedIntegrationIndex].oid"
          :available-integrations="integrationsSummary"
          @select="handleAccountChange"
          @new-account="handleNewAccount"
          :style="{ marginRight: '10px', maxWidth: 'calc(100vw - 80px)', }"
        />
        
        <am2-expand-button-dropdown
          align="left"
          :button-props="{ iconName: 'settings' }"
          :items="[
            {
              name: 'Remove Account',
              value: 'removeAccount',
            },
            {
              name: 'Reset Account',
              value: 'resetConnection',
            }
          ]"
          @select="handleSettingsSelect"/>
      </div>
    </div>

    <!-- loading -->
    <section v-if="isWaiting" class="integration-loading-container">
      <am2-loading-bubble/>
    </section>

    <!-- status -->
    <section v-else>
      <SyncBlockContainer
        class="sync-block-container"
        :is-fetching-sync-task="isWaiting"
        :sync-status="syncStatus"
        :sync-time="syncTime"
        :sync-copies="syncCopies"
        :copy-size="'sm'"
      />

    </section>

  </section>
</template>

<script>
import {mapState, mapActions, mapMutations, mapGetters} from 'vuex';
import SyncBlockContainer from '../components/sync-block-container';
import {convertToCamelCase} from "~/utils/helpers";

export default {
  name: 'WorkatoIntegration',
  layout: 'default',
  components: {
    SyncBlockContainer,
  },

  data: () => ({
    currentlySelectedIntegrationIndex: 0,
    showModal: false,
    clientPartnerKey: null,
    privateKey: null,
    accountName: null
  }),

  computed: {
    ...mapState({
      path: state => state.route.path,
      providerCode: state => state.route.params.integration,
      promoter: state => state.auth.account,
      isWaiting: state => state.workatoIntegration.isWaiting,
      integration: state => state.workatoIntegration.integration,
      providerAccounts: state => state.workatoIntegration.providerAccounts
    }),

    providerAccount() {
      return !!this.providerAccounts && this.providerAccounts.length > 0 ? this.providerAccounts[this.currentlySelectedIntegrationIndex] : null;
    },

    providerModal() {
      switch (this.providerAccount?.provider) {
        case 'see-tickets-uk':
          return 'am2-see-tickets-uk-modal';
        case 'mailchimp':
          return 'am2-mailchimp-modal';
        default:
          return null;
      }
    },

    syncStatus() {
      return !!this.providerAccount ? this.providerAccount.status : '';
    },

    syncTime() {
      return !!this.providerAccount ? this.providerAccount.lastSyncTime : null;
    },

    syncCopies() {
      if (!!this.integration) {
        const providerName = this.integration.name;
        const supportEmail = 'support@audiencerepublic.com';
        return {
          'connected': `Your ${providerName} account is currently connected.`,
          'pending': `Please contact Audience Republic via ${supportEmail} to finish connecting your ${providerName} account.`,
          'auth': `Please contact Audience Republic via ${supportEmail} to finish connecting your ${providerName} account.`,
          'stopped': `Your ${providerName} account is currently disconnected. Please contact Audience Republic via ${supportEmail}.`,
          'error': `A problem has occurred with your ${providerName} connection. Please contact Audience Republic via ${supportEmail}.`
        };
      }

      return {};
    },

    iconColor() {
      return !!this.integration ? this.$arStyle.color[convertToCamelCase(this.integration.provider)] : null;
    },

    integrationsSummary() {
      if (!this.providerAccounts) return null;
      const summary = this.providerAccounts
        .map(i => ({
          oid: i.oid,
          name: i.accountName,
          email: i.accountName
        }));
      return summary
    },

    ...mapGetters({}),
  },

  async mounted() {
    await this['workatoIntegration/FETCH_INTEGRATION']({provider: this.providerCode});
    await this['workatoIntegration/LIST_PROVIDER_ACCOUNTS']({integration: this.integration});
  },

  beforeDestroy() {
    this['workatoIntegration/RESET']();
  },

  methods: {
    ...mapActions([
      'SHOW_CONFIRM',
      'workatoIntegration/FETCH_INTEGRATION',
      'workatoIntegration/LIST_PROVIDER_ACCOUNTS',
      'workatoIntegration/DELETE_PROVIDER_ACCOUNT'
    ]),
    ...mapMutations([
      'workatoIntegration/RESET'
    ]),

    handleBackLinkClick() {
      this.$router.push({path: '/settings/integrations'});
    },

    handleComplete() {
      this.showModal = false;
    },

    handleCancel() {
      this.showModal = false;
    },

    handleAccountChange(integration, index) {
      this.currentlySelectedIntegrationIndex = index;
    },

    handleNewAccount() {
      this.showModal = true;
    },

    async handleSettingsSelect(item) {
      switch (item.value) {
        case 'resetConnection':
          await this.resetConnection();
          break;
        case 'removeAccount':
          await this.removeAccount();
          break;
      }
    },

    async resetConnection() {
      const answer = await this.SHOW_CONFIRM({
        messageHtml: `Resetting this account will re-synchronise your data from ${this.integration.name}.`,
        confirmButtonText: 'Reset connection',
        iconName: 'alert-question-mark',
        title: 'Are you sure?',
      });
      if (answer) {
        await this['workatoIntegration/RESET_PROVIDER_ACCOUNT']({
          integration: this.integration,
          oid: this.providerAccount.oid
        });
      }
    },

    async removeAccount() {
      const answer = await this.SHOW_CONFIRM({
        messageHtml: `Removing this account will stop syncing your data from ${this.integration.name}.`,
        confirmButtonText: 'Remove integration',
        iconName: 'alert-question-mark',
        title: 'Are you sure?',
      });
      if (answer) {
        const success = await this['workatoIntegration/DELETE_PROVIDER_ACCOUNT']({
          integration: this.integration,
          oid: this.providerAccount.oid
        });
        if (success) {
          if (this.providerAccounts.length === 0) {
            await this.$router.push({path: '/settings/integrations'});
          }
        }
      }
    }
  }

};
</script>

<style lang="scss" scoped>
.wrapper {

  &.sm-max {
    padding: 0 24px;
  }

  &.xs-max {
    padding: 0 12px;
  }

  .integration-loading-container {
    display: flex;
    justify-content: center;
    margin-top: 65px;
  }

  .step-back-link {
    margin-top: 48px;
  }

  .title-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 48px;

    .title-section-left {
      display: inline-flex;
      align-items: flex-start;

      .tag {
        position: relative;
        margin-left: 10px;
        top: 4px;
      }
    }

    .title-section-right {
      display: inline-flex;
      align-items: center;

      .select {
        width: 300px;
        margin-right: 10px;
      }
    }

    &.md-max {
      flex-direction: column;
      align-items: flex-start;

      .title-section-right {
        margin-top: 16px;
      }
    }
  }

  .sync-block-container {
    margin-top: 64px;
  }
}
</style>
