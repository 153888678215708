<template>
  <div class="table-wrapper">
    <div class="table-header">
      <ar-text
        size="xs"
        :text="tableTitle"
        weight="bold"
      />
    </div>
    <am2-messages-table
      :messages="messagesWithRecipients"
      :head="tableHeadings"
      :loading="isFetchingMessages && messages.length === 0"
      hasStickyHeader
      :style="{
        marginTop: '16px',
      }"
      @cloneMessage="handleMessageAdded"
      @cancelMessage="handleMessageCancelled"
      @openMessagePreviewModal="openMessagePreviewModal"
    />
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex';

export default {
  name: 'MessagesTable',

  props: {
    activeFilter: {
      type: String,
      default: null,
    }
  },

  computed: {
    ...mapState({
      messages: state => state.message.messages,
      isFetchingMessages: state => state.message.isFetchingMessages,
      messageLists: state => state.messageList.messageLists,
    }),

    messagesWithRecipients() {
      return this.messages.map((msg) => {
        const messageListOid = msg.meta.messageListOid;
        const messageList = this.messageLists.find(ml => ml.oid === messageListOid);

        if (messageList)
          return { ...msg, messageList };
        return msg;
      });
    },

    tableTitle() {
      switch(this.activeFilter) {
        case 'completed':
          return 'Sent';
        case 'in-progress':
          return 'Sending';
        case 'draft':
          return 'Drafts';
        case 'scheduled':
          return 'Scheduled';
        case 'failed':
          return 'Cancelled/Failed';
        case 'archived':
          return 'Archived';
      }
    },

    tableHeadings() {
      const tableType = this.activeFilter;
      const commonHeadings = [
        {
          name: 'Message',
          key: 'message',
          format: 'message',
        }
      ];
      const tableHeadings = {
        'in-progress': [
          ...commonHeadings,
          {
            name: '',
            key: 'status',
            format: 'status',
          },
          {
            name: '',
            key: 'menu',
            format: 'menu',
          },
        ],
        scheduled: [
          ...commonHeadings,
          {
            name: '',
            key: 'menu',
            format: 'menu',
          },
        ],
        failed: [
          ...commonHeadings,
          {
            name: 'Edited',
            key: 'edited',
            format: 'edited',
          },
          {
            name: '',
            key: 'menu',
            format: 'menu',
          },
        ],
        draft: [
          ...commonHeadings,
          {
            name: 'Edited',
            key: 'edited',
            format: 'edited',
          },
          {
            name: '',
            key: 'menu',
            format: 'menu',
          },
        ],
        completed: [
          ...commonHeadings,
          {
            name: 'Recipients',
            key: 'recipients',
            format: 'recipients',
          },
          {
            name: 'Opened',
            key: 'opens',
            format: 'opens',
          },
          {
            name: 'Clicked',
            key: 'clicks',
            format: 'clicks',
          },
          {
            name: 'Sent',
            key: 'sent',
            format: 'sent',
          },
          {
            name: '',
            key: 'menu',
            format: 'menu',
          },
        ],
        archived: [
          ...commonHeadings,
          {
            name: 'Archived',
            key: 'edited',
            format: 'edited',
          },
          {
            name: '',
            key: 'menu',
            format: 'menu',
          },
        ],
      };
      
      return tableHeadings[tableType] || [];
    },
  },

  methods: {
    handleMessageAdded() {
      this.$emit('cloneMessage')
    },

    handleMessageCancelled() {
      this.$emit('cancelMessage')
    },

    openMessagePreviewModal(data) {
      this.$emit('openMessagePreviewModal', data)
    },
  },
}
</script>

<style lang="scss" scoped>
.table-wrapper {
  margin-top: 32px;

  .table-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
  }
}
</style>