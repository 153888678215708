var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"email-body-section"},[_c('am2-design-email-modal',{attrs:{"opened":_vm.displayDesignEmailModal,"template-type":_vm.templateType},on:{"designFromScratch":_vm.handleDesignFromScratch,"template":_vm.handleFromTemplate,"legacyTemplate":_vm.handleFromLegacyTemplate,"close":_vm.handleDesignEmailClosed}}),_vm._v(" "),_c('div',{ref:"wrapper",staticClass:"wrapper"},[_c('div',[(!_vm.selectedEmailHtml)?_c('div',{staticClass:"edit-email-section",class:_vm.isAbDesign ? 'abEmail' : ''},[(_vm.isAbDesign)?_c('div',{staticClass:"abEmailSection"},[_c('div',{staticClass:"abEmailSection__main"},[_c('ar-simple-select',{staticClass:"selector",style:({ width: '300px', height: '40px' }),attrs:{"enable-filter":"","items":_vm.abOptions,"default-select-index":_vm.abSwitchIndex},on:{"select":_vm.handleVariantSelectClick}}),_vm._v(" "),_c('ar-simple-button',{style:({
              height: '40px',
              'margin-left': '16px'
            }),attrs:{"icon-name":"edit","icon-props":{
              width: '14px'
            },"text":"Design email","outlined":""},on:{"click":_vm.handleDesignEmailClick}})],1),_vm._v(" "),_c('div',{staticClass:"abEmailSection__error"},[(_vm.isEmptyError)?_c('ar-text',{staticClass:"error-subtext",style:({
              'margin-left': '16px'
            }),attrs:{"text":'A design is required',"weight":"normal","size":"12px"}}):_vm._e()],1)]):_vm._e(),_vm._v(" "),_c('img',{attrs:{"src":require("assets/images/message-center/email-design-image.png")}}),_vm._v(" "),(!_vm.isAbDesign)?_c('div',{staticClass:"u-display-flex u-flex-flow-column"},[_c('ar-simple-button',{style:({
              height: '40px'
            }),attrs:{"icon-name":"edit","icon-props":{
              width: '14px'
            },"text":'Design email',"outlined":""},on:{"click":_vm.handleDesignEmailClick}}),_vm._v(" "),(_vm.isEmptyError)?_c('ar-text',{staticClass:"error-subtext",style:({
              'margin-left': '16px'
            }),attrs:{"text":'A design is required',"weight":"normal","size":"12px"}}):_vm._e()],1):_vm._e()]):_c('div',{ref:"wrapper",staticClass:"edit-email-section email-wrapper"},[(_vm.isAbDesign)?_c('div',{staticClass:"abEmailSection"},[_c('div',{staticClass:"abEmailSection__main"},[_c('ar-simple-select',{staticClass:"selector",style:({ width: '300px', height: '40px' }),attrs:{"enable-filter":"","items":_vm.abOptions,"default-select-index":_vm.abSwitchIndex},on:{"select":_vm.handleVariantSelectClick}}),_vm._v(" "),_c('ar-simple-button',{style:({
              height: '40px',
              'margin-left': '16px'
            }),attrs:{"icon-name":"edit","icon-props":{
              width: '14px'
            },"text":"Edit email","outlined":""},on:{"click":_vm.handleDesignEmailClick}})],1)]):_c('div',{staticClass:"design-button"},[_c('ar-simple-button',{staticClass:"edit-email-button",style:({
              margin: '24px',
              height: '40px',
              'margin-left': '16px'
            }),attrs:{"icon-name":"edit","icon-props":{
              width: '14px'
            },"text":"Edit email","outlined":""},on:{"click":_vm.handleDesignEmailClick}})],1),_vm._v(" "),_c('div',{ref:"emailBody",staticClass:"email",style:({ zoom: _vm.computedZoom }),domProps:{"innerHTML":_vm._s(_vm.selectedEmailHtml)}})])]),_vm._v(" "),(_vm.isAbEmailFeatureEnabled)?_c('am2-switch-field-card',{staticClass:"u-margin-top-5 ab-email-selector",attrs:{"title":"A/B test your email","size":'normal',"title-size":"sm","subtitle":"Test out variations in design or message details","align-center":"","value":_vm.isAbEmailEnabled,"is-controlled-value":true},on:{"input":_vm.handleAbEmailChange}}):_vm._e()],1),_vm._v(" "),_c('AbModalOffConfirm',{attrs:{"opened":_vm.isAbModalOffConfirmOpened,"ab-type":_vm.abType},on:{"close":_vm.handleAbModalOffClose,"confirm":_vm.handleAbModalOffConfirm}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }