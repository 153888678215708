<template>
  <portal to="modal" :disabled="!opened" :style="{ position: 'absolute' }">
    <ar-modal
      :is-open="opened"
      header="Prepare to send"
      width="728px"
      :mobile="$arMediaQuery.window.maxWidth('xs')"
      @close="handleModalClose"
      class="prepare-to-send-modal"
    >
      <div slot="body" class="body">
        <am2-card-container class="u-padding-y-6 u-padding-x-7 u-margin-y-4 message-details">
          <div v-if="isFormComplete">
            <div class="u-display-flex u-align-items-center">
              <ar-icon :color="$arStyle.color.purple500" name="message-center" :class="['icon']" class="message-icon" />
              <div class="message">
                <ar-text
                  class="message-name"
                  size="sm"
                  weight="bold"
                  :text="`${scratchEmailMessage.customerName || 'Email'}`"
                />
                <ar-text size="xs" :text="`is ready to send to ${messageListRecipientCount} recipients`" />
              </div>
            </div>
          </div>
          <div v-if="!isFormComplete">
            <div class="u-display-flex u-align-items-center">
              <ar-icon
                :color="$arStyle.color.purple500"
                name="alert-exclamation-mark"
                :class="['icon']"
                class="message-icon"
              />
              <div class="message">
                <ar-text
                  class="message-name"
                  size="sm"
                  weight="bold"
                  :text="`${scratchEmailMessage.customerName || 'Email'} isn't ready to send`"
                />
                <div class="error-message">
                  <span class="error-message__text">You still need to complete the </span>
                  <span v-for="(item, index) in validationErrors" :key="item.label">
                      <span
                        class="error-message__error-item"
                        @click="handleClickError(item)"
                      >{{ item.label }}</span>{{ (index + 1 !== validationErrors.length) ? ', ' : '' }}
                    </span>
                  <span class="error-message__text">{{ validationErrors.length > 1 ? 'sections' : 'section' }}</span>
                  <span class="error-message__text">of your email campaign.</span>
                </div>
              </div>
            </div>
          </div>
        </am2-card-container>
        <am2-card-container class="u-padding-y-6 u-padding-x-7 u-margin-y-4 schedule-message">
          <div class="u-display-flex u-align-items-center u-justify-content-space-between">
            <div><ar-text size="xs" weight="bold" text="Schedule Message" /></div>
            <am2-switch
              :value="enableSchedule"
              @input="handleEnableScheduleToggle"
              :disabled="!currentMessageListCanBeSchedued"
              v-tooltip.top="{
                content:
                  !currentMessageListCanBeSchedued && !enableSchedule
                    ? 'You cannot schedule messages from campaigns'
                    : null
              }"
            />
          </div>
          <div
            v-if="enableSchedule"
            class="u-margin-top-6"
            :style="{
              display: 'flex',
              alignItems: 'baseline'
            }"
          >
            <div class="u-flex-grow-1">
              <am2-date-time-input
                :value="scheduledAt"
                @input="handleScheduledAtInput"
                :time-zone="timeZone"
                has-label
                v-validate="'required|tzFormat|afterNow|after5MinFromNow'"
                data-vv-name="scheduledAt"
                data-vv-as="scheduled time"
                :style="{
                  width: '100%'
                }"
                data-test-id="message-scheduled-at"
              />
              <ar-state-message
                v-if="veeErrors.has('scheduledAt')"
                :style="{ marginTop: '8px' }"
                type="error"
                :text="veeErrors.first('scheduledAt')"
              />
            </div>
            <ar-divider
              :style="{
                position: 'relative',
                top: '38px',
                width: '20px',
                flexShrink: '0',
                margin: '0 10px'
              }"
            />
            <div
              :style="{
                width: '205px',
                flexShrink: '0'
              }"
            >
              <ar-text
                class="u-margin-bottom-3"
                size="xs"
                text="Timezone"
                :style="{
                  color: $arStyle.color.blueGrey700
                }"
              />
              <am2-timezone-select
                :value="timeZone"
                @input="handleTimezoneInput"
                v-validate="'required'"
                data-vv-name="timeZone"
                data-vv-as="timezone"
              />
              <ar-state-message
                v-if="veeErrors.has('timeZone')"
                :style="{ marginTop: '8px' }"
                type="error"
                :text="veeErrors.first('timeZone')"
              />
            </div>
          </div>
        </am2-card-container>
      </div>
      <div slot="footer" class="footer">
        <ar-link-button
          text="Cancel"
          :disabled="isEmailSending"
          @click="handleCancelClick"
          :style="{
            height: '40px',
            padding: '0 20px'
          }"
        />
        <ar-simple-button
          v-if="!enableSchedule"
          :disabled="!isFormComplete"
          text="Send Now"
          @click="handleSendClick"
          :loading="isEmailSending"
        />
        <ar-simple-button
          v-if="enableSchedule"
          :disabled="!isFormComplete"
          :loading="isEmailSending"
          text="Schedule Message"
          @click="handleSendClick"
        />
      </div>
    </ar-modal>
  </portal>
</template>

<script>
import { mapGetters, mapState, mapMutations, mapActions } from 'vuex';
import moment from 'moment';
export default {
  name: 'PrepareToSendModal',
  components: {},
  props: {
    opened: {
      type: Boolean,
      default: false
    },
    isEmailSending: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      enableSchedule: false,
      formHasErrors: true,
      emailAddresses: '',
      scheduledAt: '',
      timeZone: ''
    };
  },
  created() {
    this.$validator.extend('afterNow', {
      getMessage: field => `Scheduled time is invalid - time must be in the future`,
      validate: value => {
        return moment(value).isAfter(new Date());
      }
    });
    this.$validator.extend('after5MinFromNow', {
      getMessage: field => `Scheduled time is invalid - must be more than 5 minutes from now`,
      validate: value => {
        return moment(value).isAfter(moment().add(5, 'minutes'));
      }
    });
  },
  computed: {
    ...mapState({
      scratchEmailMessage: state => state.message.scratchEmailMessage,
      scratchEmailMessageVariants: state => state.message.scratchEmailMessageVariants,
      currentMessageList: state => state.messageList.currentMessageList,
      filteredRecipientListCount: state => state.messageList.filteredRecipientListCount
    }),
    ...mapGetters({
      currentMessageListCanBeSchedued: 'messageList/currentMessageListCanBeSchedued',
      currentSelectedMessageUiStatus: 'message/currentSelectedMessageUiStatus'
    }),
    isFormComplete() {
      return this.validate().length === 0;
    },
    validationErrors() {
      return this.validate();
    },
    messageListRecipientCount() {
      // filtering doesn't work on campaign message lists so just use total from snapshot
      if (!!this.currentMessageList?.campaignOid) {
        return this.currentMessageList?.statsSnapshot?.total || 0;
      }
      return this.filteredRecipientListCount;
    }
  },
  watch: {
    opened(newVal) {
      if (newVal) {
        this.initEmailEditor();
      }
    }
  },

  methods: {
    ...mapMutations({
      patchScratchEmail: 'message/PATCH_SCRATCH_EMAIL_MESSAGE'
    }),
    ...mapActions({
      fetchFitleredRecipientListCount: 'messageList/FETCH_FILTERED_RECIPIENT_LIST_COUNT'
    }),
    async initEmailEditor() {
      if (this.scratchEmailMessage.scheduledAt != null) {
        this.scheduledAt = this.scratchEmailMessage.scheduledAt;
        this.timeZone = this.scratchEmailMessage.meta.presentation.timeZone;
        this.enableSchedule = true;
      }
      await this.$nextTick(() => {
        this.fetchFitleredRecipientListCount({ channel: 'email' });
      });
    },
    handleModalClose() {
      this.$emit('close');
    },
    handleCancelClick() {
      this.$emit('cancel');
    },
    async handleSendClick() {
      const isValid = await this.$validator.validateAll();
      if (!isValid) {
        this.$arNotification.push({ type: 'error', message: 'Could not send message, please review error messages' });
        return;
      }
      if (this.scheduledAt) {
        this.patchScratchEmail({
          meta: {
            presentation: {
              timeZone: this.timeZone
            }
          },
          scheduledAt: this.scheduledAt
        });
      }
      this.$emit('send');
    },
    handleScheduledAtInput(scheduledAt) {
      this.scheduledAt = scheduledAt;
    },
    handleTimezoneInput(timeZone) {
      this.timeZone = timeZone;
    },
    handleEnableScheduleToggle(toggle) {
      this.enableSchedule = toggle;
      if (!toggle) {
        this.clearScheduleTime();
      }
    },
    clearScheduleTime() {
      this.patchScratchEmail({
        meta: {
          presentation: {
            timeZone: null
          }
        },
        scheduledAt: null
      });
      this.scheduledAt = null;
      this.timeZone = null;
    },
    handleClickError(error) {
      this.$emit('clickError', error)
      this.$emit('close')
    },
    validate() {
      const isABEmail = this.scratchEmailMessage.abEmail;
      const isABSubjectTreatment = this.scratchEmailMessage.abEmailType === 'subject';
      const messageMeta = this.scratchEmailMessage.meta;
      const messageVariants = this.scratchEmailMessageVariants;
      let errors = [];

      if (!messageMeta.messageListOid) {
        errors.push({ type: 'to', label: 'To' });
      }
      if (!messageMeta.email.promoterPropertyOid) {
        errors.push({ type: 'from', label: 'From' });
      }

      if (isABEmail && isABSubjectTreatment) {
        for (const key of Object.keys(messageVariants)) {
          if (!messageVariants[key].meta.messageBody.subject) {
            errors.push({ type: 'subject', label: 'Subject ' + key, variant: key });
          }
        }
      } else {
        if (!messageMeta.messageBody.subject) {
          errors.push({ type: 'subject', label: 'Subject' });
        }
      }

      if (isABEmail && !isABSubjectTreatment) {
        for (const key of Object.keys(messageVariants)) {
          if (!messageVariants[key].meta.presentation.template) {
            errors.push({ type: 'design', label: 'Design ' + key, variant: key });
          }
        }
      } else {
        if (!messageMeta.presentation.template) {
          errors.push({ type: 'design', label: 'Design' });
        }
      }

      if (messageMeta.fallbackDynamicTags) {
        for (const key of Object.keys(messageMeta.fallbackDynamicTags)) {
          if (!messageMeta.fallbackDynamicTags[key]) {
            errors.push({ type: 'dynamicTagsFallback', label: 'Dynamic tags fallback' });
            break;
          }
        }
      }

      return errors;
    }
  }
};
</script>

<style lang="scss" scoped>
.prepare-to-send-modal {
  .body {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    margin: 32px;
    .schedule-message {
      width: 100%;
    }
    .message-details {
      width: 100%;
      .message {
        display: flex;
        flex-direction: column;
        gap: 8px;
      }
      .message-name {
        margin-bottom: 8px;
      }
    }
  }
  .footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 100px;
    padding: 0 30px;
  }
  .message-icon {
    background: #f6f9fc;
    border-radius: 175px;
    width: 72px;
    height: 72px;
    justify-content: center;
    margin-right: 24px;
  }
  .error-message {
    white-space: normal;
    word-break: break-word;

    &__error-item {
      color: $purple500;
      text-decoration: underline;
      cursor: pointer;
    }
  }
}
</style>
