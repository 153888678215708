<template>
<div class="sales-chart-wrapper">
  <div class="sales-chart-nav-wrapper">
    <SalesChartHeadingDropdown
      :has-data="hasData"
      v-model="selectedHeading"
      :options="headingOptions"
    />

    <div class="sales-chart-nav-items-wrapper">
      <ar-link-button
        v-if="selectedTimescaleOption.value !== 'increments'"
        class="timescale-reset-button"
        text="Reset"
        :has-underline="true"
        :color="$arStyle.color.$purple500"
        @click="handleTimescaleReset"
        data-test-id="timescale-reset-button"
      />
      <CompareEventsDropdown
        :has-data="hasData"
        :options="showedCompareOptions"
        :compare-from="compareFrom"
        @compare-from-update="compareFrom = $event"
        @compare="compareEventsHandler"
        @filter="handleCompareOptionsFilter"
        @clear="handleEventCompareDeselect"
        @load-more="handleEventCompareLoadMore"
        :timescale-active="timescaleActive"
      />

      <TimescaleDropdown
        :has-data="hasData"
        v-model="selectedTimescaleOption"
        :options="timescaleDropdownOptions"
      />

      <SettingsDropdown
        v-model="activeSetting"
        @annotations="toggleAnnotations"
        @update="handleUpdateState"
        :with-annotations="withAnnotations"
        :timescale-active="timescaleActive"
      />

      <am2-elegant-tabs
        :items="salesChartTabItems"
        :tab-key="0"
        @select="handleSalesTopicChange"
        :style="{
          minWidth: '200px',
          pointerEvents: !this.hasData ? 'none' : '',
        }"
        layout="wide"
      />
    </div>
  </div>

  <div v-if="containsMultiEventData" class="comparing-to-wrapper">
    <ar-text
      v-if="!!compareEventNames"
      class="comparing-to-copy"
      size="14px"
      text="Comparing to: "
      multiple-lines
      :max-lines="1"
      line-height="20px"
    />

    <template v-for="(name, index) in compareEventNames">
      <ar-text
        :key="index"
        class="comparing-to-item"
        size="14px"
        :text="name"
        multiple-lines
        :max-lines="1"
        line-height="20px"
      />
    </template>

    <ar-link-button
      v-if="!!compareEventNames"
      class="comparing-to-clear-button"
      text="clear"
      :has-underline="true"
      :color="$arStyle.color.$purple500"
      @click="handleCompareClear"
      data-test-id="comparing-to-clear-button"
    />
  </div>

  <div class="sales-chart-container">
    <div
      class="apex-sales-chart-wrapper"
      :style="{
        height: chartWrapperHeight
      }"
    >
      <am2-apex-utility-chart
        class="apex-chart"
        chart-id="ticketSalesUtilityChart"
        :series="series"
        :options="chartOptions"
        :annotations="eventAnnotations"
        :annotationHandlers="annotationHandlers"
        tooltipPlacement="vertical"
        :with-annotations="withAnnotations"
        :timezone="selectedTimezone"
        :loading="chartLoading"
        :animation="chartAnimation"
        :render-key="chartRenderKey"
        :selectedToolbarIcon="selectedToolbarIcon"
        @toolbar-click="handleCategoryToolbarSelection"
        @add-drag-listener="handleAddDragListener"
        :has-drag-listener="dragListenerAdded"
      >
        <!-- To customize annotations and pull out
        individual annotation data, do the below.
        This chart component emits the following events,
        @chartMounted,
        @chartUpdated,
        @markerClick,
        ..and passes the chart config at that point in time
        with the event. With the annotation data below, and the
        chart config through the above events you will be able
        to style and configure the annotations as you please :)

        <div slot-scope="annotation">
          <span>{{annotation.annotation.leftCalc}}</span>
          <span>{{annotation.annotation.timeMillis}}</span>
          etc etc
        </div>

        ** NOTE - you don't need to configure the annotations. The
        default styling will be inherited if you leave as is. -->
      </am2-apex-utility-chart>
    </div>
  </div>
</div>
</template>
<script>
import moment from 'moment'

import accounting from 'accounting'
import { amountInDollars, centsRemaining } from '@/utils/helpers'
import {mapState, mapGetters, mapMutations} from 'vuex'
import { mapActions } from 'vuex'
import { displayDateRangeUSNoDay } from '@/utils/date/'
import SalesChartHeadingDropdown from './components/SalesChartHeadingDropdown.vue'
import CompareEventsDropdown from './components/CompareEventsDropdown.vue'
import TimescaleDropdown from './components/TimescaleDropdown.vue'
import SettingsDropdown from './components/SettingsDropdown.vue'

import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import weekOfYear from 'dayjs/plugin/weekOfYear'
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(localizedFormat)
dayjs.extend(weekOfYear)

const waitFor = delay => new Promise(resolve => setTimeout(resolve, delay))

export default {
  name: 'TicketSalesChartSection',
  components: {
    SalesChartHeadingDropdown,
    CompareEventsDropdown,
    TimescaleDropdown,
    SettingsDropdown,
  },
  props: {
    filter: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      isTimescaleView: false,
      chartLoading: false,
      selectedToolbarIcon: null,
      chartAnimation: null,
      chartRenderKey: null,
      cats: null,
      originalCats: null,
      catsFullString: null,
      activeSetting: null,
      changeTimezoneActive: false,
      withAnnotations: true,
      chartOptions: null,
      series: [],
      rawSeriesData: [],
      uncollapsedChartData: [],
      zoom: null,

      currentCompareKeys: [],
      compareFrom: 'start',
      oldFilter: null,
      headingOptions: [
        {
          name: 'Total ticket sales',
          value: 'totalTicketSales',
        },
        {
          name: 'Incremental ticket sales',
          value: 'incrementalTicketSales',
        },
      ],
      selectedHeading: { name: 'Total ticket sales', value: 'totalTicketSales' },
      timescaleDropdownOptions: [
        {
          name: 'Increments',
          value: 'increments',
        },
        {
          name: 'Monthly',
          value: 'monthly',
        },
        {
          name: 'Weekly',
          value: 'weekly',
        },
        {
          name: 'Daily',
          value: 'daily',
        },
      ],
      selectedTimescaleOption: { name: 'Increments', value: 'increments' },
      ticketSalesChartTopic: 'Sales',
      salesChartTabItems: [
        {
          key: 0,
          name: 'Sales'
        },
        {
          key: 1,
          name: 'Quantity'
        }
      ],
      annotationHandlers: {
        create: this.handleAnnotationCreate,
        update: this.handleAnnotationUpdate,
        delete: this.handleAnnotationDelete,
      }
    }
  },
  watch: {
    timescaleData() {
      if (this.selectedTimescaleOption.value !== 'increments') {
        this.handleTimescaleSelect()
      }
    },

    selectedTimescaleOption(newVal, oldVal) {
      if (oldVal.value === 'increments' && newVal.value !== oldVal.value) {
        this.isTimescaleView = true
        this.selectedHeading = { name: 'Incremental ticket sales', value: 'incrementalTicketSales' }
      }
      this.selectTimescaleOption(newVal.value, oldVal.value)
    },

    isSmMedia: {
      immediate: true,
      handler() {
        this.chartOptions = {...this.chartOptions}
      }
    },

    containsMultiEventData() {
      this.chartOptions = {...this.chartOptions}
      if (this.containsMultiEventData) {
        this.withAnnotations = false
      } else {
        this.withAnnotations = true
      }
    },

    selectedHeading(newVal) {
      if (this.selectedHeading.value === 'totalTicketSales' && this.selectedTimescaleOption.value !== 'increments') {
        this.isTimescaleView = false
        this.handleTimescaleReset()
      }

      if (this.isTimescaleView) {
        return
      }

      if (newVal.value === 'incrementalTicketSales') {
        this.switchIncrementalView()
      } else if (newVal.value === 'totalTicketSales') {
        this.switchCumulativeView()
      }

    },

    chartData() {
      if (this.selectedTimescaleOption.value === 'increments') {
        this.refreshSeriesDataForChart()
      }
    },

    ticketSalesChartTopic() {
      if (this.ticketSalesChartTopic === 'Sales') {
        this.setSalesDataView()
      } else if (this.ticketSalesChartTopic === 'Quantity') {
        this.setQuantityDataView()
      }
    },

    eventAndSalesDataLoaded() {
      this['event/RESET_ALL_EVENTS_LIST']()
      this.loadEventsOptions()

      if (this.eventAndSalesDataLoaded) {
        this.setDateTimeOptions('area')
        this.setInitialTicketData(this.eventTicketSales)
        this.setInitialTimescaleData(this.eventTicketSales)
      }
    },

    async eventTicketSales(newVal, oldVal) {
      if (oldVal != null && (!Object.is(this.oldFilter, this.filter) || !Object.is(newVal, oldVal))){
        this.oldFilter = this.filter

        this.setDateTimeOptions('area')
        this.setInitialTicketData(this.eventTicketSales)
        this.setInitialTimescaleData(this.eventTicketSales)

        if (this.compareIds && Object.keys(this.compareIds).length > 0) {
          let promises =[]

          Object.entries(this.compareIds).forEach(([key, val]) => {
            if (val) {
              let { name, oid } = this.compareOptions.find(item => {
                return item.oid !== this.currentEvent.oid && item.oid === parseInt(key)
              })
              promises.push(this.fetchCompareTicketSalesData({oid, name, filter: this.prunedScratchSegment.filter}))
            }
          })

          try {
            let multipleEventDataArray = await Promise.all(promises)
            this.setCompareDataSets(multipleEventDataArray)
            this.setDateTimeOptions('line')
          } catch(error) {
            console.log('Something went wrong setting compare data:', error)
          } finally {
            if (!!this.chartLoading) this.chartLoading = false
          }
        }
      }
    },

    currentEvent(newVal, oldVal) {
      if (!!newVal && !oldVal) {
        this.fetchAnnotations()
      }
    },
  },
  computed: {
    ...mapState({
      currentEvent: state => state.event.currentEvent,
      eventTicketSales: state => state.event.eventTicketSales,
      dragListenerAdded: state => state.event.dragListenerAdded,
      optionsEventsLoaded: state => state.event.eventsList,
      compareOptions: state => state.event.selectedEventsList,
    }),

    ...mapGetters({
      getEventAnnotations: 'event/getEventAnnotations',
      selectedTimezone: 'event/getSalesChartTimezone',
      rawSalesChartData: 'event/rawSalesChartData',
      timescaleData: 'event/getTimescaleData',
      prunedScratchSegment: 'segment/prunedScratchSegmentFilter',
      compareIds: 'event/getSelectedEventListIdsObject'
    }),

    timescaleActive() {
      return this.selectedTimescaleOption.value !== 'increments'
    },

    chartWrapperHeight() {
      return this.setResponsiveChartHeight()
    },

    isSmMedia() {
      return this.$arMediaQuery.window.maxWidth('sm')
    },

    eventAnnotations() {
      if (!this.getEventAnnotations.length || !this.currentEvent) return []

      let annos = this.getEventAnnotations.map(anno => {
        let millis = moment(anno.timestamp).tz(this.currentEvent.timeZone).toDate().getTime()
        return {
          ...anno,
          status: 2,
          infoWrapperWidth: 315,
          timeMillis: millis,
        }
      })

      return annos
    },

    hasData() {
      let salesData = this.cumulativeSalesOrBackwardsCompatibleSales(this.eventTicketSales)
      return !!salesData
    },

    compareEventNames() {
      let names = []
      if (this.series.length > 1) {
        let i = this.series.length
        while (i-- && i > 0) {
          names.push(
            i === 1 ? this.series[i].name : `${this.series[i].name},`
          )
        }
      }

      return names
    },

    chartData() {
      return this.$store.getters["event/salesChartData"]
    },

    containsMultiEventData() {
      return this.chartData.quantity?.incremental?.length > 1
    },

    eventAndSalesDataLoaded() {
      return !!this.eventTicketSales && !!this.currentEvent
    },

    getIncrementsMarkerCopy() {
      if (this.selectedTimescaleOption.value === 'monthly') {
        if (this.ticketSalesChartTopic === 'Sales') {
          return 'Monthly sales'
        } else {
          return 'Monthly tickets'
        }
      } else if (this.selectedTimescaleOption.value === 'weekly') {
        if (this.ticketSalesChartTopic === 'Sales') {
          return 'Weekly sales'
        } else {
          return 'Weekly tickets'
        }
      } else if (this.selectedTimescaleOption.value === 'daily') {
        if (this.ticketSalesChartTopic === 'Sales') {
          return 'Daily sales'
        } else {
          return 'Daily tickets'
        }
      } else {
        console.log('Something went wrong setting `getIncrementsMarkerCopy` copy')
      }
    },

    getMarkerCopy() {
      let copy
      switch(this.ticketSalesChartTopic) {
        case 'Sales':
          copy =  'Total ticket sales'
          break
        case 'Quantity':
          copy =  'Total ticket quantity'
          break
        default:
          console.log('An unrecognised topic was received', this.ticketSalesChartTopic)
      }

      return copy
    },

    showedCompareOptions() {
      let compareOptions = this.optionsEventsLoaded.map(item => {
        return {
          oid: item.oid,
          name: item.name,
          location: item.location,
          dateString:  displayDateRangeUSNoDay(dayjs(item.startDate).tz(this.selectedTimezone).valueOf(), null, this.selectedTimezone),
        }
      })

      return compareOptions.filter(item => item.oid !== this.currentEvent?.oid)
    }
  },

  methods: {
    ...mapActions({
      fetchEvents: 'event/FETCH_EVENTS_LIST',
      fetchCompareTicketSalesData: 'event/FETCH_COMPARE_EVENT_TICKET_SALES_DATA',
      setInitialChartData: 'event/SET_INITIAL_TICKET_SALES_CHART_DATA',
      setOverlayData: 'event/SET_OVERLAY_DATA',
      createAnnotation: 'event/CREATE_ANNOTATION',
      fetchAnnotations: 'event/FETCH_ANNOTATIONS',
      deleteAnnotation: 'event/DELETE_ANNOTATION',
      updateAnnotation: 'event/UPDATE_ANNOTATION',
      setInitialTimescaleData: 'event/SET_INITIAL_TIMESCALE_DATA',
      clearCompareTimescaleData: 'event/CLEAR_COMPARE_TIMESCALE_DATA',
      setDragListenerState: 'event/SET_DRAG_LISTENER_STATE'
    }),

    ...mapMutations([
      'event/RESET_EVENTS_LIST',
      'event/SET_EVENTS_LIST_SEARCH',
      'event/CLEAR_SELECTED_EVENTS_LIST',
      'event/RESET_ALL_EVENTS_LIST',
      'event/SET_UNSAVED_EVENTS_LIST',
    ]),

    handleUpdateState(bool) {
      this.chartLoading = bool
    },

    selectTimescaleOption(val) {
      if (val === 'increments') return

      this.handleTimescaleSelect()
    },

    async setSalesDataView() {
      if (!this.chartLoading) this.chartLoading = true
      await waitFor(200)
      if (!this.isTimescaleView) {
        let headingType = this.selectedHeading.value === 'incrementalTicketSales' ? 'incremental' : 'accumulative'
        this.series = Object.freeze(this.chartData['sales'][headingType])
      } else {
        this.series = Object.freeze(this.timescaleData['sales'].incremental[this.selectedTimescaleOption.value])
      }
      if (!!this.chartLoading) this.chartLoading = false
    },

    async setQuantityDataView() {
      if (!this.chartLoading) this.chartLoading = true
      await waitFor(200)
      if (!this.isTimescaleView) {
        let headingType = this.selectedHeading.value === 'incrementalTicketSales' ? 'incremental' : 'accumulative'
        this.series = Object.freeze(this.chartData['quantity'][headingType])
      } else {
        this.series = Object.freeze(this.timescaleData['quantity'].incremental[this.selectedTimescaleOption.value])
      }
      if (!!this.chartLoading) this.chartLoading = false
    },

    async switchIncrementalView() {
      if (!this.chartLoading) this.chartLoading = true
      await waitFor(200)
      this.series = Object.freeze(this.chartData[this.ticketSalesChartTopic.toLowerCase()].incremental)
      if (!!this.chartLoading) this.chartLoading = false
    },

    async switchCumulativeView() {
      if (!this.chartLoading) this.chartLoading = true
      await waitFor(200)
      this.series = Object.freeze(this.chartData[this.ticketSalesChartTopic.toLowerCase()].accumulative)
      if (!!this.chartLoading) this.chartLoading = false
    },

    handleAddDragListener() {
      if (this.dragListenerAdded) return

      this.setDragListenerState(true)
    },

    handleCategoryToolbarSelection(selection) {
      let isCategory = this.selectedTimescaleOption.value !== 'increments'
      if (selection === 'undo' && isCategory) {
        this.selectedToolbarIcon = 'undo'
        setTimeout(() => {
          this.selectedToolbarIcon = 'select'
        }, 500)
        this.selectedTimescaleOption = { name: 'Monthly', value: 'monthly'}
      } else if (selection === 'select') {
        if (!isCategory) {
          this.selectedToolbarIcon = 'select'
        } else {
          return
        }
      } else if (selection === 'zoom-in') {
        if (!isCategory) {
          this.selectedToolbarIcon = 'zoom-in'
          this.selectedTimescaleOption = { name: 'Monthly', value: 'monthly'}
        } else if (this.selectedTimescaleOption.value === 'monthly') {
          this.selectedToolbarIcon = 'zoom-in'
          this.selectedTimescaleOption = { name: 'Weekly', value: 'weekly'}
        } else if (this.selectedTimescaleOption.value === 'weekly') {
          this.selectedToolbarIcon = 'zoom-in'
          this.selectedTimescaleOption = { name: 'Daily', value: 'daily'}
        }
      } else if (selection === 'zoom-out') {
        if (!isCategory) {
          this.selectedToolbarIcon = 'zoom-out'
          this.selectedTimescaleOption = { name: 'Monthly', value: 'monthly'}
        } else if (this.selectedTimescaleOption.value === 'daily') {
          this.selectedToolbarIcon = 'zoom-out'
          this.selectedTimescaleOption = { name: 'Weekly', value: 'weekly'}
        } else if (this.selectedTimescaleOption.value === 'weekly') {
          this.selectedToolbarIcon = 'zoom-out'
          this.selectedTimescaleOption = { name: 'Monthly', value: 'monthly'}
        }
      } else if (selection === 'undo' && !isCategory) {
        this.selectedToolbarIcon = 'undo'
        setTimeout(() => {
          this.selectedToolbarIcon = 'select'
        }, 500)
      } else if (selection === 'pan' && !isCategory) {
        this.selectedToolbarIcon = 'pan'
      }
    },

    setDateTimeOptions(type) {
      let fill = {}
      if (type === 'line') {
        fill = {
          type: 'solid',
        }
      }

      let options = {
        type: type,
        fill: fill,
        yaxis: {
          labels: {
            minWidth: 50,
            formatter: (val) => {
              return this.ticketSalesChartTopic === 'Sales' ? this.yAxisSalesFormatter(val) : this.yAxisQuantityFormatter(val)
            },
          }
        },
        xaxis: {
          convertedCatToNumeric: false,
          tickAmount: null,
          type: 'datetime',
          labels: {
            datetimeUTC: false,
            style: {
              colors: '#8e97a6',
              fontSize: '12px',
              fontFamily: 'Graphik',
              fontWeight: 400,
              cssClass: 'apex-axis-text-class'
            },
            datetimeFormatter: {
              year: 'yyyy',
              month: 'MMM \'yy',
              day: 'MMM dd',
              hour: 'HH:mm'
            }
          },
          tooltip: {},
          crosshairs: {
            stroke: {
              color: '#EDEEF1',
            },
          },
        },
        tooltip: {
          custom: ({_, seriesIndex, dataPointIndex, w}) => {
            let toolTipData = w.config.series[seriesIndex].data[dataPointIndex]
            let dataDate = dayjs(toolTipData[0][0]).tz(this.selectedTimezone).format('MMMM YYYY')

            return `
              <div class="sales-tooltip-wrapper">
                <span class="tooltip-date">${dataDate}</span>
                <span class="tooltip-tab">${this.getMarkerCopy}</span>
                <span class="tooltip-value-wrapper">
                  <span class="tooltip-value">${this.getTooltipValue(toolTipData[1])}</span>
                  <span class="tooltip-cents">${this.topic === 'Sales' ? this.getCents(toolTipData[1]) : ''}</span>
                </span>
              </div>
            `
          }
        }
      }

      this.chartOptions = options
      let containsLargeData = false
      let i = this.series.length
      while (i--) {
        if (this.series[i].data.length > 50) {
          containsLargeData = true
        }
      }

      let animation
      if (this.$browser.isFirefox || this.$browser.isSafari || containsLargeData) {
        return
      }
      animation = {
        enabled: true,
        easing: 'easeout',
        speed: 300,
        animateGradually: {
          enabled: false,
          delay: 50
      }}
      this.chartAnimation = animation
    },

    async handleTimescaleReset() {
      this.isTimescaleView = false
      if (!this.chartLoading) this.chartLoading = true
      await waitFor(200)
      this.selectedTimescaleOption = { name: 'Increments', value: 'increments' }
      await this.refreshSeriesDataForChart()

      if (this.containsMultiEventData) {
        this.setDateTimeOptions('line')
        this.withAnnotations = false
      } else {
        this.setDateTimeOptions('area')
        setTimeout(() => {
          this.withAnnotations = true
        }, 500)
      }
      this.selectedToolbarIcon = 'select'
      if (!!this.chartLoading) this.chartLoading = false
    },

    toggleAnnotations() {
      this.withAnnotations = !this.withAnnotations
    },

    setResponsiveChartHeight() {
      if (this.chartOptions) {
        if (this.isSmMedia) {
          this.chartOptions['chart'] = {
            'height': 260
          }
          return '260px'
        } else {
          this.chartOptions['chart'] = {
            'height': 380
          }
          return '380px'
        }
      } else {
        return '0px'
      }
    },

    async handleAnnotationCreate(annotation) {
      if (!annotation.body.length) {
        this.$arNotification.push({
          type: 'error',
          message: 'Your annotation cannot be empty.',
        })
        return
      }
      let annoDateUTC = dayjs.utc(annotation.timeMillis).toISOString()

      let anno = {
        ...annotation,
        timestamp: annoDateUTC,
      }

      try {
        let createdAnno = await this.createAnnotation(anno)
        return createdAnno
      } catch (error) {
        console.log('An error occurred attempting to create annotation: ', error)
      }
    },

    async handleAnnotationUpdate(annotation) {
      try {
        await this.updateAnnotation(annotation)
      } catch (error) {
        console.log('An error occurred attempting to update annototation with id: ', annotation.oid)
      }
    },

    async handleAnnotationDelete(oid) {
      try {
        await this.deleteAnnotation(oid)
      } catch (error) {
        console.log('An error occurred attempting to delete annototation with id: ', oid)
      }
    },

    selectCompareOption(oid) {
      this.$refs[`compareCheckbox${oid}`][0].toggle()
    },

    getTimescaleData() {
      let topic = this.ticketSalesChartTopic
      let timescaleType = this.selectedTimescaleOption.value

      if (topic === 'Sales') {
        if (timescaleType === 'monthly') {
          return this.timescaleData.sales.incremental.monthly
        } else if (timescaleType === 'weekly') {
          return this.timescaleData.sales.incremental.weekly
        } else if (timescaleType === 'daily') {
          return this.timescaleData.sales.incremental.daily
        } else {
          console.log("An unrecognised timescaleType was recieved: ", timescaleType)
        }
      } else if (topic === 'Quantity') {
        if (timescaleType === 'monthly') {
          return this.timescaleData.quantity.incremental.monthly
        } else if (timescaleType === 'weekly') {
          return this.timescaleData.quantity.incremental.weekly
        } else if (timescaleType === 'daily') {
          return this.timescaleData.quantity.incremental.daily
        } else {
          console.log("An unrecognised timescaleType was recieved: ", timescaleType)
        }
      } else {
        console.log("An unrecognised topic was recieved: ", topic)
      }
    },

    async handleTimescaleSelect() {
      this.withAnnotations = false
      if (!this.chartLoading) this.chartLoading = true
      await waitFor(200)
      let data = Object.freeze(this.timescaleData[this.ticketSalesChartTopic.toLowerCase()].incremental[this.selectedTimescaleOption.value])

      let seriesData = []
      let rawSeriesData = []
      let largestDataIndex = 0
      let count = 0
      let i = 0
      while (i < data.length) {
        if (data[i].cats.length > count) {
          count = data[i].cats.length
          largestDataIndex = i
        }

        i++
      }

      let j = 0
      while (j < data.length) {
        if (!this.containsMultiEventData) {
          seriesData.push(Object.freeze({name: data[j].name, data: data[j].data}))
          rawSeriesData.push(Object.freeze({name: data[j].name, data: data[j].data, cats: data[j].cats}))
        } else {
          let compareWithArray = []
          if (this.compareFrom === 'end') {
            if (data[j].data.length < count) {
              let nullsToPrepend = count - data[j].data.length
              let compareFromEndArray = []
              while (nullsToPrepend--) {
                compareFromEndArray.push(0)
              }
              compareWithArray = [...compareFromEndArray, ...data[j].data]
              seriesData.push(Object.freeze({name: data[j].name, data: compareWithArray}))
              rawSeriesData.push(Object.freeze({name: data[j].name, data: data[j].data, cats: data[j].cats}))
            } else {
              seriesData.push(Object.freeze({name: data[j].name, data: data[j].data}))
              rawSeriesData.push(Object.freeze({name: data[j].name, data: data[j].data, cats: data[j].cats}))
            }
          } else if (this.compareFrom === 'start') {
            if (data[j].data.length < count) {
              let nullsToAppend = count - data[j].data.length
              let compareFromStartArray = []
              while (nullsToAppend--) {
                compareFromStartArray.push(0)
              }
              compareWithArray = [...data[j].data, ...compareFromStartArray ]
              seriesData.push(Object.freeze({name: data[j].name, data: compareWithArray}))
              rawSeriesData.push(Object.freeze({name: data[j].name, data: data[j].data, cats: data[j].cats}))
            } else {
              seriesData.push(Object.freeze({name: data[j].name, data: data[j].data}))
              rawSeriesData.push(Object.freeze({name: data[j].name, data: data[j].data, cats: data[j].cats}))
            }
          }
        }

        j++
      }

      this.cats = []
      this.catsFullString = []
      let k = 0
      let length = data[largestDataIndex].cats.length
      while (k < length) {
        this.cats.push(Object.freeze(data[largestDataIndex].cats[k].category))
        this.catsFullString.push(Object.freeze(data[largestDataIndex].cats[k].dateString))
        k++
      }
      this.series = Object.freeze(seriesData)
      this.rawSeriesData = Object.freeze(rawSeriesData)
      this.originalCats = Object.freeze(this.cats)
      this.chartOptions = this.setTimescaleOptions()
      let animation
      if ((this.$browser.isFirefox || this.$browser.isSafari) || count > 50) {
        if (!!this.chartLoading) this.chartLoading = false
        animation = {
          enabled: false,
        }
        this.chartAnimation = animation
        return
      }
      animation = {
        enabled: true,
        easing: 'easeout',
        speed: 300,
        animateGradually: {
          enabled: false,
          delay: 50
      }}
      this.chartAnimation = animation

      if (!!this.chartLoading) this.chartLoading = false
    },

    getBarColumnWidths() {
      if (this.containsMultiEventData) {
        if (this.cats.length === 1) {
          return '30%'
        } else if (this.cats.length < 5) {
          return '40%'
        } else if (this.cats.length <= 12) {
          return '60%'
        } else {
          return '90%'
        }
      } else {
        if (this.cats.length === 1) {
          return '10%'
        } else if (this.cats.length < 5) {
          return '18%'
        } else if (this.cats.length <= 12) {
          return '50%'
        } else {
          return '80%'
        }
      }
    },

    setTimescaleOptions() {
      let colWidth = this.getBarColumnWidths()

      return {
        type: 'bar',
        plotOptions: {
          bar: {
            columnWidth: colWidth,
          }
        },
        fill: {
          type: 'solid',
        },
        grid: {
          padding: {
            right: 1,
          },
        },
        yaxis: {
          minWidth: 50,
          labels: {
            formatter: (val) => {
              return this.ticketSalesChartTopic === 'Sales' ? this.yAxisSalesFormatter(val) : this.yAxisQuantityFormatter(val)
            },
          }
        },
        xaxis: {
          type: 'category',
          categories: this.cats,
          tickAmount: 10,
          labels: {
            formatter: function(val) {
              return val
            }
          },
        },
        tooltip: {
          custom: ({series, seriesIndex, dataPointIndex, w}) => {
            let toolTipData = w.config.series[seriesIndex].data[dataPointIndex]
            let catToolTipDate = this.catsFullString[dataPointIndex]

            return `
              <div class="sales-tooltip-wrapper">
                <span class="tooltip-date">${catToolTipDate}</span>
                <span class="tooltip-tab">${this.getIncrementsMarkerCopy}</span>
                <span class="tooltip-value-wrapper">
                  <span class="tooltip-value">${this.getTooltipValue(toolTipData)}</span>
                  <span class="tooltip-cents">${this.topic === 'Sales' ? this.getCents(toolTipData) : ''}</span>
                </span>
              </div>
            `
          }
        },
      }
    },

    handleCompareOptionsFilter(text) {
      this['event/RESET_EVENTS_LIST']()
      this['event/SET_EVENTS_LIST_SEARCH'](text)

      this.loadEventsOptions()
    },

    async refreshSeriesDataForChart() {
      if (!this.chartLoading) this.chartLoading = true
      let topic = this.ticketSalesChartTopic
      let headingOption = this.selectedHeading.value
      let data = this.chartData

      switch (topic) {
        case 'Sales':
          if (headingOption === 'totalTicketSales') {
            this.series = Object.freeze(data.sales.accumulative)
            break
          }
          if (headingOption === 'incrementalTicketSales') {
            this.series = Object.freeze(data.sales.incremental)
            break
          }
        case 'Quantity':
          if (headingOption === 'totalTicketSales') {
            this.series = Object.freeze(data.quantity.accumulative)
            break
          }
          if (headingOption === 'incrementalTicketSales') {
            this.series = Object.freeze(data.quantity.incremental)
            break
          }
        default:
          console.log('An unrecognised state was received when toggling sales chart: ', `${topic}: ${headingOption}`)
      }
      if (!!this.chartLoading) this.chartLoading = false
      return
    },

    async loadEventsOptions() {
      await this.fetchEvents({ selectString: 'name,startDate,location' })
    },

    handleEventCompareLoadMore() {
      this.loadEventsOptions()
    },

    cumulativeSalesOrBackwardsCompatibleSales(data) {
      if (!!data?.cumulativeSales?.length) {
        return data.cumulativeSales
      } else if (!!data?.sales?.length) {
        return data.sales
      } else {
        return []
      }
    },

    async getAdjustedOverlayData(data) {
      let adjustedData = {}
      let promises = []
      let salesPerDayRawData
      let salesPerWeekRawData
      let salesData = this.cumulativeSalesOrBackwardsCompatibleSales(data)
      let totalSalesRawData = !!salesData.length ? salesData : this.noSalesDefaultData()
      let salesPerHourRawData = !!data?.salesPerHour?.length ? data.salesPerHour : this.noSalesDefaultData()
      salesPerDayRawData = !!data?.salesPerDay?.length ? data.salesPerDay : this.noSalesDefaultData()
      salesPerWeekRawData = !!data?.salesPerWeek?.length ? data.salesPerWeek : this.noSalesDefaultData()

      promises.push(this.adjustTimestampsForOverlay(this.convertTimestampStringsToMillis(totalSalesRawData), this.compareFrom))
      promises.push(this.adjustTimestampsForOverlay(this.convertTimestampStringsToMillis(salesPerHourRawData), this.compareFrom))
      promises.push(this.adjustTimestampsForOverlay(this.convertTimestampStringsToMillis(salesPerDayRawData, true), this.compareFrom))
      promises.push(this.adjustTimestampsForOverlay(this.convertTimestampStringsToMillis(salesPerWeekRawData, true), this.compareFrom))

      let result = await Promise.all(promises)

      adjustedData['totalSales'] = result[0]
      adjustedData['incrementalSales'] = result[1]
      adjustedData['salesPerDay'] = result[2]
      adjustedData['salesPerWeek'] = result[3]

      return adjustedData
    },

    async setCompareDataSets(dataSets) {
      if (!this.chartLoading) this.chartLoading = true

      let compareDataSets = []

      let i = dataSets.length
      while (i--) {
        let adjustedData = await this.getAdjustedOverlayData(dataSets[i].data)
        compareDataSets.push({
          oid: dataSets[i].data.oid,
          name: dataSets[i].name,
          data: {totalSales: adjustedData.totalSales, incrementalSales: adjustedData.incrementalSales},
          timescale: {
            salesPerWeek: adjustedData.salesPerWeek,
            salesPerDay: adjustedData.salesPerDay,
          }
        })
      }

      try {
        this.setOverlayData({data: compareDataSets})
      } catch (error) {
        console.log('this.setOverlayData error: ', error)
      }
    },

    adjustTimeStampData(diffMillis, data) {
      let adjustedData

      let i = data.length
        while (i--) {
          adjustedData = data.map(dataPoint => {
            let adjustedTs = dayjs.utc(dataPoint.ts).valueOf() - diffMillis
            return {
              ...dataPoint,
              ts: adjustedTs
            }
          })
        }

      return adjustedData
    },

    adjustTimestampsForOverlay(dataSet, compareFrom) {
      let adjustedData
      let diff

      switch (compareFrom) {
        case 'start':
          let currentStartMillis = this.rawSalesChartData.totalSales[0].ts
          let overlayItemStartMillis = dayjs.utc(dataSet[0].ts).valueOf()
          diff = overlayItemStartMillis - currentStartMillis

          break
        case 'end':
          let currentEndMillis = dayjs(this.rawSalesChartData.totalSales[this.rawSalesChartData.totalSales.length - 1].ts).tz(this.selectedTimezone).valueOf()
          let overlayItemEndMillis = dayjs.utc(dataSet[dataSet.length - 1].ts).valueOf()
          diff = overlayItemEndMillis - currentEndMillis

          break
        default:
          console.log('An unrecognised compareFrom value was received:', compareFrom)
      }

      adjustedData = this.adjustTimeStampData(diff, dataSet)

      return adjustedData
    },

    async compareEventsHandler() {
      if (!this.chartLoading) this.chartLoading = true
      this.withAnnotations = false
      await waitFor(300)
      let promises =[]

      if (!Object.keys(this.compareIds).length) {
        this.handleCompareClear()
        return
      }

      Object.entries(this.compareIds).forEach(([key, val]) => {
        if (val) {
          let { name, oid } = this.compareOptions.find(item => {
            return item.oid !== this.currentEvent.oid && item.oid === parseInt(key)
          })
          promises.push(this.fetchCompareTicketSalesData({oid, name, filter: this.prunedScratchSegment.filter}))
        }
      })

      try {
        let multipleEventDataArray = await Promise.all(promises)
        this.setCompareDataSets(multipleEventDataArray)
        if (this.selectedTimescaleOption.value !== 'increments') {
          this.setTimescaleOptions()
        } else {
          this.setDateTimeOptions('line')
        }
      } catch(error) {
        console.log('Something went wrong setting compare data:', error)
      }

    },

    handleEventCompareDeselect() {
      this['event/SET_UNSAVED_EVENTS_LIST']([])
    },

    async handleCompareClear() {
      if (!this.chartLoading) this.chartLoading = true
      this.series = [this.series[0]]
      this['event/CLEAR_SELECTED_EVENTS_LIST']()
      this.clearCompareTimescaleData()
      this.setCompareDataSets([])
      if (this.selectedTimescaleOption.value === 'increments') {
        this.setDateTimeOptions('area')
        this.refreshSeriesDataForChart()
      }
    },

    getTooltipValue(val) {
      if (this.ticketSalesChartTopic === 'Quantity') {
          return val
      }

      return `${this.getDollars(val)}`
    },

    getDollars(val) {
        return `$${amountInDollars(val)}`
    },

    getCents(val) {
        return `${centsRemaining(val)}`
    },

    handleSalesTopicChange(topic) {
      this.ticketSalesChartTopic = topic.name
    },

    getTimeWithEventTimezone() {
      if (!this.currentEvent?.timeZone) {
        return null;
      }

      return moment.utc().toDate().getTime()
    },

    noSalesDefaultData() {
      let defaultData = []
      let endDate = this.currentEvent?.endDate ? this.currentEvent.endDate : this.currentEvent.startDate
      let timeStart = this.getTimeWithEventTimezone()
      let eventEndDate = dayjs(endDate).tz(this.selectedTimezone)

      if (eventEndDate.isAfter(dayjs().tz(this.selectedTimezone))) { // Good, event has not ended
        let daysDiffBetweenNowAndEventEndDate = eventEndDate.diff(dayjs().tz(this.selectedTimezone), 'day')

        for (let i = 0; i < daysDiffBetweenNowAndEventEndDate; i += 1) {
          defaultData.push({ ts: timeStart + (i * 86400 * 1000), sales: 0, tickets: 0 })
        }
      } else {
        let currentEventStartEndDiff
        if (!!this.eventTicketSales?.cumulativeSales?.length) {
          let currentEndVal = this.eventTicketSales.cumulativeSales[this.eventTicketSales.cumulativeSales.length -1].ts
          let currentStartVal = dayjs(this.eventTicketSales.cumulativeSales[0].ts)

          // true as extra parameter returns decimal value of days difference,
          // ensuring that null values of compare data show just more than the
          // current event time period, rather than always showing 30 days.
          currentEventStartEndDiff = dayjs(currentEndVal).diff(currentStartVal, 'day', true)
        } else {
          // if current event has no sales data at all, default to show 30 days of null values
          currentEventStartEndDiff = 30
        }
        let timeStart = eventEndDate.subtract(currentEventStartEndDiff, 'day').valueOf()
        for (let i = 0; i < currentEventStartEndDiff; i += 1) {
          defaultData.push({ ts: timeStart + (i * 86400 * 1000), sales: 0, tickets: 0 })
        }
      }

      return defaultData
    },

    /**
     * Sales data is not consistent and not even, so in this function we're trying to
     * get the highest data of each hour and then find if any data of day missed then fill it.
     */
    getCleaneventTicketSalesData(sales) {
      const salesHourMap = {};
      let res = [];

      sales.filter(s => !!s.ts).forEach((sale) => {
        const hourTime = moment(sale.ts || moment()).startOf('hour').toDate().getTime();
        if (!salesHourMap[hourTime]) {
          salesHourMap[hourTime] = {
            ...sale,
            ts: hourTime,
          };
        }
        if (sale.tickets > salesHourMap[hourTime].tickets) {
          salesHourMap[hourTime] = {
            ...sale,
            ts: hourTime,
          };
        }
      });

      res = Object.keys(salesHourMap).sort((a, b) => parseInt(a, 10) - parseInt(b, 10)).map(key => salesHourMap[key]);

      for (let i = 0; i < res.length; i += 1) {
        if (!res[i + 1]) {
          break;
        }
        const endOfHour = moment(res[i].ts).endOf('hour');
        const startOfNextHour = moment(res[i + 1].ts).startOf('hour');
        if (startOfNextHour.diff(endOfHour, 'hours') >= 1) {
          res.splice(i + 1, 0, {
            ...res[i],
            ts: moment(res[i].ts).add(1, 'hours').toDate().getTime(),
          });
        }
      }

      // We need to add first data with no value so the graph will start with 0.
      // NOTE - If no datetime data has been imported, res[0] will be undefined
      if(res[0] && res[0].ts) {
        res.unshift({
          sales: 0,
          tickets: 0,
          ts: moment(res[0].ts).add(-1, 'hours').toDate().getTime(),
        });
      }


      return res;
    },

    prependZeroValsToChartData(data) {
      if (!data.length) {
        return data
      }
      let zeroedData = data
      if(zeroedData[0] && zeroedData[0].ts) {
        zeroedData.unshift({
          sales: 0,
          tickets: 0,
          ts: dayjs.utc(zeroedData[0].ts).subtract(1, 'day').valueOf(),
        })
      }

      return zeroedData
    },

    patchChartData(data, isCategoryType = false) {
      if (isCategoryType) {
        return data
      } else {
        let dataSet = this.prependZeroValsToChartData(data)
        return dataSet
      }
    },

    convertTimestampStringsToMillis(data, isCategoryType = false) {
      let dataWithMillis = data.map(item => {
        return {
          ...item,
          ts: dayjs.utc(item.ts).valueOf(),
        }
      })

      if (isCategoryType) {
        return dataWithMillis
      } else {
        let dataSet = this.prependZeroValsToChartData(dataWithMillis)
        return dataSet
      }
    },

    async setInitialTicketData(data) {
      let initialData = {}

      let salesData = this.cumulativeSalesOrBackwardsCompatibleSales(data)
      let totalSalesRawData = !!salesData.length ? salesData : this.noSalesDefaultData()
      let incrementalSalesRawData = !!data?.salesPerHour.length ? data.salesPerHour : this.noSalesDefaultData()

      initialData['totalSales'] = !!totalSalesRawData.length ? this.patchChartData(totalSalesRawData) : totalSalesRawData
      initialData['incrementalSales'] = !!incrementalSalesRawData.length ? this.patchChartData(incrementalSalesRawData) : incrementalSalesRawData

      try {
        await this.setInitialChartData(initialData)
      } catch (error) {
        console.log('this.setInitialChartData error:', error)
      }
    },

    yAxisQuantityFormatter(value) {
      return accounting.formatNumber(value);
    },

    yAxisSalesFormatter(value) {
      if (value < 100) { // Might get small value if it's not in unit of cent
        return `${accounting.formatMoney(value, { precision: 0 })}`;
      }
      let dollars = value / 100;
      if (dollars >= 1000000) {
        return `${accounting.formatMoney(dollars / 1000000, { precision: dollars % 1000000 === 0 ? 0 : 1 })}m`;
      } else if (dollars >= 1000) {
        return `${accounting.formatMoney(dollars / 1000, { precision: dollars % 1000 === 0 ? 0 : 1  })}k`;
      }
      return accounting.formatMoney(dollars, { precision: 0 })
    },
  },

  async mounted() {
    this.chartLoading = true
  },
}
</script>
<style lang="scss">

$borderColor: $blueGrey500;
$disabledColor: $blueGrey700;

@mixin flexRow {
    display: flex;
    flex-flow: row nowrap;
}

@mixin dropdownOptionsStyle {
  background: white;
  border: ui-px2em(1) solid $borderColor;
  border-radius: 5px;
  box-shadow: 0px 1px 11px -2px rgba(0, 0, 0, 0.2);
}

.sales-chart-wrapper {
  grid-column: 1 / span 2;
  background: white;
  border: ui-px2em(1) solid $skyBlueGrey400;
  box-shadow: $blueGrey400 2px 5px 10px;
  border-radius: 4px;
  padding: ui-px2em(30);

  .sales-chart-nav-wrapper {
    @include flexRow;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 10px;
    padding: 0 ui-px2em(5);

    .sales-chart-nav-items-wrapper {
      @include flexRow;
      align-items: center;
      justify-content: flex-end;
      height: 36px;

      .timescale-reset-button {
        margin-right: 10px;
      }
    }
  }

  .comparing-to-wrapper {
    @include flexRow;
    align-items: center;
    justify-content: flex-end;
    margin: 14px 0 12px;
    padding: 0 ui-px2em(5);

    .comparing-to-copy, .comparing-to-item {
      margin-right: 5px;
      color: $blueGrey700;
    }
  }

  .sales-chart-container {
    position: relative;

    .apex-sales-chart-wrapper {
      opacity: 1;
      visibility: visible;
      height: 380px;

      .apex-chart {
        height: 100%;
      }
    }
  }

  .sales-tooltip-wrapper {
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
    justify-content: center;
    width: 180px;
    background: white;
    padding: ui-px2em(12) ui-px2em(15) ui-px2em(18);
    box-sizing: border-box;
    font-family: 'Graphik';
    font-weight: 400;
    color: $blueGrey800;
    z-index: 1;
    position: relative;

    .tooltip-date {
      font-size: 14px;
      margin-bottom: 0;
    }

    .tooltip-tab {
      font-size: 14px;
      color: $blueGrey700;
      margin-top: -5px;
      margin-bottom: 10px;
    }

    .tooltip-value-wrapper {
      @include flexRow;
      align-items: baseline;
      justify-content: flex-start;

      .tooltip-value {
        font-size: 24px;
      }

      .tooltip-cents {
        font-size: 14px;
      }
    }
  }
}
</style>
