<template>
  <div class="cf-page-container">
    <am2-select-edit-create-custom-field-modal
      :open="createModalOpen"
      @save="handleCreateSave"
      @update="handleUpdate"
      @close="handleModalClose"
      :create-state="createState"
      :selected-custom-field="selectedCustomField"
      ref="custom-field-modal"
    />
    <div class="cf-page-wrapper">
      <div class="cf-heading-wrapper">
        <div class="cf-heading-container">
          <ar-text
            class="cf-heading"
            size="30px"
            text="Manage custom fields"
            weight="normal"
            :max-lines="1"
            line-height="30px"
          />
          <div class="heading-actions-wrapper">
            <div class="heading-tabs-wrapper">
              <div class="tab-wrapper" @click="handleTabClick('profileTab')">
                <ar-text
                  class="tab-heading"
                  size="14px"
                  text="Profile fields"
                  :weight="selectedTab === 'profileTab' ? 'bold' : 'normal'"
                  :max-lines="1"
                  line-height="18px"
                />
                <div v-if="selectedTab === 'profileTab'" class="tab-bottom"></div>
              </div>
              <div class="tab-wrapper" @click="handleTabClick('formTab')">
                <ar-text
                  class="tab-heading"
                  size="14px"
                  text="Form fields"
                  :weight="selectedTab === 'formTab' ? 'bold' : 'normal'"
                  :max-lines="1"
                  line-height="18px"
                />
                <div  v-if="selectedTab === 'formTab'" class="tab-bottom"></div>
              </div>
            </div>
            <ar-simple-button
              icon-name="circle-plus"
              icon-distance="9px"
              text="Create custom field"
              :style="{ width: '185px', marginLeft: '9px', marginBottom: '17px', padding: '6px 22px' }"
              @click="handleCreateCustomField"
            />
          </div>
        </div>
      </div>
      <div class="cf-body-wrapper">
        <component
          :is="selectedTab"
          :rows="customFields"
          :menu-button-actions="menuButtonActions"
          @handleMenuButtonSelect="handleMenuButtonSelect"
          :field-type-text="fieldTypeText"
          @handleUpdateSearchFilter="handleUpdateSearchFilter"
        />
        <br />
      </div>
    </div>
  </div>
</template>
<script>
  import { mapActions, mapState } from 'vuex';
  import profileSection from './sections/profile-section.vue'
  import formSection from './sections/form-section.vue'
  import { sanitizeHtmlChild } from '@/utils/html-element/';
  import { dropdownTypeOptions } from '~/utils/constants';

  export default {
    name: 'customFields',
    components: {
      profileTab: profileSection,
      formTab: formSection,
    },
    data() {
      return {
        selectedTab: 'profileTab',
        showCustomFieldModal: false,
        createModalOpen: false,
        defaultMenuButtonActions: [
          {
            name: 'Edit',
            key: 'edit'
          },
          {
            name: 'Delete',
            key: 'delete',
            typography: {
              style: {
                color: this.$arStyle.color.red500,
              },
            },
          }
        ],
        createState: "new",
        selectedCustomField: null,
        fieldTypeText: dropdownTypeOptions.reduce((fieldTypes, currentType) => {
          return {
            ...fieldTypes,
            [currentType.key]: currentType.display
          }
        }, {}),
        searchFilter: "",
      }
    },
    computed: {
      ...mapState({
        customFields: state => state.customField.customFields,
      }),

      tabName() {
        return this.selectedTab === 'profileTab' ? 'profile' : 'form'
      },
      menuButtonActions() {
        const exportCsvMenu = {
          name: 'Export as CSV',
          key: 'exportCsv'
        }

        if (this.selectedTab === 'profileTab') {
          return [
            this.defaultMenuButtonActions[0],
            exportCsvMenu,
            this.defaultMenuButtonActions[1],
          ]
        } else {
          return this.defaultMenuButtonActions
        }
      }
    },
    methods: {
      ... mapActions([
        'customField/FETCH_CUSTOM_FIELDS',
        'customField/CREATE_CUSTOM_FIELD',
        'customField/UPDATE_CUSTOM_FIELD',
        'customField/DELETE_CUSTOM_FIELD',
        'customField/EXPORT_CUSTOM_FIELD_CSV',
        'SHOW_CONFIRM',
      ]),
      handleModalClose() {
        this.createModalOpen = false
        this.createState = 'new' // Refresh to create state
        this.selectedCustomField = null
      },
      handleTabClick(tab) {
        this.selectedTab = tab
        const queryName = tab === 'profileTab' ? 'profile' : 'form'
        this.$router.push({query: {tab: queryName}}).catch(_ => null)
        this.searchFilter = ""
        this['customField/FETCH_CUSTOM_FIELDS']({type: queryName, searchFilter: this.searchFilter})
      },
      handleCreateCustomField() {
        this.createState = 'new'
        this.createModalOpen = true
      },
      async handleCreateSave(createState, data) {
        if (createState === 'new') {
          const succeed = await this['customField/CREATE_CUSTOM_FIELD'](data)
          if (succeed?.type === 'error') {
            if (!!succeed['internal-code'] && succeed['internal-code'] === 'record-exists') {
              this.$refs['custom-field-modal'].insertErrorForDuplicatedName()
            }
          } else {
            this.createModalOpen = false
          }
        } else if (createState === 'edit') {
          const succeed = await this['customField/UPDATE_CUSTOM_FIELD'](data)
          if (succeed?.type === 'error') {
            if (!!succeed['internal-code'] && succeed['internal-code'] === 'record-exists') {
              this.$refs['custom-field-modal'].insertErrorForDuplicatedName()
            }
          } else {
            this.createModalOpen = false
          }
        }
      },
      handleUpdateSearchFilter(searchFilter) {
        this.searchFilter = searchFilter
        this['customField/FETCH_CUSTOM_FIELDS']({type: this.tabName, searchFilter: this.searchFilter})
      },
      handleMenuButtonSelect(menuButton, rowData) {
        if (menuButton.key === 'edit') {
          this.createState = 'edit'
          this.selectedCustomField = rowData

          this.createModalOpen = true
        } else if (menuButton.key === 'delete') {
          this.deleteCustomFieldModal(rowData)
        } else if (menuButton.key === 'exportCsv') {
          this.exportCsv(rowData)
        }
      },
      async deleteCustomFieldModal(rowData) {
        const ans = await this['SHOW_CONFIRM']({
          title: 'Delete this custom field?',
          messageHtml: `You’re about to delete <b>${sanitizeHtmlChild(rowData.name)}</b>. Deleting this field will remove it and the data associated with it from <XXXX> fans`,
          confirmButtonText: 'Delete',
          validation: {
            question: 'Type DELETE to confirm',
            answer: 'DELETE',
          },
          asyncCompleteFunction: () => this.deleteAutomationAction(rowData),

          iconName: 'alert-question-mark',
        });
      },
      async deleteAutomationAction(data) {
        this['customField/DELETE_CUSTOM_FIELD'](data.oid)
      },
      async exportCsv(data) {
        this['customField/EXPORT_CUSTOM_FIELD_CSV']({oid: data.oid, fieldType: data.fieldType, name: data.name})
      }
    },
    beforeRouteEnter(to, _, next) {
      if (!to.query?.tab) return next()
      next(vm => {
        vm.selectedTab = to.query.tab === 'profile' ? 'profileTab' : 'formTab'
        return true
      })
    },
    mounted() {
      // Fetch all custom fields
      this['customField/FETCH_CUSTOM_FIELDS']({type: this.tabName, searchFilter: this.searchFilter})
    }
  }
</script>
<style lang="scss">
.cf-page-container {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  width: 100%;

  .cf-page-wrapper {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    min-height: 100vh;

    .cf-heading-wrapper {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;
      width: 100%;
      background: white;
      border-bottom: 1px solid $skyBlueGrey500;

      .cf-heading-container {
        max-width: 1260px;
        width: 100%;
        padding: 36px 56px 0;
        display: flex;
        flex-flow: column nowrap;
        align-items: flex-start;

        .cf-heading {
          color: $skyBlueGrey800;
          margin-bottom: 36px;
        }
      }


      .heading-actions-wrapper {
        display: flex;
        flex-flow: row nowrap;
        align-items: flex-end;
        justify-content: space-between;
        width: 100%;

        .heading-tabs-wrapper {
          display: flex;
          flex-flow: row nowrap;
          align-items: center;
          justify-content: flex-start;

          .tab-wrapper {
            position: relative;
            padding: 0 0 27px;
            margin-right: 30px;
            display: flex;
            flex-flow: row nowrap;
            align-items: flex-start;
            cursor: pointer;

            .tab-bottom {
              position: absolute;
              background: $purple500;
              height: 2px;
              width: 100%;
              bottom: 0;
            }
          }
        }
      }
    }

    .cf-body-wrapper {
      width: 100%;
      padding: 56px 56px 0;
      display: flex;
      flex-flow: column nowrap;
      align-items: flex-start;
      max-width: 1260px;
    }
  }
}
</style>
