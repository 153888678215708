<template>

    <div
      class="message-center-recipients-wrapper"
      v-infinite-scroll="loadMoreRecipients"
      infinite-scroll-disabled="disableLoadMoreRecipients"
      infinite-scroll-distance="10"
    >
    <!-- When you scroll down to the bottom of the page loadMoreRecipients will be triggered -->
      <section
        class="message-center-recipients"
      >
        <div class="message-center-recipients-left">
          <am2-menu-vertical
            title="View by"
            :items="verticalMenuItems"
            :selected-key="selectedMenuItemKey"
            @select="handleRecipientsMenuSelect"
            custom-padding="16px 16px"
            custom-head-padding="5px 5px 16px 0"
            min-width="188px"
          />
        </div>

        <div class="message-center-recipients-right">
          <div v-if="clickTrackingDisabled" class="link-tracking-wrapper">
            <div class="link-tracking-container">
              <ar-snackbar
                class="u-margin-top-5"
                type="warning"
                message="Click tracking is disabled on this message"
              />
            </div>
          </div>

          <div
            v-if="!searchString"
            class="recipients-count-wrapper"
          >
            <div>
              <ar-text
                size="xs"
                weight="bold"
                :text="recipientsCountFormatted"
              />
              <ar-text
                size="xs"
                :text="recipientsCount !== 1 ? 'recipients' : 'recipient'"
                :style="{ color: $arStyle.color.skyBlueGrey700, marginLeft: '5px' }"
              />
            </div>

            <div class="recipients-ab-select" v-if="isAbEmail">
              <ar-simple-select
                :items="abSelectItems"
                :default-select-index="defaultSelectedIndexAbOptions"
                @select="handleAbItemSelect"
                />
            </div>
          </div>

          <div class="table-control-wrapper">
            <TableControl
              :recipient-count="recipientsCount"
              :dropdown-items="dropdownItems"
              @search="handleSearch"
              @clear="handleSearchClear"
            />
          </div>

          <div
            v-if="!!filter"
            class="filter-section-wrapper"
          >
            <am2-tag
              class="filter-tag"
              :key="filter[1]"
              type="purple"
              shape="rectangle"
              has-cross
              text-size="xs"
              text-weight="bold"
              :remove-button-style="{
                background: 'none',
                color: $arStyle.color.purple500,
              }"
              @remove="handleRemoveFilter"
              :text="filterTagText"
            />
          </div>

          <MessageTable
            :table-headers="recipientTableHead"
            :table-data="tableData"
            :loading="isFetchingRecipients"
            :enable-row-click="customerProfilesEnabled"
            @sort="handleSortByChange"
            @row-click="handleRowClick"
          />
        </div>
      </section>
    </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import accounting from 'accounting';
import { convertToTitleCase, convertToKebabCase } from '@/utils/helpers';
import TableControl from './message-table/table-control.vue'
import MessageTable from './message-table/message-table.vue'
import { clone } from '@/utils/helpers/';

const menuItems = [
  'All recipients',
  'Opened',
  'Not opened',
  'Clicked',
  'Not clicked',
  'Unsubscribed',
  'Bounced',
  'Failed',
];

const menuItemsSms = [
'All recipients',
  'Clicked',
  'Not clicked',
  'Unsubscribed',
  'Failed',
];

export default {
  name: 'MessageViewRecipients',
  components: {
    TableControl,
    MessageTable,
  },

  props: {
    refreshRecipients: {
      type: Boolean,
      default: false,
    }
  },

  data() {
    return {
      messageStats: null,
      isFetchingMessageStats: null,
      recipientsSelectKeys: ['fan', 'status', 'sysMtime', 'status-details', 'promoterMessageOid'],
      recipientsExportSelectKeys: ['firstName','lastName','emailAddress','lastStatus','lastActivity'],
      sortRecipientBy: { key: 'sysMtime', order: 'desc' },
      recipientsStatus: null,
      searchString: null,
      filter: null,
      abSelectItems: [
        { key: 'all', name: 'All Recipients' },
        { key: 'A', name: 'Test A Recipients' },
        { key: 'B', name: 'Test B Recipients' },
      ],
      selectedVariantFilter: 'all',
      variantLookup: {},
    };
  },

  watch: {
    refreshRecipients(newVal) {
      if (newVal == true) {
        this.fetchMessageRecipients();
        this.$emit('refreshed-recipients');
      }
    },

    currentSelectedMessage(newVal, oldVal) {
      if (newVal && !oldVal) {
        if (newVal.provider === 'sms') {
          this.recipientsExportSelectKeys = ['firstName','lastName','emailAddress','lastStatus','lastActivity', 'mobileNumber'];
        } else {
          this.recipientsExportSelectKeys = ['firstName','lastName','emailAddress','lastStatus','lastActivity'];
        }
      }
    },

    filter() {
      this.reloadRecipients();
    },

    promoterMessages(newVal) {
      if (newVal) {
        this.variantLookup = {};
        for (const variant in newVal) {
          this.variantLookup[newVal[variant].promoterMessageOid] = variant;
        }
      }
    }
  },

  computed: {
    ...mapState({
      oid: state => state.route.params.oid,
      currentSelectedMessage: state => state.message.currentSelectedMessage,
      promoterMessages: state => state.message.scratchEmailMessageVariants,
      isFetchingMessage: state => state.message.isFetchingMessage,
      statsAB: state => state.message.statsAB,

      recipients: state => state.recipient.recipients,
      isExportingListRecipients: state => state.recipient.isExportingListRecipients,
      totalRecipientsCount: state => state.recipient.totalRecipientsCount,
      isFetchingRecipients: state => state.recipient.isFetchingRecipients,
      isNoMoreRecipients: state => state.recipient.isNoMoreRecipients,
      hasFetchRecipientsFailed: state => state.recipient.hasFetchRecipientsFailed,
    }),

    isSms() {
      return this.currentSelectedMessage?.provider === 'sms';
    },

    recipientsCount() {
      if (this.isAbVariantSelected && this.statsAB) {
        return this.calculateCountForAb()
      }
      return !!this.filteredRecipientsCount ? this.filteredRecipientsCount : this.totalRecipientsCount;
    },

    recipientsCountFormatted() {
      return accounting.formatNumber(this.recipientsCount);
    },

    disableLoadMoreRecipients() {
      return this.isFetchingRecipients || this.isNoMoreRecipients || this.hasFetchRecipientsFailed;
    },

    searchStringTargets() {
      if (!this.currentSelectedMessage) return null;
      const targets = ['status', 'fan[firstName]', 'fan[lastName]', 'fan[emailAddress]']
      return this.isSms ? [...targets, 'fan[mobileNumber]'] : targets
    },

    // Items which appear in the '...' dropdown button
    dropdownItems() {
      let dropdownActions = []

      dropdownActions.push({
        "name": "Export as CSV",
        "action": this.handleExportClick
      })

      return dropdownActions
    },

    clickTrackingDisabled() {
      if (!this.currentSelectedMessage) {
        return false;
      }
      return this.currentSelectedMessage?.meta?.disableLinkTracking;
    },

    customerProfilesEnabled() {
      return !!process.env.arEnableCustomerProfiles;
    },

    recipientTableHead() {
      let head;

      if (this.isSms) {
        head = [
          {
            name: 'Name',
            key: 'name',
            format: 'name',
            attributeKey: 'firstName,lastName',
          },
          {
            name: 'Mobile',
            key: 'mobileNumber',
            format: 'text',
            attributeKey: 'mobileNumber',
          },
          {
            name: 'Country Code',
            key: 'countryCode',
            format: 'text',
            attributeKey: 'countryCode',
          },
          {
            name: 'Clicks',
            key: 'clicks',
            format: 'text',
            attributeKey: 'clicks',
          },
        ]
      } else {
        head = [
          {
            name: 'Name',
            key: 'name',
            format: 'name',
            attributeKey: 'firstName,lastName',
          },
          {
            name: 'Email',
            key: 'emailAddress',
            format: 'emailAddress',
            attributeKey: 'emailAddress',
          },
          {
            name: 'Status',
            key: 'status',
            format: 'status',
            sortKey: 'status',
            attributeKey: 'status',
          },
          {
            name: 'Last open / click',
            key: 'sysMtime',
            format: 'lastActiveTime',
            sortKey: 'sysMtime',
            attributeKey: 'sysMtime',
          }
        ];

        if (this.currentSelectedMessage?.abEmail) {
          head.push({
            name: 'Test variant',
            key: 'messageVariant',
            format: 'messageVariant',
            sortKey: 'messageVariant',
            attributeKey: 'messageVariant'
          });
        }
      }

      return head;
    },

    messageStatus() {
      if (!this.currentSelectedMessage || !this.currentSelectedMessage.status) {
        return '';
      } else if (this.currentSelectedMessage.status === 'completed') {
        return 'Sent';
      } else if (this.currentSelectedMessage.status === 'in-progress') {
        return 'Sending';
      } else {
        return convertToTitleCase(this.currentSelectedMessage.status.replace(/-/, ' '));
      }
    },

    messageStatTotalSuccessfulSends() {
      if (!this.currentSelectedMessage || !this.currentSelectedMessage.statusDetails) {
        return 0;
      }
      return (this.currentSelectedMessage.statusDetails.totalMessages || 0)
        - (this.currentSelectedMessage.statusDetails.bounced || 0)
        - (this.currentSelectedMessage.statusDetails.deferred || 0)
        - (this.currentSelectedMessage.statusDetails.failed || 0);
    },

    messageStatsCount() {
      if (!this.currentSelectedMessage || !this.currentSelectedMessage.statusDetails) {
        return null;
      }
      return `${accounting.formatNumber(this.messageStatTotalSuccessfulSends)} of ${accounting.formatNumber(this.currentSelectedMessage.statusDetails.totalMessages)} sent`;
    },

    // Use message stats endpoint, but fall back to message->statusDetails if there are any issues
    statusDetails() {
      if (!this.messageStats && (!this.currentSelectedMessage || !this.currentSelectedMessage.statusDetails)) {
        return {};
      }

      return this.messageStats || this.currentSelectedMessage.statusDetails;
    },

    recipientsFilter() {
      if (!!this.filter && this.filter.length > 1) {
        return this.filter
      }
      return null
    },

    tableData() {
      const data = this.recipients.map(recipient => ({
        firstName: recipient.fan.firstName,
        lastName: recipient.fan.lastName,
        emailAddress: recipient.fan.emailAddress,
        mobileNumber: recipient.fan.mobileNumber,
        status: convertToTitleCase(recipient.status ? recipient.status.replace(/-/, ' ') : ''),
        lastActiveTime: recipient.sysMtime,
        oid: recipient.fan.oid,
        clicks: recipient.clicks,
        countryCode: recipient.countryCode,
        messageVariant: this.lookupRecipientVariant(recipient.promoterMessageOid)
      }))

      if (this.isAbVariantSelected) {
        return data.filter(recipient => recipient.messageVariant === this.selectedVariantFilter)
      } else {
        return data
      }
    },

    defaultSelectedIndexAbOptions() {
      return this.abSelectItems.findIndex(item => item.key === this.selectedVariantFilter) || 0
    },

    isAbEmail() {
      return this.currentSelectedMessage?.abEmail;
    },

    currentMenuItems() {
      return this.isSms ? menuItemsSms : menuItems;
    },

    verticalMenuItems() {
      if (!this.currentMenuItems) return [];
      return this.currentMenuItems.map(i => ({ key: convertToKebabCase(i), value: i }));
    },

    selectedMenuItemKey() {
      // If NULL return the default option all-recipients
      return this.recipientsStatus || convertToKebabCase(this.currentMenuItems?.[0]);
    },

    filterTagText() {
      if (!Array.isArray(this.filter)) return;
      const fullFilter = this.filter.join(': ');
      const maxLen = 40;

      const truncateString = (str, num) => {
        if (str.length > num) {
          return str.slice(0, num) + "...";
        } else {
          return str;
        }
      };

      return fullFilter.charAt(0).toUpperCase() + truncateString(fullFilter.slice(1), maxLen)
    },

    filteredRecipientsCount() {
      if (!!this.recipientsFilter) {
        const url = this.recipientsFilter[1]
        const snapshot = this.currentSelectedMessage?.statusDetails?.clicksSnapshot.find(o => o.targetUrl === url);
        return snapshot?.uniqueTotal || 0;
      }

      return null;
    },

    isAbVariantSelected() {
      return this.currentSelectedMessage?.abEmail && ['A', 'B'].includes(this.selectedVariantFilter)
    },
  },

  async mounted() {
    const { viewBy, filter, ab } = this.$route.query;

    if (typeof filter === 'string') {
      // Filter is a string like "clicked=https://www.youtube.com/watch?v=obuyCkotJ_s"
      // so split on the first `=` occurrence
      this.filter = filter.split(/=(.*)/s).slice(0, -1);
    }

    if (typeof viewBy === 'string') {
      this.recipientsStatus = viewBy;
    }

    if (ab) {
      this.selectedVariantFilter = ab;
    }

    this['message/FETCH_MESSAGE'](this.oid);

    if (!!this.currentSelectedMessage) {
      this.fetchMessageStats(this.oid);
      this.reloadRecipients();

      if (this.currentSelectedMessage.provider === 'sms') {
        this.recipientsExportSelectKeys = ['firstName','lastName','emailAddress','lastStatus','lastActivity', 'mobileNumber'];
      } else {
        this.recipientsExportSelectKeys = ['firstName','lastName','emailAddress','lastStatus','lastActivity'];
      }
    }
  },

  beforeDestroy() {
    this['message/RESET_CURRENT_SELECTED_MESSAGE']();
    this['campaign/RESET_CURRENT_CAMPAIGN']();
    this['recipient/RESET_RECIPIENTS']();
  },

  methods: {

    ...mapActions([
      'recipient/EXPORT_MESSAGE_RECIPIENTS_CSV',
      'FETCH_MESSAGE_STATS_BY_OID',
      'messageList/FETCH_CURRENT_MESSAGE_LIST',
      'recipient/FETCH_MESSAGE_RECIPIENTS',
      'messageSender/FETCH_CURRENT_MESSAGE_SENDER',
      'message/FETCH_MESSAGE',
    ]),

    ...mapMutations([
      'message/RESET_CURRENT_SELECTED_MESSAGE',
      'campaign/RESET_CURRENT_CAMPAIGN',
      'recipient/RESET_RECIPIENTS',
    ]),

    handleExportClick() {
      if (this.isExportingListRecipients) { return }

      this['recipient/EXPORT_MESSAGE_RECIPIENTS_CSV']({
        selectKeys: this.recipientsExportSelectKeys,
        taskOid: this.oid,
        orderBy: this.sortRecipientBy,
        searchString: this.searchString,
        recipientsFilter: this.recipientsFilter,
        status: this.computeRecipientsStatus(),
        combineStatusOp: this.computeCombineStatusOp(),
      })
    },

    computeRecipientsStatus() {
      let recipientsStatus;
      if(this.recipientsStatus === 'sent') {
        recipientsStatus = ['sent', 'opened', 'clicked'];
      } else if (this.recipientsStatus === 'not-opened') {
        recipientsStatus = ['not-opened', 'not-clicked', 'not-unsubscribed'];
      } else if (this.recipientsStatus === 'opened' && this.currentSelectedMessage.provider !== 'sms' ) {
        recipientsStatus = ['opened', 'clicked', 'unsubscribed'];
      } else if (this.recipientsStatus === 'failed' && this.currentSelectedMessage.provider === 'email') {
        recipientsStatus = ['failed', 'deferred'];
      } else if (this.recipientsStatus === 'failed' && this.currentSelectedMessage.provider === 'sms') {
        recipientsStatus = ['failed', 'undelivered'];
      } else {
        recipientsStatus = [this.recipientsStatus];
      }
      return recipientsStatus;
    },

    lookupRecipientVariant(promoterMessageOid) {
      return this.variantLookup[promoterMessageOid] || null;
    },

    // Returns the logical operator for combining the recipientsStatus array
    computeCombineStatusOp() {
      if (this.recipientsStatus === 'not-opened' || this.recipientsStatus === 'not-clicked') {
        return 'AND'
      }
      return 'OR'
    },

    async fetchMessageRecipients (
      fetchCount = true,
      reload = false,
    ) {
      await this['recipient/FETCH_MESSAGE_RECIPIENTS']({
        messageTaskOid: this.oid,
        selectKeys: this.recipientsSelectKeys,
        status: this.computeRecipientsStatus(),
        fetchCount,
        orderBy: this.sortRecipientBy,
        searchString: this.searchString,
        searchStringTargets: this.searchStringTargets,
        reload,
        combineStatusOp: this.computeCombineStatusOp(),
        recipientsFilter: this.recipientsFilter,
        isSms: this.isSms,
        promoterMessages: this.promoterMessages
      });
    },

    async reloadRecipients() {
      this.fetchMessageRecipients(
        true,
        true // reload
      );
    },

    async loadMoreRecipients() {
      this.fetchMessageRecipients(
        true,
        false // reload
      );
    },

    async fetchMessageStats(oid) {
      try {
        this.isFetchingMessageStats = true;
        const messageStats = await this.FETCH_MESSAGE_STATS_BY_OID({oid});
        if (messageStats) {
          this.messageStats = messageStats;
        }
      } catch (e) {
        console.error(e);
        this.$arNotification.push({ type: 'error', message: 'Failed to fetch message stats' });
      } finally {
        this.isFetchingMessageStats = false;
      }
    },
    async fetchMessageList(oid) {
      await this['messageList/FETCH_CURRENT_MESSAGE_LIST']({oid});
      return true;
    },
    fetchMessageSender(oid) {
      this['messageSender/FETCH_CURRENT_MESSAGE_SENDER']({
        oid,
        allowDeleted: true,
      });
    },

    handleRecipientsMenuSelect(item) {
      if (item.key === this.$route.query.viewBy) return;

      if (item.key === 'all-recipients') {
        this.recipientsStatus = null;
      } else {
        this.recipientsStatus = item.key;
      }

      // Update query params to change the state
      if (!!this.recipientsFilter) {
        // Remove the filter and change the viewBy
        this.removeFilter(this.recipientsStatus);
      } else {
        // Changes the viewBy only without updating the navigation history
        this.changeRouteViewByStatus(this.recipientsStatus);
      }

      this.reloadRecipients()
      this.fetchMessageStats(this.oid)
      this.fetchMessageList(this.currentSelectedMessage?.meta?.messageListOid)
      if (this.currentSelectedMessage?.meta?.email) {
        this.fetchMessageSender(this.currentSelectedMessage.meta.email.promoterPropertyOid)
      }
    },

    handleSortByChange(sortBy) {
      if (sortBy.key === 'messageVariant') return;
      this.sortRecipientBy = sortBy;
      this.reloadRecipients();
    },

    changeRouteViewByStatus(newVal) {
      const query = this.$route.query;
      this.$router.replace({ path: this.$route.path, query: { ...query, viewBy: newVal }});
    },

    handleRowClick(target) {
      if (this.customerProfilesEnabled && target && target.oid) {
        this.$router.push({
          path: `/audience/${target.oid}/view/overview`,
        });
      }
    },

    handleSearch(searchString) {
      this.searchString = searchString
      this.reloadRecipients()
    },
    handleSearchClear() {
      this.searchString = ''
      this.reloadRecipients()
    },

    handleRemoveFilter() {
      this.removeFilter();
    },

    removeFilter(viewBy) {
      this.filter = null;
      const q = clone(this.$route.query);
      delete q.filter;

      if (!!viewBy) {
        this.$router.push({ query: { ...q, viewBy } });
      } else {
        this.$router.push({ query: q });
      }
    },

    handleAbItemSelect(item) {
      this.selectedVariantFilter = item.key
      const query = this.$route.query;
      this.$router.replace({ path: this.$route.path, query: { ...query, ab: this.selectedVariantFilter }})
    },

    calculateCountForAb() {
      const index = this.selectedVariantFilter === 'A' ? 0 : 1;

      if (!this.statsAB[index] || !this.statsAB[index].statusDetails) return 0

      if (!this.recipientsStatus) {
        return this.statsAB[index].statusDetails.totalMessages || 0
      }

      if (this.recipientsStatus === 'opened') {
        return this.statsAB[index].statusDetails.opened + this.statsAB[index].statusDetails.clicked || 0
      }

      if (this.recipientsStatus === 'not-opened') {
        return this.statsAB[index].statusDetails.totalMessages -
          (this.statsAB[index].statusDetails.opened + this.statsAB[index].statusDetails.clicked ) || 0
      }

      if (this.recipientsStatus === 'clicked') {
        return this.statsAB[index].statusDetails.clicked || 0
      }

      if (this.recipientsStatus === 'not-clicked') {
        return this.statsAB[index].statusDetails.totalMessages - this.statsAB[index].statusDetails.clicked || 0
      }

      if (this.recipientsStatus === 'unsubscribed') {
        return this.statsAB[index].statusDetails.unsubscribed || 0
      }

      if (this.recipientsStatus === 'bounced') {
        return this.statsAB[index].statusDetails.bounced || 0
      }

      if (this.recipientsStatus === 'failed') {
        return this.statsAB[index].statusDetails.failed || 0
      }

      return 0
    }
  }
};
</script>

<style lang="scss" scoped>
@import '~/assets/styles/base/mixins';

@mixin flex-row {
  display: flex;
  flex-flow: row nowrap;
}

.message-center-recipients {
  display: flex;
  flex-direction: row;

  width: 90%;
  max-width: 1200px;
  margin: 0 auto;
  gap: 20px;

  &-wrapper {
    padding-bottom: 100px;
    margin-top: 56px;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  &-left > div > div {
    padding: 0 16px;
  }

  &-right {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .message-tabs-wrapper {
    @include flex-row;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 80px;
    background-color: white;
    border-bottom: 1px solid $skyBlueGrey500;

    .message-tabs-container {
      width: 80%;
      height: 100%;
      @include flex-row;
      align-items: center;
      justify-content: space-between;

      .tabs-left-container {
        height: 100%;
        @include flex-row;
        align-items: center;
        justify-content: flex-start;

        .tab-wrapper {
          height: 100%;
          @include flex-row;
          align-items: center;
          justify-content: center;
          margin-right: 30px;
          cursor: pointer;
          color: $blueGrey700;

          .tab-btn {
            background: white;
            border: none;
          }
        }

        .tab-wrapper:last-child {
          margin-right: 0px;
        }
      }
    }
  }

  .link-tracking-wrapper {
    width: 100%;
    @include flex-row;
    align-items: center;
    justify-content: center;
  }

  .recipients-count-wrapper {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    margin-bottom: 25px;

    & > div {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: flex-start;
    }
  }

  .recipients-ab-select {
    margin-left: auto;
  }

  .sent-numbers-wrapper {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    width: 100%;
    transition: all 0.3s;

    .sent-numbers-container {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: flex-start;
      padding: 30px 0;
    }
  }

  .table-control-wrapper {
    position: sticky;
    top: 0px;
    z-index: 12;
    width: 100%;
  }

  .filter-section-wrapper {
    position: sticky;
    top: 0px;
    z-index: 12;
    width: 100%;
    padding: 14px;
    background-color: white;
    border: 1px solid $skyBlueGrey500;
    border-bottom: none;
  }
}

.fade-enter {
  opacity: 0;
  visibility: hidden;
}
.fade-enter-active {
  transition: opacity 0.4s ease;
}
/* .fade-leave {
  } */
.fade-leave-active {
  transition: 0.2s opacity ease;
  opacity: 0;
  visibility: hidden
}
</style>
