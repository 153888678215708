var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{class:[
    'customer-profile-overview',
    _vm.$arMediaQuery.pageContent.maxWidth('md') && 'md-max' ]},[_c('am2-select-date-range-modal',{attrs:{"is-show":_vm.showSelectDateRangeModal},on:{"select":_vm.handleDateRangeSelect,"cancel":_vm.handleDateRangeCancel}}),_vm._v(" "),_c('div',{class:[
    'left-side',
    _vm.$arMediaQuery.pageContent.only('xs') && 'xs-only',
    _vm.$arMediaQuery.pageContent.between('sm', 'md') && 'between-sm-md' ]},[_c('am2-card-container',{attrs:{"layout":"soft"}},[[_c('am2-data-list',{staticClass:"data-list-container",attrs:{"title":"Profile","items":_vm.userData,"delete-data":_vm.deleteData,"save-data":_vm.saveData}}),_vm._v(" "),(_vm.arEnableCustomFields)?_c('am2-title-value-list',{staticClass:"data-list-container",attrs:{"title":"Custom fields","items":_vm.filteredCustomFieldData,"delete-data":_vm.deleteData,"save-data":_vm.saveCustomFieldData,"show-more-items":_vm.showMoreCustomFieldItems,"toggle-cutoff":_vm.customFieldMinimumItems},on:{"toggleItemDisplay":_vm.toggleCustomFieldItemDisplay}}):_vm._e()],_vm._v(" "),[_c('ar-divider',{attrs:{"type":"soft"}}),_vm._v(" "),_c('am2-tags-list',{staticClass:"tags-list-container",attrs:{"loading":!_vm.customerState.customer,"fan-oid":!!_vm.customerState.customer ? _vm.customerState.customer.oid : null,"input-styles":{
            height: '40px',
          }}})],_vm._v(" "),(_vm.arEnableFanActiveMembership)?[_c('ar-divider',{attrs:{"type":"soft"}}),_vm._v(" "),_c('am2-active-membership-list',{staticClass:"active-membership-list"})]:_vm._e(),_vm._v(" "),[_c('ar-divider',{attrs:{"type":"soft"}}),_vm._v(" "),_c('am2-messaging-lists-list',{class:[
            'messaging-lists-list',
            !_vm.arEnableFanActiveMembership && 'not-enable-fan-active-membership' ],attrs:{"fan-oid":!!_vm.customerState.customer ? _vm.customerState.customer.oid : null}})]],2)],1),_vm._v(" "),_c('div',{staticClass:"right-side"},[_c('am2-card-container',{directives:[{name:"ar-feature-mask",rawName:"v-ar-feature-mask",value:({
        show: !_vm.isCustomerProfileFeatureEnabled,
        showUpgradeOn: 'hover',
        iconName: 'audience',
        title: _vm.lockedTitle.overview,
        message: 'Unlock customer profiles to get an overview for each fan.',
      }),expression:"{\n        show: !isCustomerProfileFeatureEnabled,\n        showUpgradeOn: 'hover',\n        iconName: 'audience',\n        title: lockedTitle.overview,\n        message: 'Unlock customer profiles to get an overview for each fan.',\n      }"}],ref:"overviewContainer",staticClass:"cell",style:({
        position: 'relative',
        padding: '6px',
        paddingBottom: !_vm.isCustomerProfileFeatureEnabled ? '54px' : null,
      }),attrs:{"locked":!_vm.isCustomerProfileFeatureEnabled,"layout":"soft"}},[_c('am2-overview-bar',{style:({
          paddingBottom: '6px',
        }),attrs:{"values":_vm.overviewBarValues,"title":"Overview","layout":"box-layout","loading":_vm.isFetchingCustomer || _vm.isFetchingPurchases || _vm.isFetchingActivity,"dropdown-width":220,"dropdown":_vm.computedDateRangeFiltersOverview,"dropdown-index":_vm.salesDataOverviewIndex,"blur-values":!_vm.isCustomerProfileFeatureEnabled},on:{"select":function (item) { return this$1.setSalesDataFilter(item.key, 'overview'); }}})],1),_vm._v(" "),_c('am2-card-container',{directives:[{name:"ar-feature-mask",rawName:"v-ar-feature-mask",value:({
        show: !_vm.isCustomerProfileFeatureEnabled,
        showUpgradeOn: 'hover',
        iconName: 'audience',
        title: _vm.lockedTitle.salesHistory,
        message: 'Unlock customer profiles to see sales over time for each fan.',
      }),expression:"{\n        show: !isCustomerProfileFeatureEnabled,\n        showUpgradeOn: 'hover',\n        iconName: 'audience',\n        title: lockedTitle.salesHistory,\n        message: 'Unlock customer profiles to see sales over time for each fan.',\n      }"}],staticClass:"cell sales-chart",attrs:{"layout":"soft"}},[_c('div',{staticClass:"dropdown"},[_c('ar-simple-select',{attrs:{"items":_vm.computedDateRangeFiltersChart.items,"default-select-index":_vm.salesDataChartIndex,"placeholder":_vm.$arMediaQuery.pageContent.maxWidth('xs') ? 'Date' : 'Date range',"dropdown-width":_vm.$arMediaQuery.pageContent.minWidth('sm') ? 220 : 152,"align":"right"},on:{"select":function (item) { return this$1.setSalesDataFilter(item.key, 'chart'); }}})],1),_vm._v(" "),(_vm.salesChartData)?_c('am2-multiple-column-charts',{ref:"salesChart",style:({
          height: '400px',
          margin: '30px',
        }),attrs:{"hide-switch-buttons":"","loading":_vm.isFetchingCustomer || !_vm.customer,"topics":_vm.salesChartData.topics,"selected-title-topic":_vm.salesDataChartSelectedTitleTopic,"title-dropdown-options":_vm.salesDataChartTitleOptions,"chart-datas":_vm.salesChartData.chartDatas,"chart-height":400,"topic-index":0,"locked":!_vm.isCustomerProfileFeatureEnabled},on:{"titleTopicChange":_vm.handleSalesChartTitleTopicChange}}):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }