<template>
  <!-- This page supports infinite scroll -->
  <section
    class="message-lists-page-wrapper"
    v-infinite-scroll="fetchMoreMessageLists"
    infinite-scroll-disabled="disableFetchMoreMessageLists"
    :infinite-scroll-distance="10"
  >
    <am2-create-message-list-modal
      :is-show="displayCreateMessageListModal"
      @close="handleCreateMessageListModalClose"
    />
    <am2-message-list-preferences-modal
      :is-show="displayMessageListPreferencesModal"
      @close="handleMessageListPreferencesModalClose"
    />
    <div
      :class="{
        content: true,
        ['content']: true,
      }"
    >
      <am2-top
        :expand-icon-button="{
          iconName: 'settings',
          text: 'Settings',
          'v-tooltip.bottom': {
            content: 'List settings'
          },
          action: handleSettingsClick
        }"
        :searchLeft="{
          action: serverSideSearch,
          placeholder: 'Search lists',
          value: searchString,
          style: {
            width: $arMediaQuery.pageContent.minWidth('md') ? '330px' : '100%'
          }
        }"
        :class="[
          'top-wrapper',
          $arMediaQuery.pageContent.maxWidth('sm') && 'sm-max',
        ]" />

      <div
        v-for="list of messageLists"
        :key="list.oid"
        :style="{ marginBottom: $arMediaQuery.pageContent.maxWidth('sm') ? '12px' : '20px' }"
      >
        <am2-messaging-list-card
          :messaging-list="list"
          :show-as="$arMediaQuery.pageContent.maxWidth('sm') ? 'tiles' : 'list'"
        />
      </div>
      <div
        v-if="!isFetchingMessageLists && messageLists.length === 0"
      >
        <am2-no-content-section
          v-if="searchString"
          header="No lists found"
          header-size="sm"
        />
        <am2-no-content-section
          v-else
          icon-name="ar-list-in-circle"
          header="Create a List"
          body="Lists are where your subscribers are managed across email and SMS"
          button-text="Create a list"
          @buttonClick="handleCreateListClick"
        />
      </div>
      <div
        :style="{
          height: '80px',
        }"
      >
        <am2-card-skeleton
          v-for="n in skeletonCardsCount"
          :key="n"
          :level="2 * n - 1"
          type="message-list"
          :show-as="$arMediaQuery.pageContent.maxWidth('sm') ? 'tiles' : 'list'"
          :style="{
            marginBottom: '20px',
            width: '100%',
            border: '1px solid #EDF1F5',
          }"
         />
      </div>
    </div>
  </section>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';

export default {
  name: 'MessageLists',

  props: {
    createButtonClicked: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    searchString: '',
    displayCreateMessageListModal: false,
    displayMessageListPreferencesModal: false,
  }),

  created() {
    this['messageList/RESET_MESSAGE_LISTS']();
    this.reloadMessageLists();
    this.loadSubscribePreferences();
  },

  watch: {
    createButtonClicked(newVal, oldVal) {
      if (newVal && newVal !== oldVal) {
        this.handleCreateListClick();
        this.$emit("cancelCreateClicked");
      }
    }
  },

  computed: {
    ...mapState({
      promoterOid: state => state.auth.account.promoterOid,
      messageLists: state => state.messageList.messageLists,
      hasNoMoreMessageLists: state => state.messageList.hasNoMoreMessageLists,
      isFetchingMessageLists: state => state.messageList.isFetchingMessageLists,
      hasFetchMessageListsFailed: state => state.messageList.hasFetchMessageListsFailed,
    }),
    disableFetchMoreMessageLists() {
      return this.hasNoMoreMessageLists || this.isFetchingMessageLists || this.hasFetchMessageListsFailed;
    },
    skeletonCardsCount() {
      if (!this.isFetchingMessageLists) {
        return 0;
      } else {
        return this.messageLists.length === 0 ? 5 : 1;
      }
    },
  },

  methods: {
    ...mapMutations([
      'messageList/RESET_MESSAGE_LISTS',
    ]),
    ...mapActions([
      'messageList/FETCH_MORE_MESSAGE_LISTS',
      'messageList/FETCH_SUBSCRIPTION_PREFERENCE',
      'SHOW_CONFIRM',
    ]),

    async fetchMoreMessageLists() {
      this['messageList/FETCH_MORE_MESSAGE_LISTS']({
        filterString: this.searchString,
        userDefined: true,
      });
    },

    async reloadMessageLists() {
      this['messageList/FETCH_MORE_MESSAGE_LISTS']({
        filterString: this.searchString,
        reload: true,
        userDefined: true
      });
    },

    async loadSubscribePreferences() {
      this['messageList/FETCH_SUBSCRIPTION_PREFERENCE'](this.promoterOid);
    },

    async serverSideSearch(search) {
      this.searchString = search;
      this.reloadMessageLists();
    },

    handleCreateListClick() {
      this.displayCreateMessageListModal = true;
    },

    handleCreateMessageListModalClose() {
      this.displayCreateMessageListModal = false;
    },

    handleSettingsClick() {
      this.displayMessageListPreferencesModal = true;
    },

    handleMessageListPreferencesModalClose() {
      this.displayMessageListPreferencesModal = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.message-lists-page-wrapper {
  display: flex;
  margin-top: 56px;
  max-width: 1296px;
  .content {
    transition: 0.3s all;
    margin-left: 0;
    width: 100%;
    padding: 0 24px;

    .loading-section {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
    }

    .top-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      margin: 0 0 35px;

      &.sm-max {
        margin: 0 0 22px;
        flex-direction: column;
        justify-content: unset;
        align-items: flex-start;
      }
    }
  }
}
</style>
