<template>
  <section :class="['message-center-overview', 'content', $arMediaQuery.pageContent.minWidth('md') && 'md-max']">
    <div class="overview-wrapper">
      <div class="overview-full">
        <RecipientsOverviewStats
          :overview-stats="overviewStats"
          :is-sms="isSms"
          :is-ab-email="isAbEmail"
          :oid="oidInteger"
          :ab-preview-winner="abPreviewWinner"
          :ab-type="abType"
        />
      </div>

      <div class="overview-cards-h">
        <div class="overview-delivery-summary">
          <DeliverySummaryStats :overview-stats="overviewStats" :is-sms="isSms" :oid="oidInteger" />
        </div>

        <div class="overview-open-clicks-chart"><OverviewOpenClicksChart /></div>
      </div>

      <div
        class="overview-cards-h overview-cards-h--convers-links"
        :class="[$arMediaQuery.pageContent.maxWidth('md') && 'overview-cards-h--convers-links-md']"
      >
        <div class="overview-top-links">
          <OverviewTopLinks
            :current-selected-message="currentSelectedMessage"
            :short-urls="shortUrls"
            :oid="oidInteger"
          />
        </div>

        <div class="overview-conversions"><conversion-circle-chart /></div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import RecipientsOverviewStats from './components/recipients-overview-stats.vue';
import DeliverySummaryStats from './components/delivery-summary-stats.vue';
import OverviewOpenClicksChart from './components/overview-open-clicks-chart.vue';
import OverviewTopLinks from './components/overview-top-links.vue';
import ConversionCircleChart from '~/pages/message-center/messages/view/components/conversion-circle-chart.vue';

export default {
  name: 'MessageViewOverview',
  components: {
    ConversionCircleChart,
    RecipientsOverviewStats,
    DeliverySummaryStats,
    OverviewOpenClicksChart,
    OverviewTopLinks
  },
  props: {
    abPreviewWinner: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      messageStats: null
    };
  },

  computed: {
    ...mapState({
      oid: state => state.route.params.oid,
      currentSelectedMessage: state => state.message.currentSelectedMessage,
      isFetchingMessage: state => state.message.isFetchingMessage,

      recipients: state => state.recipient.recipients,
      shortUrls: state => state.shortUrls.shortUrls,
      isExportingListRecipients: state => state.recipient.isExportingListRecipients,
      isFetchingRecipients: state => state.recipient.isFetchingRecipients,
      isNoMoreRecipients: state => state.recipient.isNoMoreRecipients,
      hasFetchRecipientsFailed: state => state.recipient.hasFetchRecipientsFailed,
      statsAB: state => state.message.statsAB
    }),

    isSms() {
      return this.currentSelectedMessage?.provider === 'sms';
    },

    overviewStats() {
      if (!!this.currentSelectedMessage && !!this.currentSelectedMessage.statusDetails) {
        const {
          totalMessages,
          sent,
          opened,
          clicked,
          unsubscribed,
          bounced,
          failed,
          deferred
        } = this.currentSelectedMessage.statusDetails;

        const stats = {
          total: totalMessages || 0,
          sent: (sent || 0) + (opened || 0) + (clicked || 0) + (unsubscribed || 0),
          opened: (opened || 0) + (clicked || 0) + (unsubscribed || 0),
          clicked: clicked || 0,
          unsubscribed: unsubscribed || 0,
          bounced: bounced || 0,
          failed: (deferred || 0) + (failed || 0)
        };

        const ctorValue = stats.opened === 0 ? 0 : (stats.clicked / stats.opened) * 100;

        return {
          ...stats,
          ctor: ctorValue > 0 && ctorValue < 10 ? ctorValue.toFixed(1) : Math.trunc(ctorValue) || 0
        };
      }

      return {
        total: 0,
        sent: 0,
        opened: 0,
        clicked: 0,
        bounced: 0,
        unsubscribed: 0,
        failed: 0,
        ctor: 0
      };
    },

    oidInteger() {
      if (typeof this.oid === 'string' || this.oid instanceof String) return parseInt(this.oid, 10);
      else return this.oid;
    },
    isAbEmail() {
      return this.currentSelectedMessage?.abEmail ?? false;
    },
    abType() {
      return this.currentSelectedMessage?.abEmailType ?? '';
    }
  },

  async mounted() {
    if (!!this.currentSelectedMessage) {
      this.fetchMessageStats(this.oid);
      this.fetchMessageRecipients();

      this['shortUrls/FETCH_SHORT_URLS']({ taskOid: this.oid });
    }
  },

  methods: {
    ...mapActions([
      'FETCH_MESSAGE_STATS_BY_OID',
      'messageList/FETCH_CURRENT_MESSAGE_LIST',
      'recipient/FETCH_MESSAGE_RECIPIENTS',
      'messageSender/FETCH_CURRENT_MESSAGE_SENDER',
      'shortUrls/FETCH_SHORT_URLS'
    ]),

    async fetchMessageRecipients() {
      await this['recipient/FETCH_MESSAGE_RECIPIENTS']({
        messageTaskOid: this.oid,
        selectKeys: this.recipientsSelectKeys,
        status: null,
        fetchCount: true,
        reload: false
      });
    },

    async fetchMessageStats(oid) {
      try {
        this.isFetchingMessageStats = true;
        const messageStats = await this.FETCH_MESSAGE_STATS_BY_OID({ oid });
        if (messageStats) {
          this.messageStats = messageStats;
        }
      } catch (e) {
        console.error(e);
        this.$arNotification.push({ type: 'error', message: 'Failed to fetch message stats' });
      } finally {
        this.isFetchingMessageStats = false;
      }
    },

    async fetchMessageList(oid) {
      await this['messageList/FETCH_CURRENT_MESSAGE_LIST']({ oid });
      return true;
    },

    fetchMessageSender(oid) {
      this['messageSender/FETCH_CURRENT_MESSAGE_SENDER']({
        oid,
        allowDeleted: true
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.message-center-overview {
  margin-top: 56px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 100%;
  padding: 0;

  .overview-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    width: 90%;
    max-width: 1200px;
  }

  .overview-cards-h {
    width: 100%;
    display: flex;
    flex-direction: row;
    margin-bottom: 24px;
    flex-wrap: wrap;
    gap: 24px;
  }

  .overview-cards-h--convers-links {
    flex-wrap: nowrap;
  }

  .overview-cards-h--convers-links-md {
    flex-direction: column;

    .overview-conversions {
      max-width: unset;
    }
  }
}

.overview-delivery-summary {
  flex: 2 0 300px;
}

.overview-open-clicks-chart {
  flex: 3 0 450px;
}

.overview-top-links {
  width: 100%;
}

.overview-full {
  width: 100%;
}

.overview-conversions {
  width: 100%;
  max-width: 505px;
  overflow: hidden;
}
</style>
