<template>
  <section
    class="email-edit-page"
    :class="{ 'email-edit-page--template': step === 'template' }"
  >
    <am2-put-email-template-name-modal
      :opened="displaySaveCurrentEmailTemplateModal"
      :loading="isCreatingEmailTemplate"
      @confirm="handleSaveCurrentEmailTemplateConfirm"
      @cancel="handleSaveCurrentEmailTemplateCancel"
    />
    <am2-put-email-template-name-modal
      :opened="displayCreateEmailTemplateModal"
      :loading="isCreatingEmailTemplate"
      @confirm="handleCreateEmailTemplateConfirm"
      @cancel="handleCreateEmailTemplateCancel"
    />
    <am2-select-email-template-modal
      :opened="displaySelectEmailTemplate"
      @select="handleSelectEmailTemplateSelect"
      @cancel="handleSelectEmailTemplateCancel"
      :templateType="templateType"
    />
    <am2-prepare-to-send-modal
      :opened="displayPrepareToSendModal"
      :isEmailSending="isEmailSending"
      :scratchEmailMessage="scratchEmailMessage"
      @cancel="handlePrepareToSendCancel"
      @close="handlePrepareToSendClose"
      @send="sendMessage"
      @clickError="handleClickError"
    />
    <am2-dynamic-tag-fallback-modal
      :opened="displayTagFallbackModal"
      @cancel="handleTagFallBackClose"
      @close="handleTagFallBackClose"
    />

    <am2-send-test-email-modal :opened="displaySendTestEmailModal" @cancel="handleSendTestEmailCancel" />
    <am2-ab-email-modal :opened="displayAbEmailModal" @cancel="handleAbEmailModalCancel" @create="enableAbEmail" />
    <!-- :message="scratchEmailMessage" -->
    <am2-email-preview-modal
      :opened="displayEmailPreviewModal"
      :subject="scratchSubject"
      :preview-text="scratchPreviewText"
      :scheduled-at="scratchScheduledAt"
      :sender-name="scratchSenderName"
      :email-html="scratchEmailWithInfo"
      :message="scratchEmailMessage"
      :is-ab-select-disabled="isAbSelectDisabled"
      show-share-link
      @close="handleEmailPreviewClose"
    />
    <am2-shareable-link-modal
      :opened="displaySharablePreviewLinkModal"
      :messageOid="messageOid"
      @close="handleSharablePreviewLinkModalClose"
    />
    <div :class="['header-section']">
      <am2-unlock-prompt
        v-if="displayUnlockPrompt"
        title="Unlock Email Marketing"
        subtitle="Upgrade to a paid plan now to start sending emails today!"
        @click="handleGoToPlansClick"
        :size="$arMediaQuery.pageContent.maxWidth('xs') ? 'small' : 'medium'"
      />
      <am2-top-create
        :exit-path="'path'"
        :title-icon="{
          name: 'email',
          color: $arStyle.color.email,
          width: '18px'
        }"
        :style="{
          position: 'relative'
        }"
        :show-exit-button="false"
      >
        <div v-if="currentStep == 1" slot="content" class="top-create-content">
          <div class="top-create-left-content">
            <am2-step-link
              v-if="currentStep == 1"
              text="Back to messaging"
              @click="handleBackLinkClick"
              :style="{
                height: '40px',
                padding: '0 20px'
              }"
            />
          </div>
          <div class="top-create-right-content">
            <div v-tooltip.bottom="{content: !scratchEmailMessage.meta.presentation.template ? 'Design your email to preview.' : ''}">
              <ar-link-button
                text="Preview"
                data-test-id="preview-email-button"
                @click="handlePreviewClick"
                :disabled="!scratchEmailMessage.meta.presentation.template"
                :style="{
                height: '40px',
                padding: '0 20px'
              }"
              />
            </div>
            <div v-tooltip.bottom="{content: !scratchEmailMessage.meta.presentation.template ? 'Design your email to send a test.' : '' }">
              <ar-link-button
                text="Send a test"
                data-test-id="test-email-button"
                @click="handleTestEmailClick"
                :disabled="!scratchEmailMessage.meta.presentation.template"
                :style="{
                height: '40px',
                padding: '0 20px'
                }"
                align="left"
                @select="handleTemplateItemSelect"
              />
            </div>
            <ar-link-button
              v-if="hasImportDynamicTagsLink && step === 'template' && isBeefree"
              text="Import dynamic tags"
              class="u-margin-right-7"
              data-test-id="import-dynamic-tags-button"
              @click="handleImportDynamicTagsLinkClick"
            />
            <ar-simple-button
              v-if="isEmailFeatureEnabled"
              text="Prepare to send"
              :style="{
                height: '40px',
                padding: '0 20px'
              }"
              @click="handlePreparetoSendClick"
            />
            <ar-simple-button
              v-if="!isEmailFeatureEnabled"
              text="Prepare to send"
              :style="{
                height: '40px',
                padding: '0 20px'
              }"
              icon-name="lock"
              disabled
              outlined
              v-tooltip.top="{
                content:
                  'A <strong>Basic</strong> or <strong>Pro</strong> plan (with the Email Marketing Add-On) or a <strong>Plus</strong> plan is required before you can send emails.'
              }"
              @click="handleGoToPlansClick"
              data-test-id="send-email-button"
            />
          </div>
        </div>
        <div v-if="currentStep == 2" slot="content" class="top-create-content">
          <div class="top-create-left-content">
            <ar-text size="sm" weight="bold" text="Design your email" />
            <ar-simple-select
              v-if="scratchEmailMessage.abEmail && scratchEmailMessage.abEmailType === 'design'"
              :style="{ width: '120px', height: '40px', marginLeft: '10px', marginRight: '10px' }"
              enable-filter
              class="selector"
              :items="abOptions"
              @select="handleAbSelectClick"
              :default-select-index="abSwitchIndex"
            />
          </div>
          <div class="top-create-right-content">
            <am2-link-button-dropdown
              v-if="step === 'template'"
              class="u-margin-right-9"
              placeholder="Template"
              :items="templateDropdownItems"
              :dropdown-style="{
                padding: '5px 0',
                width: '207px'
              }"
              :item-style="{
                height: '40px',
                padding: '0 20px'
              }"
              align="left"
              @select="handleTemplateItemSelect"
            />
            <am2-link-button-dropdown
              v-if="step === 'template'"
              placeholder="Preview"
              class="u-margin-right-7"
              :items="previewDropdownItems"
              :dropdown-style="{
                padding: '5px 0',
                width: '250px'
              }"
              :item-style="{
                height: '40px',
                padding: '0 20px'
              }"
              align="left"
              @select="handlePreviewItemSelect"
            />
            <ar-link-button
              v-if="hasImportDynamicTagsLink && step === 'template' && isBeefree"
              text="Import dynamic tags"
              class="u-margin-right-7"
              data-test-id="import-dynamic-tags-button"
              @click="handleImportDynamicTagsLinkClick"
            />

            <ar-simple-button
              text="Save and Exit"
              :style="{
                height: '40px',
                padding: '0 20px'
              }"
              @click="handleSaveHtmlClick"
            />
          </div>
        </div>
      </am2-top-create>
    </div>
    <div class="body-section" :class="{ 'small-width': currentStep == 1 }">
      <div :class="['edit-message-section', (hasNoSpaceForRightSidePreview || fullScreenMode) && 'full-size']">
        <div
          :class="[
            'edit-message-form-section',
            $arMediaQuery.pageContent.maxWidth('sm') && 'small-window-size',
            fullScreenMode && 'no-padding'
          ]"
        >
          <div
            :class="[
              'page-content',
              $arMediaQuery.pageContent.maxWidth('sm') && 'small-window-size',
              fullScreenMode && 'full-size'
            ]"
          >
            <nuxt-child
              v-if="messageInitializationIsCompleted"
              ref="modify-email-page"
              :beefree-show-preview="beefreeShowPreview"
              :message-initialization-is-complete="messageInitializationIsCompleted"
              @saveDraftAndRedirectToSettings="handleSaveAndRedirectToSettings"
              @showImportDynamicTagsLink="hasImportDynamicTagsLink = true"
              @setBeefreeShowPreview="setBeefreeShowPreview"
              @setBeefreeShowStructure="setBeefreeShowStructure"
              @setBeefreeShowPreviewDynamicTags="setBeefreeShowPreviewDynamicTags"
              @generateHtml="getScratchEmailWithInfo"
              @disableFields="handleDisableFields"
              @editSchedule="handleEditSchedule"
              @cancelSchedule="handleCancelSchedule"
            />
          </div>
        </div>
      </div>
      <div
        v-show="currentStep === 1"
        :class="[
          'preview-section',
        ]"
      >
        <div class="preview-wrapper" :class="disableSubForms == true ? 'disabled' : ''">
          <div class="preview">
            <MessageDetailsDesignBlock
              ref="designBlock"
              @update="handleDesignEmailClick"
              @abEmailChange="handleAbEmailChange"
              @template="handleDesignTemplate"
              :scratch-email-message="scratchEmailMessage"
              @variantChange="handleVariantChange"
              @unlayerChange="handleUnlayerDesignLoad"
            />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapActions, mapMutations, mapState, mapGetters } from 'vuex';
import { debounce } from 'debounce';
import MessageDetailsDesignBlock from '../../messages/view/message-details-design-block.vue';
import { recipientFilterToTargetingRules, recipientFilterToSidebar } from '@/store/modules/message/utils';
import {defaultSettingsPath, clone} from "@/utils/helpers";

export default {
  name: 'EmailEdit',
  layout: 'edit',

  components: {
    MessageDetailsDesignBlock
  },

  data() {
    return {
      unsavedMessage: null,
      displaySaveCurrentEmailTemplateModal: false,
      saveCurrentEmailTemplateSuccessCallBack: () => {},
      displayCreateEmailTemplateModal: false,
      displaySelectEmailTemplate: false,
      displaySendTestEmailModal: false,
      displayAbEmailModal: false,
      displayEmailPreviewModal: false,
      displayPrepareToSendModal: false,
      displayTagFallbackModal: false,
      displaySharablePreviewLinkModal: false,
      messageInitializationIsCompleted: false,
      totalStep: 3,
      steps: ['basic', 'template'],
      stepTitleMap: {
        basic: 'Setup the basics',
        template: 'Create your email',
        schedule: 'Summary'
      },
      rightSidePreviewIsToggledUp: false,
      windowHeight: window.innerHeight,
      stopAutoSave: false,
      isDebouncingAutoSave: false,
      beefreeShowPreviewDynamicTags: false,
      beefreeShowStructure: false,
      beefreeShowPreview: false,
      scratchEmailWithInfo: '',
      generatedHtml: null,
      disableSubForms: false,
      locallySelectedVariant: 'A',
      isEmailSending: false,
      originalTemplateType: null,
      abOptions: [{ name: 'Design A', val: 'A' }, { name: 'Design B', val: 'B' }],
    };
  },

  computed: {
    ...mapState({
      step: state => state.route.path.substring(state.route.path.lastIndexOf('/') + 1),
      messageOid: state => {
        if (state.message.scratchEmailMessage && state.message.scratchEmailMessage.oid) {
          return state.message.scratchEmailMessage.oid;
        } else {
          return parseInt(state.route.params.oid, 10);
        }
      },
      query: state => state.route.query,
      currentMessageList: state => state.messageList.currentMessageList,
      currentSelectedMessage: state => state.message.currentSelectedMessage,
      scratchEmailMessage: state => state.message.scratchEmailMessage,
      scratchEmail: state => state.message.scratchEmail,
      isSendingOutMessage: state => state.message.isSendingOutMessage,
      isUpdatingEmail: state => state.message.isUpdatingEmail,
      isSavingMessageAsDraft: state => state.message.isSavingMessageAsDraft,
      csvPreviewContacts: state => state.message.csvPreviewContacts,
      selectedCsvPreviewContactIndex: state => state.message.selectedCsvPreviewContactIndex,
      displayCsvPreviewContacts: state => state.message.displayCsvPreviewContacts,
      scratchEmailMessageUnsaved: state => state.message.scratchEmailMessageUnsaved,
      currentMessageSender: state => state.messageSender.currentMessageSender,
      selectedUnlayerTemplate: state => state.emailTemplate.selectedUnlayerTemplate,
      selectedBeefreeTemplate: state => state.emailTemplate.selectedBeefreeTemplate,
      isCreatingEmailTemplate: state => state.emailTemplate.isCreatingEmailTemplate,
      filteredRecipientListCount: state => state.messageList.filteredRecipientListCount,
      advancedMessageListTargeting: state => state.messageList.advancedMessageListTargeting,
      emailEditorSetting: state => state.auth.emailEditorSetting,
      emailVariants: state => state.message.scratchEmailMessageVariants,
      fallbackTags: state => state.message.fallbackDynamicTagsForScratchEmailMessage,
      selectedVariant: state => state.message.selectedVariant,
      promoterOid: state => state.auth.account.oid
    }),

    ...mapGetters({
      isFeatureEnabled: 'auth/isFeatureEnabled',
      hasNonExistingTagsInEmailMessage: 'message/hasNonExistingTagsInEmailMessage',
      nonExistingDynamicTagsInEmailMessageCopy: 'message/nonExistingDynamicTagsInEmailMessageCopy',
      injectInfoToScratchEmail: 'message/injectInfoToScratchEmail',
      getBeefreePreviewAndFooterHtmlForInjection: 'message/getBeefreePreviewAndFooterHtmlForInjection',
      scratchEmailMessageCampaignOid: 'message/scratchEmailMessageCampaignOid',
      scratchEmailMessageEventOid: 'message/scratchEmailMessageEventOid',
      currentSelectedMessageUiStatus: 'message/currentSelectedMessageUiStatus',
      selectedCsvPreviewContact: 'message/selectedCsvPreviewContact',
      currentMessageListHasNoEmailContacts: 'messageList/currentMessageListHasNoEmailContacts',
      currentMessageSenderNotVerified: 'messageSender/currentMessageSenderNotVerified',
      historicalCurrentPagePath: 'application/historicalCurrentPagePath'
    }),

    isAbSelectDisabled() {
      if (this.scratchEmailMessage.abEmail && !(this.emailVariants?.A.meta.presentation.template
        && this.emailVariants?.B.meta.presentation.template)) {
        return true
      }
      return false
    },

    hasImportDynamicTagsLink() {
      return this.scratchEmailMessage?.meta?.presentation?.templateType === 'beefree';
    },

    sanitizedReferringPage() {
      return this.historicalCurrentPagePath('/message-center/messages');
    },

    scratchSubject() {
      return this.scratchEmailMessage.meta?.messageBody?.subject || null;
    },

    scratchPreviewText() {
      return this.scratchEmailMessage.meta?.messageBody?.previewText || null;
    },

    scratchScheduledAt() {
      return this.scratchEmailMessage.scheduledAt || null;
    },

    scratchSenderName() {
      return this.currentMessageSender?.additionalInfo?.senderName || '';
    },

    displayCsvPreviewPaginator() {
      return this.csvPreviewContacts && this.displayCsvPreviewContacts;
    },

    templateDropdownItems() {
      const items = [];
      items.push({
        name: 'Use a template',
        key: 'select_template'
      });
      if (process.env.arEnableRichTextEmail) {
        if (this.scratchEmailMessage.meta.presentation.templateType !== 'rich-text') {
          items.push({
            name: 'Use text only',
            key: 'use_text_only'
          });
        }
      }

      if (
        !(
          this.scratchEmailMessage.meta.presentation.templateType === 'unlayer' ||
          this.scratchEmailMessage.meta.presentation.templateType === 'beefree'
        )
      ) {
        items.push({
          name: 'Use email editor',
          key: `use_${this.emailEditorSetting}_editor`
        });
      }

      items.push({
        name: 'Save as new template',
        key: 'save_template'
      });
      return items;
    },

    isBeefree() {
      return this.scratchEmailMessage?.meta?.presentation?.templateType === 'beefree';
    },

    hasNoSpaceForRightSidePreview() {
      if (this.$arMediaQuery.window.maxWidth('md')) {
        return true;
      }
      if (this.displayUnlockPrompt) {
        return this.windowHeight < 700;
      } else {
        return this.windowHeight < 600;
      }
    },

    displayRightSidePreview() {
      return this.rightSidePreviewIsToggledUp && this.hasNoSpaceForRightSidePreview && !this.fullScreenMode;
    },
    isNewEmailMessage() {
      return !this.scratchEmailMessage.oid;
    },

    isScheduledMessage() {
      return !!this.scratchEmailMessage.scheduledAt || !!this.scratchEmailMessage.meta.presentation.timeZone;
    },

    sendMessageButtonCopy() {
      if (this.isScheduledMessage) {
        if (this.currentSelectedMessageUiStatus === 'scheduled') {
          return 'Update';
        } else {
          return 'Schedule';
        }
      } else {
        return 'Send Now';
      }
    },

    isEmailFeatureEnabled() {
      return this.isFeatureEnabled(['messages', 'email']);
    },

    displayUnlockPrompt() {
      return !this.isEmailFeatureEnabled;
    },

    currentStepIndex() {
      return this.steps.findIndex(step => step === this.step);
    },

    currentStep() {
      return this.currentStepIndex + 1;
    },

    isEditingTemplate() {
      return this.currentStep === 2;
    },

    stepTitle() {
      return this.stepTitleMap[this.step];
    },

    fullScreenMode() {
      return this.isEditingTemplate;
    },

    recipientsCount() {
      if (!this.currentMessageList) {
        return 0;
      }
      if (!this.currentMessageList.statsSnapshot) {
        return 0;
      }
      if (this.scratchEmailMessageCampaignOid || this.scratchEmailMessageEventOid) {
        return this.currentMessageList.statsSnapshot.total;
      }
      return this.currentMessageList.statsSnapshot.email ? this.currentMessageList.statsSnapshot.email : 0;
    },

    autoSaveCopy() {
      if (this.isSavingMessageAsDraft) {
        return 'Saving changes...';
      } else if (this.scratchEmailMessageUnsaved) {
        return 'Changes unsaved';
      } else {
        return 'All changes saved';
      }
    },

    isAutoSaveEnabled() {
      return (
        this.currentSelectedMessageUiStatus === 'draft' ||
        this.currentSelectedMessageUiStatus === null ||
        this.currentSelectedMessageUiStatus === 'scheduled'
      );
    },

    isSaveAsADraftButtonDisabled() {
      return this.isUpdatingEmail || this.isSavingMessageAsDraft || this.isSendingOutMessage;
    },

    isNextButtonDisabled() {
      // 15 Feb 2023
      // For Beefree, we are considering whether there is a template generated already.
      // If we send with a null template, it will either error when uploading in bucket-email-template endpoint
      // Or the email will still be sent but the user will see "Failed to generate for bucket 3"
      if (this.isBeefree) {
        return this.isEditingTemplate && !(this.scratchEmail || this.scratchEmailMessage.meta.presentation.template);
      } else {
        return this.isEditingTemplate && !this.scratchEmail;
      }
    },

    sendButtonDisabled() {
      if (this.isSavingMessageAsDraft) {
        return true;
      }
      if (this.currentMessageSenderNotVerified) {
        return true;
      }
      if (this.currentMessageListHasNoEmailContacts) {
        return true;
      }
      if (
        this.advancedMessageListTargeting?.type &&
        this.advancedMessageListTargeting?.type !== 'all' &&
        !this.filteredRecipientListCount
      ) {
        return true;
      }
      return false;
    },

    previewDropdownItems() {
      const isDraftOrScheduled =
        this.currentSelectedMessage?.status === 'draft' || this.currentSelectedMessage?.status === 'scheduled';

      const isBeefreeEnabled = this.scratchEmailMessage?.meta?.presentation?.templateType === 'beefree';
      let dropdownItems = [
        {
          name: 'Send test email',
          key: 'send_test_email'
        },
        {
          name: isBeefreeEnabled && this.beefreeShowPreview ? 'Hide email preview' : 'Preview email',
          key: 'preview_email'
        },
        // Enable sharable link only if message status is draft
        {
          name: 'Shareable preview link',
          key: 'shareable_preview_link',
          disabled: !isDraftOrScheduled
        }
      ];

      // Enable preview dynamic tags
      if (isBeefreeEnabled && process.env.arEnableBeefreePreviewDynamicTags) {
        dropdownItems.push({
          name: this.beefreeShowPreviewDynamicTags ? 'Hide dynamic tags preview' : 'Preview dynamic tags',
          key: 'preview_dynamic_tags'
        });
      }
      if (this.fallbackTags && this.fallbackTags.length !== 0) {
        dropdownItems.push({
          name: 'Dynamic tags fallbacks',
          key: 'dynamic_tags_fallbacks'
        });
      }

      // When Beefree is enabled and selected, we can let it enable the structure outlines
      if (isBeefreeEnabled && !!process.env.arEnableBeefreeShowStructure) {
        dropdownItems.push({
          name: this.beefreeShowStructure ? 'Hide structure outlines' : 'Show structure outlines',
          key: 'toggle_structure_outlines'
        });
      }

      return dropdownItems;
    },

    templateType() {
      return this.scratchEmailMessage?.meta?.presentation?.templateType || 'unlayer';
    },

    abSwitchIndex() {
      return this.selectedVariant === 'A' ? 0 : 1;
    }
  },

  watch: {
    scratchEmailMessage: {
      async handler(val) {
        if (!this.messageInitializationIsCompleted) {
          return;
        }
        this.$nextTick(() => {
          if (this.emailVariants.length === 2) return
          this['message/SET_SCRATCH_EMAIL_MESSAGE_UNSAVED'](true);
          if (this.isAutoSaveEnabled) {
            if (!this.isSavingMessageAsDraft && !this.isDebouncingAutoSave) {
              this.handleAutoSaveDebounce();
              this.handleAutoSaveDebounce.flush();
            } else {
              this.handleAutoSaveDebounce();
              this.isDebouncingAutoSave = true;
            }
          }
          sessionStorage.setItem('_ar_promoter_new_email_message_', JSON.stringify(val));
        });
      },
      deep: true
    },
    currentMessageList: {
      handler(newCurrentMessageList) {
        if (this.scratchEmailMessage?.meta?.email?.promoterPropertyOid) return;
        if (
          !newCurrentMessageList ||
          !newCurrentMessageList.meta.email ||
          !newCurrentMessageList.meta.email.promoterPropertyOid
        )
          return;

        this['messageSender/FETCH_CURRENT_MESSAGE_SENDER']({
          oid: newCurrentMessageList.meta.email.promoterPropertyOid
        });
        this['message/PATCH_SCRATCH_EMAIL_MESSAGE']({
          meta: {
            email: {
              promoterPropertyOid: newCurrentMessageList.meta.email.promoterPropertyOid
            }
          }
        });
      },
      immediate: true
    },
    scratchEmail: {
      handler(val, oldVal) {
        if (val && val !== oldVal) {
          this['message/RENEW_AVAILABLE_FALLBACK_DYNAMIC_TAGS_FOR_EMAIL_MESSAGE'](val);
        }
      },
      immediate: true
    },
    currentMessageSender: {
      async handler(val, oldVal) {
        if (val && val !== oldVal) {
          // When the message sender data is modified, only generate html on the schedule page
          if (this.steps[this.currentStep - 1] === 'schedule') {
            await this.getScratchEmailWithInfo();
          } else {
            await this.updateInjectedHtml();
          }
        }
      },
      immediate: true
    }
  },

  /**
   * If you need any data to be ready before we start rending the template,
   * please do it here.
   **/
  async asyncData({ store, route }) {
    store.commit('message/RESET_CURRENT_SELECTED_MESSAGE');
    store.commit('message/RESET_SCRATCH_EMAIL_TEMPLATE');
    store.commit('message/RESET_CSV_PREVIEW_CONTACTS');
    store.commit('messageList/RESET_CURRENT_MESSAGE_LIST');
    store.commit('messageSender/RESET_CURRENT_MESSAGE_SENDER');
    const unsavedMessage = sessionStorage.getItem('_ar_promoter_new_email_message_');

    if (route.params.oid) {
      await store.dispatch('message/FETCH_MESSAGE', parseInt(route.params.oid, 10));
    }

    return {
      unsavedMessage
    };
  },

  async created() {
    window.addEventListener('resize', this.handleWindowResize);
    this.handleAutoSaveDebounce = debounce(this.handleAutoSave, 1000);

    this['segment/RESET_SCRATCH_SEGMENT']();

    this['message/SET_SCRATCH_EMAIL_MESSAGE_UNSAVED'](false);

    // Fetch dynamic tags
    await this['message/FETCH_ADDITIONAL_DYNAMIC_TAGS']();

    if (this.unsavedMessage && !JSON.parse(this.unsavedMessage).abEmail) {
      // Scratch email message with unsaved changes
      this['message/SET_SCRATCH_EMAIL_MESSAGE'](JSON.parse(this.unsavedMessage));
    } else if (this.messageOid) {
      // Scratch email message from a saved email message
      this['message/SET_SCRATCH_EMAIL_MESSAGE'](this.currentSelectedMessage);

      // if AB enabled
      if (this.scratchEmailMessage.abEmail) {
        this['message/SELECT_AB_EMAIL_MESSAGE']('A')
      }
    } else {
      // New email message
      this['message/RESET_SCRATCH_EMAIL_MESSAGE']();
      // If you also have default selected Unlayer template id

      const finalEditor = this.emailEditorSetting || 'unlayer';
      this['message/PATCH_SCRATCH_EMAIL_MESSAGE']({
        meta: {
          presentation: {
            templateType: finalEditor
          }
        }
      });

      if (this.query.libraryTemplateId) {
        if (!!this.emailEditorSetting && this.emailEditorSetting === 'beefree') {
          await this['emailTemplate/FETCH_SELECTED_BEEFREE_TEMPLATE'](this.query.libraryTemplateId);
          this['message/PATCH_SCRATCH_EMAIL_MESSAGE']({
            meta: {
              presentation: {
                template: this.selectedBeefreeTemplate?.meta?.template,
                templateType: 'beefree'
              }
            }
          });
        } else {
          await this['emailTemplate/FETCH_SELECTED_UNLAYER_TEMPLATE'](this.query.libraryTemplateId);
          this['message/PATCH_SCRATCH_EMAIL_MESSAGE']({
            meta: {
              presentation: {
                template: this.selectedUnlayerTemplate.design
              }
            }
          });
        }
      }
    }

    const savedMessageListOid = this.query && this.query.messageList ? this.query.messageList : null;
    const savedCampaignOid = this.query && this.query.campaignOid ? this.query.campaignOid : null;
    const savedEventOid = this.query && this.query.eventOid ? this.query.eventOid : null;

    if (!this.scratchEmailMessage.meta.messageListOid && savedMessageListOid && savedMessageListOid.length > 0) {
      this['message/PATCH_SCRATCH_EMAIL_MESSAGE']({
        meta: {
          messageListOid: parseInt(savedMessageListOid)
        }
      });
    } else if (savedCampaignOid) {
      // The email message is from a campaign
      this['message/PATCH_SCRATCH_EMAIL_MESSAGE']({
        meta: {
          initiator: {
            campaignOid: parseInt(savedCampaignOid)
          }
        }
      });
    } else if (savedEventOid) {
      // The email message is from an event
      this['message/PATCH_SCRATCH_EMAIL_MESSAGE']({
        meta: {
          initiator: {
            eventOid: parseInt(savedEventOid)
          }
        }
      });
    }
    if (this.scratchEmailMessage.meta.presentation.template) {
      if (this.scratchEmailMessage.meta.presentation.templateType === 'unlayer') {
        this['message/GENERATE_SCRATCH_EMAIL_TEMPLATE'](this.scratchEmailMessage.meta.presentation.template);
      } else {
        this['message/SET_SCRATCH_EMAIL_TEMPLATE'](this.scratchEmailMessage.meta.presentation.template);
      }
    }

    if (!!this.currentSelectedMessage && !!this.currentSelectedMessage.meta) {
      await this['segment/FETCH_SEGMENTS']();
      const recipientFilterClear = recipientFilterToSidebar(this.currentSelectedMessage.meta.recipientFilter);
      this['segment/SET_SCRATCH_SEGMENT_BY_MESSAGE_SEGMENT']({
        messageSegmentOid: this.messageOid,
        filter: recipientFilterClear
      });
    } else {
      this['segment/SET_SCRATCH_SEGMENT_BY_MESSAGE_SEGMENT']({
        messageSegmentOid: null,
        filter: { conditions: [], logic: [] }
      });
    }

    if (this.scratchEmailMessage.meta.messageListOid) {
      this.fetchMessageListAndRecipientCount();
    }
    if (this.scratchEmailMessageCampaignOid) {
      this['campaign/FETCH_CURRENT_CAMPAIGN'](this.scratchEmailMessageCampaignOid);
    }
    if (this.scratchEmailMessage?.meta?.email?.promoterPropertyOid) {
      this['messageSender/FETCH_CURRENT_MESSAGE_SENDER']({
        oid: this.scratchEmailMessage.meta.email.promoterPropertyOid
      });
    }
    // Download Dynamic Tags CSV and set contacts if exist
    if (this.scratchEmailMessage.meta.tagsResourceOid) {
      this['message/FETCH_CSV_PREVIEW_CONTACTS'](this.scratchEmailMessage.meta.tagsResourceOid);
    }

    this.$nextTick(this.handleEmailInitialzationComplete);
  },
  async beforeRouteLeave(to, from, next) {
    sessionStorage.removeItem('_ar_promoter_new_email_message_');
    next();
  },

  beforeDestroy() {
    this.resetAllStates();
    this['message/RESET_ADDITIONAL_DYNAMIC_TAGS']();
    this['message/RESET_SCRATCH_EMAIL_MESSAGE']();
    this['messageList/RESET_ADVANCED_MESSAGE_LIST_TARGETING']();
    this['message/RESET_SWITCH_AB']();
    window.removeEventListener('resize', this.handleWindowResize);
    this.handleAutoSaveDebounce.clear();
  },

  methods: {
    ...mapActions([
      'campaign/FETCH_CURRENT_CAMPAIGN',
      'message/FETCH_ADDITIONAL_DYNAMIC_TAGS',
      'message/FETCH_MESSAGE',
      'message/UPDATE_EMAIL_TEMPLATE_IN_SCRATCH_EMAIL_MESSAGE',
      'message/GENERATE_SCRATCH_EMAIL_TEMPLATE',
      'message/FETCH_CSV_PREVIEW_CONTACTS',
      'message/RENEW_AVAILABLE_FALLBACK_DYNAMIC_TAGS_FOR_EMAIL_MESSAGE',

      'emailTemplate/FETCH_SELECTED_UNLAYER_TEMPLATE',
      'emailTemplate/FETCH_SELECTED_BEEFREE_TEMPLATE',
      'emailTemplate/CREATE_EMAIL_TEMPLATE',
      'emailTemplate/CREATE_BEEFREE_EMAIL_TEMPLATE',
      'emailTemplate/UPDATE_BEEFREE_EMAIL_TEMPLATE',
      'emailTemplate/DOWNLOAD_BEEFREE_HTML_FROM_TEMPLATE',
      'messageList/FETCH_CURRENT_MESSAGE_LIST',
      'messageSender/FETCH_CURRENT_MESSAGE_SENDER',
      'SHOW_CONFIRM',
      'message/SAVE_MESSAGE_DRAFT',
      'message/SEND_OUT_MESSAGE',
      'messageList/FETCH_FILTERED_RECIPIENT_LIST_COUNT',
      'promoterTasks/START_POLLING_PENDING_TASKS',
      'segment/FETCH_SEGMENTS',
      'message/SWITCH_AB_EMAIL',
      'message/SELECT_AB_EMAIL_MESSAGE',
    ]),
    ...mapMutations([
      'campaign/RESET_CURRENT_CAMPAIGN',
      'messageList/RESET_CURRENT_MESSAGE_LIST',
      'messageSender/RESET_CURRENT_MESSAGE_SENDER',
      'message/RESET_ADDITIONAL_DYNAMIC_TAGS',
      'message/RESET_CURRENT_SELECTED_MESSAGE',
      'message/RESET_SCRATCH_EMAIL_MESSAGE',
      'message/SET_SCRATCH_EMAIL_MESSAGE',
      'message/SET_SCRATCH_EMAIL_TEMPLATE',
      'message/RESET_SCRATCH_EMAIL_TEMPLATE',
      'message/PATCH_SCRATCH_EMAIL_MESSAGE',
      'message/RESET_CSV_PREVIEW_CONTACTS',
      'message/SET_SELECTED_CSV_PREVIEW_CONTACTS_INDEX',
      'message/TOGGLE_DISPLAY_CSV_PREVIEW_CONTACTS',
      'message/SET_SCRATCH_EMAIL_MESSAGE_UNSAVED',
      'messageList/RESET_ADVANCED_MESSAGE_LIST_TARGETING',
      'messageList/SET_ADVANCED_MESSAGE_LIST_TARGETING',
      'segment/RESET_SCRATCH_SEGMENT',
      'segment/SET_SCRATCH_SEGMENT_BY_MESSAGE_SEGMENT',
      'message/CREATE_AB_EMAIL_MESSAGE',
      'message/SET_CURRENT_SELECTED_MESSAGE',
      'message/UPDATE_AB_EMAIL_STATE',
      'message/SELECT_AB_EMAIL_MESSAGE',
      'message/RESET_SWITCH_AB',
      'message/PATCH_AB_EMAIL_MESSAGE_VARIANT_RESOURCE_ID',
    ]),
    resetAllStates() {
      this['message/RESET_CURRENT_SELECTED_MESSAGE']();
      this['campaign/RESET_CURRENT_CAMPAIGN']();
      this['messageList/RESET_CURRENT_MESSAGE_LIST']();
      this['messageSender/RESET_CURRENT_MESSAGE_SENDER']();
      this['message/RESET_SCRATCH_EMAIL_TEMPLATE']();
      this['message/RESET_CSV_PREVIEW_CONTACTS']();
      this['segment/RESET_SCRATCH_SEGMENT']();
    },
    async fetchMessageListAndRecipientCount() {
      await this['messageList/FETCH_CURRENT_MESSAGE_LIST']({ oid: this.scratchEmailMessage.meta.messageListOid });
      let newAdvancedMessageListTargeting = null;
      if (this.currentSelectedMessage?.meta?.recipientFilter) {
        newAdvancedMessageListTargeting = recipientFilterToTargetingRules(
          this.currentSelectedMessage.meta.recipientFilter
        );
      } else {
        newAdvancedMessageListTargeting = recipientFilterToTargetingRules(
          this.scratchEmailMessage?.meta?.recipientFilter || null
        );
      }
      this['messageList/SET_ADVANCED_MESSAGE_LIST_TARGETING'](newAdvancedMessageListTargeting);
      if (!this.scratchEmailMessageCampaignOid) {
        this['messageList/FETCH_FILTERED_RECIPIENT_LIST_COUNT']({ channel: 'email' });
      }
    },
    handlePreparetoSendClick() {
      this.displayPrepareToSendModal = true;
    },
    handlePrepareToSendCancel() {
      this.displayPrepareToSendModal = false;
    },
    handlePrepareToSendClose() {
      this.displayPrepareToSendModal = false;
    },
    async handleSaveHtmlClick() {
      this.setBeefreeShowPreview(false);
      this.goToPreviousStep();
      await this.getScratchEmailWithInfo();
      if (this.$refs?.designBlock?.handleDesignChange) {
        this.$refs.designBlock.handleDesignChange();
      }
    },
    handleBackLinkClick() {
      let path = '';
      if (!!this.scratchEmailMessageCampaignOid && this.isNewMessage) {
        path = `/campaigns/${this.scratchEmailMessageCampaignOid}/view/dashboard`;
      } else if (!!this.scratchEmailMessageEventOid && this.isNewMessage) {
        path = `/events/${this.scratchEmailMessageEventOid}/view/sales`;
      } else {
        path = this.sanitizedReferringPage;
      }
      this.$router.push({
        path: path
      });
    },
    handleTestEmailClick() {
      this.displaySendTestEmailModal = true;
    },
    getModifyEmailPageElem() {
      return this.$refs['modify-email-page'];
    },
    goToPreviousStep() {
      this.$router.push({
        path: `${this.steps[this.currentStepIndex - 1]}`,
        query: this.query
      });
    },
    goToNextStep() {
      this.$router.push({
        path: `${this.steps[this.currentStepIndex + 1]}`,
        query: this.query
      });
    },
    handleDesignEmailClick() {
      this.goToNextStep();
    },
    validateInputs() {
      return this.getModifyEmailPageElem()
        .$validator.validateAll()
        .then(valid => {
          if (valid) {
            return true;
          } else {
            if (this.isScheduledMessage) {
              this.$arNotification.push({
                type: 'error',
                message: 'Could not schedule the email, please review error messages'
              });
            } else {
              this.$arNotification.push({
                type: 'error',
                message: 'Could not send the email, please review error messages'
              });
            }
            return false;
          }
        });
    },
    async validateDynamicTags() {
      if (this.hasNonExistingTagsInEmailMessage) {
        console.error(messageOid, ' non-existing dynamic tags exist');
        await this.SHOW_CONFIRM({
          messageHtml: this.nonExistingDynamicTagsInEmailMessageCopy,
          confirmButtonText: 'Done',
          hideCancelButton: true
        });
        return false;
      }
      return true;
    },
    hideOverviewPageFields() {
      if (this.getModifyEmailPageElem().hideFields) {
        this.getModifyEmailPageElem().hideFields();
      }
    },
    async sendMessage() {
      this.isEmailSending = true;
      // Force completing auto save and set stopAutoSave to true
      this.handleAutoSaveDebounce.flush();
      this.stopAutoSave = true;

      this.hideOverviewPageFields();
      let actionWord = this.isScheduledMessage ? 'schedule' : 'send';
      let count = this.recipientsCount;
      if (
        this.currentSelectedMessage?.meta?.recipientFilter?.conditions?.length > 0 ||
        this.scratchEmailMessage?.meta?.recipientFilter?.conditions?.length > 0
      ) {
        count = this.filteredRecipientListCount;
      }

      await this.getScratchEmailWithInfo();

      // 7 Feb 2023 ADR
      // We save the template into the bucket, for both Unlayer and Beefree
      const emailTemplateUpdated = await this.uploadTemplate();
      if (!emailTemplateUpdated) {
        console.error(`Message OID ${this.messageOid} failed to update template during sendMessage()`);
        this.stopAutoSave = false;
        const retryResponse = await this.SHOW_CONFIRM({
          title: `Message failed to ${actionWord}`,
          confirmButtonText: `Try again`,
          messageHtml: `It looks like something went wrong, please try again. If this issue continues, contact customer support via support@audiencerepublic.com`
        });

        if (!retryResponse) {
          this.isEmailSending = false;
          return;
        }

        this.sendMessage();
        return;
      }

      const succeed = await this['message/SEND_OUT_MESSAGE']({
        ...this.scratchEmailMessage,
        provider: 'email'
      });

      if (succeed) {
        this['promoterTasks/START_POLLING_PENDING_TASKS']({});
        // It's saved, so it's backe to not updated
        this['message/SET_SCRATCH_EMAIL_MESSAGE_UNSAVED'](false);
        this.$router.push({ path: '/message-center/messages' });
        this.isEmailSending = false;
      } else {
        this.stopAutoSave = false;
      }
      this.stopAutoSave = false;
      this.isEmailSending = false;
    },
    async uploadTemplate() {
      if (this.scratchEmailMessage.abEmail === true) {
        let success = true;

        // we need the uploaded template for both design and subject emails, as the preview text
        // is actually part of the email html
        success &= await this.uploadVariantTemplate('A', 1);
        success &= await this.uploadVariantTemplate('B', 2);

        await this.saveMessageDraft();
        return success;
      } else {
        const resource = await this['message/UPDATE_EMAIL_TEMPLATE_IN_SCRATCH_EMAIL_MESSAGE']({
          emailHtml: this.scratchEmailWithInfo,
          previewHtml: this.generatedHtml?.previewHtml || null,
          footerHtml: this.generatedHtml?.footerHtml || null,
          version: 1
        });

        await this.saveMessageDraft();
        return !!resource;
      }
    },
    async uploadVariantTemplate(variant, version) {
      await this['message/SELECT_AB_EMAIL_MESSAGE'](variant);

      // send the template to be stored in a bucket
      await this.getScratchEmailWithInfo();
      const resource = await this['message/UPDATE_EMAIL_TEMPLATE_IN_SCRATCH_EMAIL_MESSAGE']({
        emailHtml: this.scratchEmailWithInfo,
        previewHtml: this.generatedHtml?.previewHtml || null,
        footerHtml: this.generatedHtml?.footerHtml || null,
        version: version
      });
      // make sure templateResourceOid is updated
      if (!!resource) {
        await this['message/UPDATE_AB_EMAIL_STATE'](variant);
      }
      return !!resource;
    },
    async fetchCurrentMessage() {
      if (!this.messageOid) {
        return;
      }
      return await this['message/FETCH_MESSAGE'](this.messageOid);
    },
    async saveMessageDraft() {
      this['message/SET_SCRATCH_EMAIL_MESSAGE_UNSAVED'](false);
      const succeed = await this['message/SAVE_MESSAGE_DRAFT']({
        ...this.scratchEmailMessage,
        provider: 'email',
        meta: {
          ...this.scratchEmailMessage.meta,
          presentation: {
            ...this.scratchEmailMessage.meta.presentation,
            previewHtml: this.generatedHtml?.previewHtml,
            footerHtml: this.generatedHtml?.footerHtml
          }
        }
      });
      if (succeed && succeed.data != '') {
        this.stopAutoSave = true;
        succeed.data.meta = this.scratchEmailMessage.meta;
        await this['message/PATCH_SCRATCH_EMAIL_MESSAGE'](succeed.data);
        await this['message/SET_CURRENT_SELECTED_MESSAGE'](this.scratchEmailMessage);
        this.stopAutoSave = false;
      }
      if (!succeed) {
        this['message/SET_SCRATCH_EMAIL_MESSAGE_UNSAVED'](true);
      }
      return !!succeed;
    },
    // 7 Feb 2023 ADR
    // TO DO: This code is reserved for when we are going to implement versioning
    // This code is not yet final but will be here in preparation for versioning
    async saveBeefreeTemplateToBucket(type, taskOid) {
      if (!taskOid || !type) {
        return;
      }
      // 2023 Feb 3 ADR
      // We need to save Beefree Templates into the Beefree Bucket during draft saving
      // But we need to do it once taskOid is generated
      const VERSION = 1;

      if (this.scratchEmailMessage.meta.presentation.templateType === 'beefree') {
        // If the draft is from a new email, create a new template with a taskOid
        if (type === 'create') {
          const createBeeEmailTemplate = await this['emailTemplate/CREATE_BEEFREE_EMAIL_TEMPLATE']({
            meta: {
              template: this.scratchEmailMessage.meta.presentation.template,
              templateType: this.scratchEmailMessage.meta.presentation.templateType,
              version: VERSION
            },
            taskOid
          });
        } else if (type === 'update') {
          // If the draft is already existing, update the template
          const updateBeefreeEmailTemplate = await this['emailTemplate/UPDATE_BEEFREE_EMAIL_TEMPLATE']({
            scratchEmailTemplate: {
              oid: this.emailTemplateToRename.oid,
              meta: {
                template: this.scratchEmailMessage.meta.presentation.template,
                templateType: this.scratchEmailMessage.meta.presentation.templateType,
                version: VERSION
              },
              taskOid
            },
            displaySuccessMessage: false
          });
        }
      }
    },
    async saveCurrentEmailAsTemplate(name, description) {
      // Save template into the bucket
      if (this.scratchEmailMessage.meta.presentation.templateType === 'beefree') {
        const succeed = await this['emailTemplate/CREATE_BEEFREE_EMAIL_TEMPLATE']({
          meta: {
            name,
            description,
            template: this.scratchEmailMessage.meta.presentation.template,
            templateType: this.scratchEmailMessage.meta.presentation.templateType
          },
          taskOid: null
        });
        return succeed;
      } else {
        const succeed = await this['emailTemplate/CREATE_EMAIL_TEMPLATE']({
          meta: {
            name,
            description,
            template: this.scratchEmailMessage.meta.presentation.template,
            templateType: this.scratchEmailMessage.meta.presentation.templateType
          }
        });
        return succeed;
      }
    },
    replaceEmailTemplate(template, templateType) {
      this['message/PATCH_SCRATCH_EMAIL_MESSAGE']({
        meta: {
          presentation: {
            template,
            templateType
          }
        }
      });

      if (this.scratchEmailTemplate?.meta?.templateType === 'beefree') {
        this.saveTemplate(false);
      }
      if (this.currentStep == 1) {
        this.goToNextStep();
      }
      if (this.getModifyEmailPageElem().setEmailTemplate) {
        this.getModifyEmailPageElem().setEmailTemplate();
      }
    },

    async handleAutoSave() {
      // Remember to set this flag to false
      this.isDebouncingAutoSave = false;
      if (!this.isAutoSaveEnabled || this.stopAutoSave) {
        return;
      }
      const succeed = await this.saveMessageDraft();
      if (!succeed) {
        return;
      }
      // Refresh the current message after it's updated
      this.fetchCurrentMessage();
    },
    async handleSaveAndRedirectToSettings() {
      this.hideOverviewPageFields();
      const succeed = await this.saveMessageDraft();
      if (succeed) {
        this.$router.push({ path: `/message-center/lists/${this.currentMessageList.oid}/settings` });
      }
    },
    async handleSaveAsDraftClick() {
      this.hideOverviewPageFields();
      // When save as draft is clicked, we need to update the task with the preview and footer html injection
      await this.getScratchEmailWithInfo();

      const succeed = await this.saveMessageDraft();
      if (succeed) {
        this.$router.push({ path: '/message-center/messages' });
      }
    },
    async handleSelectEmailTemplateSelect(template, templateType) {
      this.displaySelectEmailTemplate = false;

      this.replaceEmailTemplate(template, templateType);
    },
    async handleSaveCurrentEmailTemplateConfirm({ name, description }) {
      const succeed = await this.saveCurrentEmailAsTemplate(name, description);
      if (succeed) {
        this.displaySaveCurrentEmailTemplateModal = false;
        this.saveCurrentEmailTemplateSuccessCallBack();
      }
    },
    handleSaveCurrentEmailTemplateCancel() {
      this.displaySaveCurrentEmailTemplateModal = false;
    },
    async handleCreateEmailTemplateConfirm({ name, description }) {
      const succeed = await this.saveCurrentEmailAsTemplate(name, description);
      if (succeed) {
        this.displayCreateEmailTemplateModal = false;
      }
    },
    handleCreateEmailTemplateCancel() {
      this.displayCreateEmailTemplateModal = false;
    },
    handleSelectEmailTemplateCancel() {
      this.displaySelectEmailTemplate = false;
    },
    async handleSelectTemplateSelect() {
      this.displaySelectEmailTemplate = true;
    },
    handleTemplateItemSelect(templateItem) {
      if (templateItem.key === 'select_template') {
        this.handleSelectTemplateSelect();
      } else if (templateItem.key === 'use_text_only') {
        this['message/PATCH_SCRATCH_EMAIL_MESSAGE']({
          meta: {
            presentation: {
              template: null,
              templateType: 'rich-text'
            }
          }
        });
        this.getModifyEmailPageElem().setEmailTemplate();
      } else if (templateItem.key === 'use_unlayer_editor') {
        this['message/PATCH_SCRATCH_EMAIL_MESSAGE']({
          meta: {
            presentation: {
              template: null,
              templateType: 'unlayer'
            }
          }
        });
        this.getModifyEmailPageElem().setEmailTemplate();
      } else if (templateItem.key === 'use_beefree_editor') {
        this['message/PATCH_SCRATCH_EMAIL_MESSAGE']({
          meta: {
            presentation: {
              template: null,
              templateType: 'beefree'
            }
          }
        });
        this.getModifyEmailPageElem().setEmailTemplate();
      } else if (templateItem.key == 'save_template') {
        this.displayCreateEmailTemplateModal = true;
      }
    },
    handleSendTestEmailCancel() {
      this.displaySendTestEmailModal = false;
    },
    handleAbEmailModalCancel() {
      this.displayAbEmailModal = false;
      this['message/PATCH_SCRATCH_EMAIL_MESSAGE']({
        abEmail: false
      });
      this.$refs['designBlock'].handleCancel();
    },
    handleAbEmailChange(enabled) {
      if (enabled) {
        this.displayAbEmailModal = true;
      } else {
        const result = {
          abEmail: false,
          meta: {}
        }

        if (this.scratchEmailMessage.abEmailType === 'design' && this.emailVariants.A?.meta?.messageBody?.presentation) {
          result.meta = {
            presentation: clone(this.emailVariants.A.meta.messageBody.presentation)
          }
        } else {
          result.meta = {
            messageBody: {
              previewText: this.emailVariants.A?.meta?.messageBody?.previewText || null,
              subject: this.emailVariants.A?.meta?.messageBody?.subject || null,
            }
          }
        }

        // need to ensure this is set on scratch message so it is saved in draft
        result.meta.messageTreatment = null;
        this['message/PATCH_SCRATCH_EMAIL_MESSAGE'](result);
      }
    },
    handleDesignTemplate() {
      this.displaySelectEmailTemplate = true;
    },
    async handlePreviewItemSelect(previewItem) {
      if (previewItem.key === 'send_test_email') {
        this.displaySendTestEmailModal = true;
      } else if (previewItem.key === 'preview_email') {
        if (this.scratchEmailMessage?.meta?.presentation?.templateType === 'beefree') {
          this.getModifyEmailPageElem().preview();
        } else {
          this.displayEmailPreviewModal = true;
          this.hasModeTabsOnEmailPreviewModal = true;
        }
      } else if (previewItem.key === 'shareable_preview_link') {
        this.displaySharablePreviewLinkModal = true;
      } else if (previewItem.key === 'preview_dynamic_tags') {
        this.getModifyEmailPageElem().previewDynamicTags();
      } else if (previewItem.key === 'toggle_structure_outlines') {
        this.getModifyEmailPageElem().toggleStructureOutlines();
      } else if (previewItem.key === 'dynamic_tags_fallbacks') {
        this.displayTagFallbackModal = true;
      }
    },
    handleViewDesktopPreviewClick() {
      this.displayEmailPreviewModal = true;
      this.hasModeTabsOnEmailPreviewModal = false;
    },
    handleEmailPreviewClose() {
      this.displayEmailPreviewModal = false;
    },
    handleSharablePreviewLinkModalClose() {
      this.displaySharablePreviewLinkModal = false;
    },
    async handleNextStepClick() {
      let valid = null;
      valid = await this.validateInputs();
      if (!valid) {
        return;
      }
      if (this.isEditingTemplate) {
        valid = await this.validateDynamicTags();
        if (!valid) {
          return;
        }
      }
      // Generate the Beefree HTML when moving next to the schedule step
      if (this.steps[this.currentStep - 1] === 'template') {
        this.getScratchEmailWithInfo();
      }
      this.goToNextStep();
    },
    async handleEmailSendClick() {
      let valid = null;
      valid = await this.validateInputs();
      if (!valid) {
        return;
      }
      valid = await this.validateDynamicTags();
      if (!valid) {
        return;
      }
      this.sendMessage();
    },
    handleImportDynamicTagsLinkClick() {
      this.getModifyEmailPageElem().importDynamicTags();
    },

    async handleGoToPlansClick() {
      this.hideOverviewPageFields();
      const succeed = await this.saveMessageDraft();
      if (succeed) {
        const settingsPath = defaultSettingsPath(this.promoterOid)
        this.$router.push({
          path: settingsPath
        });
      }
    },

    handlePreviewClick() {
      this.displayEmailPreviewModal = true;
    },
    handleWindowResize() {
      this.windowHeight = window.innerHeight;
    },

    handleEmailInitialzationComplete() {
      this.messageInitializationIsCompleted = true;
      const modifyEmailPageElem = this.getModifyEmailPageElem();
      if (!modifyEmailPageElem) {
        return;
      }
      if (modifyEmailPageElem.setEmailTemplate) {
        modifyEmailPageElem.setEmailTemplate();
      }
    },

    handleCsvPreviewContactsIndexChange(idx) {
      this['message/SET_SELECTED_CSV_PREVIEW_CONTACTS_INDEX'](idx);
    },

    handleDisplayCsvPreviewContactsToggle() {
      this['message/TOGGLE_DISPLAY_CSV_PREVIEW_CONTACTS']();
    },
    enableAbEmail(type) {
      this['message/PATCH_SCRATCH_EMAIL_MESSAGE']({
        abEmail: true,
        abEmailType: type,
        meta: {
          disableLinkTracking: false,
          messageTreatment: `email-${type}` // need to ensure this is set on scratch message so it is saved in draft
        }
      });
      if (type === 'subject') {
        this.getModifyEmailPageElem().$refs['subject-block'].isOpen = true;
        this.getModifyEmailPageElem().$refs['subject-block'].disableSubForms = true;
        this.handleDisableFields();
      }
      this.displayAbEmailModal = false;
    },
    handleDisableFields() {
      this.disableSubForms = !this.disableSubForms;
    },

    setBeefreeShowPreview(showPreview) {
      this.beefreeShowPreview = showPreview;
    },

    setBeefreeShowPreviewDynamicTags(showPreviewDynamicTags) {
      this.beefreeShowPreviewDynamicTags = showPreviewDynamicTags;
    },

    setBeefreeShowStructure(showStructure) {
      this.beefreeShowStructure = showStructure;
    },

    async updateInjectedHtml() {
      this.generatedHtml = await this.getBeefreePreviewAndFooterHtmlForInjection(this.currentMessageSender);
    },

    async getScratchEmailWithInfo() {
      if (this.scratchEmailMessage?.meta?.presentation?.templateType === 'beefree') {
        await this.updateInjectedHtml();
        const html = await this['emailTemplate/DOWNLOAD_BEEFREE_HTML_FROM_TEMPLATE']({
          design: this.scratchEmailMessage?.meta?.presentation?.template,
          previewHtml: this.generatedHtml?.previewHtml || null,
          footerHtml: this.generatedHtml?.footerHtml || null
        });
        this['message/SET_SCRATCH_EMAIL_TEMPLATE'](html);

        this.scratchEmailWithInfo = html;
      } else {
        // Unlayer
        this.scratchEmailWithInfo = this.injectInfoToScratchEmail(this.currentMessageSender);
      }
    },
    handleVariantChange(variant) {
      this.locallySelectedVariant = variant;
    },
    handleEditSchedule() {
      this.displayPrepareToSendModal = true;
    },
    handleCancelSchedule() {

    },
    handleTagFallBackClose() {
      this.displayTagFallbackModal = false;
    },
    handleUnlayerDesignLoad(design) {
      if (design) {
        this.scratchEmailWithInfo = design;
      }
    },
    handleAbSelectClick(data) {
      this['message/SWITCH_AB_EMAIL']({ type: data.val, data: this.scratchEmailMessage });
    },
    async handleClickError(error) {
      if (error.type === 'subject') {
        this.$refs['modify-email-page'].handleOpen()
        this.$refs['modify-email-page'].$refs['subject-block'].handleEmptyError()
      } else if (error.type === 'dynamicTagsFallback') {
        await this.$refs['designBlock'].handleEmptyDynamicTagsFallback()
        this.displayTagFallbackModal = true
      } else if (error.type === 'from') {
        this.$refs['modify-email-page'].handleOpen()
        this.$refs['modify-email-page'].$refs['from-block'].handleEmptyError()
      } else if (error.type === 'to') {
        this.$refs['modify-email-page'].handleOpen()
        this.$refs['modify-email-page'].$refs['ToBlock'].handleEmptyError()
      } else if (error.type === 'design') {
        if (error.variant) {
          this.$refs['designBlock'].handleVariantChange(error.variant);
        }
        this.$refs['designBlock'].handleEmptyError()
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.email-edit-page {
  min-height: 100vh;
  width: 100vw;
  display: flex;
  flex-flow: column;
  background-color: white;

  &--template {
    height: 100vh;
  }

  .header-section {
    position: relative;
    z-index: $zIndexRegular;
    flex-shrink: 0;
    min-width: 800px;
    .top-create-content {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .top-create-left-content {
        padding: 0 0 4px;
        display: flex;
        align-items: center;
      }
      .top-create-right-content {
        display: flex;
        align-items: center;
        padding-right: 29px;
      }
    }
  }
  .body-section.small-width {
    max-width: 1440px;
    margin: 0 auto;
  }
  .body-section {
    flex: 1;
    min-width: 800px;
    display: flex;
    flex-flow: row;

    @media screen and (max-width: 1350px) {
      flex-direction: column;
      ::v-deep {
        .edit-message-section .edit-message-form-section {
          padding: 0 !important;
        }
      }
    }

    .edit-message-section {
      flex: 1;
      position: relative;
      z-index: $zIndexRegular;
      display: flex;
      flex-direction: column;
      height: 100%;
      flex-grow: 1;
      max-width: 960px;
      background: #fff;
      &.full-size {
        min-width: unset;
        max-width: 100%;
      }

      .edit-message-form-section {
        flex: 1;
        padding: 0 24px 0 96px;
        -webkit-overflow-scrolling: touch;
        .page-content {
          max-width: 666px;
          margin: 50px auto;

          &.small-window-size {
            margin: 30px auto;
            &.full-size {
              margin: 0;
            }
          }

          &.full-size {
            height: 100%;
            max-width: 100%;
            margin: 0;
          }
        }
        &.small-window-size {
          padding: 0 12px;
        }
        &.no-padding {
          padding: 0;
        }
      }

      .edit-message-navigation-section {
        flex-shrink: 0;
        position: relative;
        width: 100%;
        z-index: $zIndexHighest;
        height: 110px;
        padding: 0 80px;
        border-top: 1px solid $skyBlueGrey500;
        background-color: white;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        &.small-window-size {
          height: 75px;
          padding: 0 12px;
        }

        .navigation-right-section {
          display: flex;
          align-items: center;
          .save-link {
            margin-right: 30px;
          }
        }
      }
    }
    .preview-section {
      flex-shrink: 0;
      z-index: $zIndexLow;
      background-color: white;
      flex-grow: 1;

      @media screen and (max-width: 1350px) {
        ::v-deep {
          .email-body-section {
            padding: 0;
          }
        }
      }

      &.go-on-top-of-edit-section {
        padding: 12px;
        display: flex;
        justify-content: center;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: calc(100% - 110px); // minus edit-message-edit-message-navigation-section-section height
        background-color: #fff;

        &.small-window-size {
          height: calc(100% - 75px);
        }
      }

      .preview-wrapper {
        display: flex;
        flex-flow: column;
        align-items: center;
        max-height: 780px;
        padding-bottom: 20px;
        .preview {
          flex: 1;
        }
        .preview-bottom-link-section {
          margin-top: 22px;
        }
      }
    }
  }
}
.background {
  color: white;
  height: 100%;
  width: 100%;
}
.disabled {
  opacity: 0.4;
  pointer-events: none;
}
</style>
