<template>
  <portal
    to="modal"
    :disabled="!opened"
    :style="{ position: 'absolute' }"
  >
    <ar-modal
      :is-open="opened"
      :header="header"
      hide-footer
      width="1200px"
      height="100%"
      :mobile="$arMediaQuery.window.maxWidth('xs')"
      @close="handleModalClose"
      class="message-preview-modal"
    >
      <div slot="body" class="body">
        <div
          v-if="showShareLink && csvPreviewContacts && csvPreviewContacts.length !== 0 && resourceUrl"
          class="message-preview-modal__header-first">
          <am2-copy-input
            class="u-margin-right-2"
            :value="resourceUrl"
            :input-styles="{height: '40px'}"
            :style="{height: '40px'}"
          />
        </div>
        <div class="message-preview-modal__header">
          <div
            v-if="csvPreviewContacts && csvPreviewContacts.length !== 0"
            class="message-preview-modal__dynamic-tags"
          >
            <ar-text
              text="Dynamic tag preview"
              size="xs"
              weight="bold"
              :style="{
                color: $arStyle.color.blueGrey800,
                marginRight: '8px',
              }"
            />
            <am2-switch v-model="isDynamicTag" @input="handleChangeIsDynamicTag" />
          </div>
          <am2-copy-input
            v-else-if="showShareLink && resourceUrl"
            class="u-margin-right-2"
            :value="resourceUrl"
            :input-styles="{height: '40px'}"
            :style="{height: '40px'}"
          />

          <div class="message-preview-modal__header-right">
            <ar-simple-select
              v-if="isAbDesignOn"
              :style="{ width: '150px', height: '40px', marginLeft: 'auto', marginRight: '20px' }"
              enable-filter
              :items="abOptions"
              :default-select-index="abSwitchIndex"
              :disabled="isAbSelectDisabled"
              v-tooltip.top="{
                content: isAbSelectDisabled ? 'Design your email to preview.' : null
              }"
              @select="handleVariantSelectClick"
            />

            <am2-elegant-tabs
              v-if="hasModeTabs"
              class="message-preview-modal__tabs"
              layout="wide"
              :items="modeTabs"
              :tab-key="mode"
              @select="handleModeSelect"
            />
          </div>
        </div>

        <ar-divider />

        <div
          v-if="isDynamicTag"
          class="message-preview-modal__preview-counter"
        >
          <ar-icon-button
            :icon-props="{
              name: 'arrow',
              rotate: 90,
            }"
            @click="handleClickPrevDynamicTag"
          />
          <ar-text
            size="xs"
            :text="dynamicTagsPreviewText"
            :style="{
              margin: '0 9px',
            }"
          />
          <ar-icon-button
            :icon-props="{
              name: 'arrow',
              rotate: 270,
            }"
            @click="handleClickNextDynamicTag"
          />
        </div>

        <div
          v-if="mode === 'mobile'"
          class="mobile-preview-section"
        >
          <am2-mobile-container
            :has-footer="false"
            size="fullHeight"
          >
            <div v-if="isLoading" class="message-preview-modal__loading-spinner">
              <am2-loading-spinner size="48px"/>
            </div>
            <am2-email-preview
              v-else
              opened
              :email-template="preparedEmailTemplate"
              :sender-name="senderName"
              :subject="preparedSubject"
              :preview-text="previewText"
              :scheduled-at="scheduledAt"
            />
          </am2-mobile-container>
        </div>
        <div
          v-if="mode === 'desktop'"
          class="desktop-preview-section"
        >
          <div v-if="isLoading" class="message-preview-modal__loading-spinner">
            <am2-loading-spinner size="48px"/>
          </div>
          <am2-email-preview
            v-else
            :mobile="false"
            opened
            :email-template="preparedEmailTemplate"
            :sender-name="senderName"
            :subject="preparedSubject"
            :preview-text="previewText"
            :scheduled-at="scheduledAt"
          />
        </div>
      </div>
    </ar-modal>
  </portal>
</template>

<script>
import {mapActions, mapGetters, mapMutations, mapState} from 'vuex'

export default {
  name: 'EmailPreviewModal',
  props: {
    opened: {
      type: Boolean,
      default: false,
    },
    emailHtml: {
      type: String,
      default: null,
    },
    hasModeTabs: {
      type: Boolean,
      default: true,
    },
    senderName: {
      type: String,
      default: null,
    },
    subject: {
      type: String,
      default: null,
    },
    previewText: {
      type: String,
      default: null,
    },
    scheduledAt: {
      type: String,
      default: null,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    message: {
      type: Object,
      default: function () {
        return {};
      },
    },
    showShareLink: {
      type: Boolean,
      default: false,
    },
    isAbSelectDisabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      mode: 'desktop',
      modeTabs: [
        {
          name: 'Desktop',
          key: 'desktop',
        },
        {
          name: 'Mobile',
          key: 'mobile',
        },
      ],
      isDynamicTag: false,
      dynamicTagsIndex: 0,
    };
  },
  computed: {
    ...mapGetters({
      availableDynamicTagsInEmailMessage: 'message/availableDynamicTagsInEmailMessage',
    }),
    ...mapState({
      csvPreviewContacts: state => state.message.csvPreviewContacts,
      selectedVariant: state => state.message.selectedVariant,
      hash: state => state.messagePreview.hash,
    }),

    abOptions() {
      const abType = this.message.abEmailType === 'design' ? 'Design' : 'Subject'
      return [{ name: `${abType} A`, val: 'A' }, { name: `${abType}  B`, val: 'B' }]
    },

    header() {
      if (this.mode === 'mobile') {
        return 'Mobile preview';
      } else {
        return 'Desktop preview';
      }
    },

    dynamicTagsPreviewText() {
      if (!this.csvPreviewContacts || this.csvPreviewContacts.length === 0) return 'Preview 0 of 0'
      return `Preview ${this.dynamicTagsIndex + 1} of ${this.csvPreviewContacts.length}`;
    },

    preparedEmailTemplate() {
      if (this.isDynamicTag && this.csvPreviewContacts && this.csvPreviewContacts.length) {
        return this.replaceTextByDynamicTags(this.emailHtml)
      }
      return this.emailHtml
    },

    preparedSubject() {
      if (this.isDynamicTag && this.csvPreviewContacts && this.csvPreviewContacts.length) {
        return this.replaceTextByDynamicTags(this.subject)
      }
      return this.subject
    },

    resourceUrl() {
      if (this.hash) {
        return `${window.location.origin}/message-preview/${encodeURIComponent(this.hash)}`
      } else {
        return null
      }
    },

    abSwitchIndex() {
      return this.selectedVariant === 'A' ? 0 : 1;
    },

    isAbDesignOn() {
      return this.message?.abEmail
    },
  },

  watch: {
    opened(newVal, oldVal) {
      if (newVal) {
        if (newVal === oldVal) return

        this.mode = 'desktop';

        if (!this.showShareLink) return
        if (newVal) {
          this['messagePreview/GENERATE_RESOURCE_URL'](this.message.oid)
        } else {
          this['messagePreview/RESET_STATE']()
        }
      }
    },
  },

  methods: {
    ...mapActions([
      'messagePreview/GENERATE_RESOURCE_URL',
    ]),
    ...mapMutations([
      'message/SELECT_AB_EMAIL_MESSAGE',
      'messagePreview/RESET_STATE',
    ]),
    handleModalClose() {
      this.$emit('close');
    },
    handleModeSelect(tabItem) {
      this.mode = tabItem.key;
    },
    handleChangeIsDynamicTag(value) {
      this.isDynamicTag = value
    },

    handleClickNextDynamicTag() {
      if (this.csvPreviewContacts && this.dynamicTagsIndex < this.csvPreviewContacts.length - 1) {
        this.dynamicTagsIndex += 1
      }
    },

    handleClickPrevDynamicTag() {
      if (this.dynamicTagsIndex > 0) {
        this.dynamicTagsIndex -= 1
      }
    },

    replaceTextByDynamicTags(text) {
      if (!text) {
        return;
      }

      let result = text
      this.availableDynamicTagsInEmailMessage.forEach(tag => {
        const replaceValue = this.csvPreviewContacts[this.dynamicTagsIndex][tag]
        result = result.replace(new RegExp(`{{${tag}}}`, 'g'), replaceValue)
      })
      return result
    },

    handleVariantSelectClick(item) {
      this['message/SELECT_AB_EMAIL_MESSAGE'](item.val)
      this.$emit('variant-select', item.val)
    },
  },
};
</script>

<style lang="scss" scoped>
.message-preview-modal {
  .body {
    position: relative;
    height: 100%;

    .mobile-preview-section {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 20px 0;
      height: 100%;
    }

    .desktop-preview-section {
      padding: 12px 0 0;
      height: 100%;
    }
  }

  &__tabs {
    max-width: 238px;
    width: 100%;
    margin-left: auto;
  }

  &__header-right {
    margin-left: auto;
    display: flex;
    align-items: center;
    min-width: 400px;
  }

  &__header {
    display: flex;
    align-items: center;
    height: 72px;
    padding: 0 32px;
  }

  &__header-first {
    display: flex;
    align-items: center;
    padding: 16px 32px;
    border-bottom: 1px solid $skyBlueGrey500;
  }

  &__dynamic-tags {
    display: flex;
    align-items: center;
  }

  &__preview-counter {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 12px;
    user-select: none;
  }

  &__loading-spinner {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 30px 0;
  }
}
</style>
