var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{attrs:{"data-test-id":"companies-page"}},[_c('div',{directives:[{name:"infinite-scroll",rawName:"v-infinite-scroll",value:(_vm.fetchMoreData),expression:"fetchMoreData"}],staticClass:"u-margin-top-6",style:({
      width: '100%',
    }),attrs:{"infinite-scroll-disabled":"disableFetchMoreMessages","infinite-scroll-distance":10}},[_c('am2-top',{class:[
        'top-wrapper',
        'u-margin-bottom-8',
        _vm.$arMediaQuery.pageContent.maxWidth('sm') && 'sm-max' ],attrs:{"search":{
        action: _vm.serverSideSearch,
        placeholder: 'Search promoters by email, name or oid',
        value: _vm.searchString,
        style: {
          width: '350px',
        }
      },"title":"Companies"},scopedSlots:_vm._u([{key:"button",fn:function(){return [_c('ar-simple-button',{directives:[{name:"tooltip",rawName:"v-tooltip.bottom",value:({
            content: 'This should only be used for testing and does not link to chargebee',
          }),expression:"{\n            content: 'This should only be used for testing and does not link to chargebee',\n          }",modifiers:{"bottom":true}}],style:({
            width: '250px'
          }),attrs:{"text":"New company (internal only)","icon-name":"circle-plus","icon-side":"left","icon-distance":"12px","data-test-id":"create-new-promoter-account"},on:{"click":_vm.handleCreatePromoterClick}})]},proxy:true}])}),_vm._v(" "),(_vm.isFetchingPromoterAccounts && _vm.promoterAccounts.length === 0)?_c('div',{staticClass:"loading-container"},[_c('am2-loading-spinner',{attrs:{"size":"48px"}})],1):_c('am2-table',{staticClass:"message-table",attrs:{"heads":_vm.tableHeadings,"rows":_vm.promoterAccounts,"has-sticky-header":"","enable-row-click":""},on:{"rowClick":_vm.handlePromoterRowClick},scopedSlots:_vm._u([{key:"oid",fn:function(ref){
          var data = ref.data;
return _c('div',{staticClass:"number-cell"},[_c('div',{staticClass:"body-container"},[_c('ar-text',{attrs:{"size":"xs","text":data.oid}})],1)])}},{key:"email",fn:function(ref){
          var data = ref.data;
return _c('div',{staticClass:"message-cell"},[_c('div',{staticClass:"body-container"},[_c('ar-text',{attrs:{"size":"xs","text":data.emailAddress}})],1)])}},{key:"name",fn:function(ref){
          var data = ref.data;
return _c('div',{staticClass:"message-cell"},[_c('div',{staticClass:"body-container"},[_c('ar-text',{attrs:{"size":"xs","text":data.name}})],1)])}},{key:"country",fn:function(ref){
          var data = ref.data;
return _c('div',{staticClass:"message-cell"},[_c('div',{staticClass:"body-container"},[_c('ar-text',{attrs:{"size":"xs","text":data.country}})],1)])}},{key:"companyCategory",fn:function(ref){
          var data = ref.data;
return _c('div',{staticClass:"message-cell"},[_c('div',{staticClass:"body-container"},[_c('ar-text',{attrs:{"size":"xs","text":data.companyCategory}})],1)])}},{key:"sysCtime",fn:function(ref){
          var data = ref.data;
return _c('div',{staticClass:"message-cell"},[_c('div',{staticClass:"body-container"},[_c('ar-text',{attrs:{"size":"xs","text":_vm.formattedDate(data.sysCtime)}})],1)])}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }