<template>
  <RendererFrame
    :is-readonly="!disableReadMode && isReadOnly"
    :title="filterCriteria.title"
    :filter-tooltip="filterTooltip"
    :subtitle="subtitleText"
    :predicate-as-value="predicateAsValue"
    :readonly-items="readonlyItems"
    :loading="isFetchingItems"
    :apply-disabled="!isEditConditionComplete"
    :condition-button-text="conditionButtonText"
    :hide-button="hideButton"
    @confirm="handleConfirmClick"
    @cancel="handleCancelClick"
    @delete="handleDeleteClick"
    @openChangeMode="isReadOnly = false"
  >
    <div slot="content" class="custom-field-picker">
      <div class="function-wrapper">
        <div class="select-section custom-field-select-section">
          <ar-simple-select
            :items="customFieldItems"
            :default-select-index="customFieldSelectedIndex"
            placeholder="Select custom field..."
            class="select"
            @select="handleCustomFieldSelect"
            data-test-id="filter-select-custom-field"
            :style="{
              height: '40px'
            }"
          />
        </div>
        <div class="select-section predicate-select-section">
          <ar-simple-select
            ref="predicate"
            v-if="customFieldSelectedIndex > -1 && customFieldIsAllowedType"
            :items="predicateItems"
            :default-select-index="predicateItemsSelectedIndex"
            placeholder="Select your type..."
            class="select"
            @select="handlePredicateSelect"
            data-test-id="filter-predicate-selector"
            :style="{
              height: '40px',
              marginTop: '10px'
            }"
          />
        </div>
      </div>
      <div v-if="inputsCategoryToDisplay === 'text'">
        <ar-input
          ref="input-text"
          class="input"
          v-if="editFilterCondition.data.condition !== 'is_known' && editFilterCondition.data.condition !== 'is_unknown'"
          :value="editFilterCondition.data.values[0]"
          :auto-focus="shouldAutoFocus"
          placeholder="Enter search term..."
          @input="handleTextInputChange"
          @enter="handleEnterPress"
          data-test-id="filter-text-input"
          :style="{
            height: '40px'
          }"
        />
      </div>
      <div v-else-if="inputsCategoryToDisplay === 'number'">
        <div v-if="editFilterCondition.data.condition === 'is_between'"
             class="number-input-section two-input-section">
          <div class="small-input">
            <ar-input
              class="input"
              :auto-focus="shouldAutoFocus"
              :value="editFilterCondition.data.values[0]"
              placeholder="Quantity"
              type="number"
              :min="0"
              @input="handleNumberInputChange(0, ...arguments)"
              @enter="handleEnterPress"
              data-test-id="filter-number-input-1"
              :style="{
              height: '40px'
            }"
            />
          </div>
          <div class="small-input">
            <ar-input
              class="input"
              :auto-focus="shouldAutoFocus"
              :value="editFilterCondition.data.values[0]"
              placeholder="Quantity"
              type="number"
              :min="0"
              @input="handleNumberInputChange(1, ...arguments)"
              @enter="handleEnterPress"
              data-test-id="filter-number-input-1"
              :style="{
              height: '40px'
            }"
            />
          </div>
        </div>
        <div v-else-if="!!editFilterCondition.data.condition"
             class="number-input-section big-input">
          <ar-input
            class="input"
            :auto-focus="shouldAutoFocus"
            :value="editFilterCondition.data.values[0]"
            placeholder="Quantity"
            type="number"
            @input="handleNumberInputChange(0, ...arguments)"
            @enter="handleEnterPress"
            data-test-id="filter-number-input-1"
            :style="{
              height: '40px'
            }"
          />
        </div>
      </div>
      <div v-else-if="inputsCategoryToDisplay === 'date'">
        <div v-if="editFilterCondition.data.condition === 'is_between'"
             class="date-picker-date-section with-dash">
          <div class="date-picker-small-datepicker">
            <am2-datepicker
              ref="datepicker-1"
              :value="editFilterCondition.data.values[0]"
              name="datepicker"
              placeholder="Date"
              :style="{
                width: '100%',
                height: '40px',
              }"
              @input="handleDateSelect(0, ...arguments)"
              data-test-id="filter-date-input-1"
            />
          </div>
          <div class="date-picker-small-datepicker">
            <am2-datepicker
              ref="datepicker-2"
              :value="editFilterCondition.data.values[1]"
              name="datepicker"
              placeholder="Date"
              :style="{
              width: '100%',
              height: '40px',
            }"
              @input="handleDateSelect(1, ...arguments)"
              data-test-id="filter-date-input-2"
            />
          </div>
        </div>
        <div
          v-else-if="!!editFilterCondition.data.condition"
          class="date-picker-date-section"
        >
          <am2-datepicker
            ref="datepicker-1"
            :value="editFilterCondition.data.values[0]"
            class="date-picker-date"
            name="datepicker"
            has-arrow
            :style="{
            width: '100%',
            height: '40px',
          }"
            @input="handleDateSelect(0, ...arguments)"
            data-test-id="filter-date-input"
          />
        </div>
      </div>
      <div v-else-if="(inputsCategoryToDisplay === 'dropdown-multi' || inputsCategoryToDisplay === 'dropdown-single') && !!editFilterCondition.data.condition">
        <div
          v-for="item of dropdownCustomFieldOptions"
          :key="item.oid"
          class="checkbox-section"
          :data-test-id="`filter-csp-item-${item.safeName}`"
        >
          <ar-checkbox
            :ref="`checkbox-${item.oid}`"
            class="checkbox"
            v-model="itemTickedMap[item.oid]"
            @change="handleCheckBoxChange(item.oid, item)"
            :data-test-id="`filter-csp-checkbox-${item.safeName}`"
          />
          <div class="checkbox-label-section" @click="handleCheckboxLabelClick(item.oid)">
            <ar-text
              size="xs"
              v-bind:text="item.name || 'Has been removed'"
              v-tooltip.top="(item.name || 'Has been removed' | capitalize).length > 26 ? {
                content: item.name || 'Has been removed' | capitalize
              } : null"
              :style="{
                width: 'calc(100% - 60px)',
                color: !item.name ? $arStyle.color.red500 : null,
                margin: '4px 8px 4px 0',
              }"
            />
          </div>
        </div>
      </div>
      <div v-if="customFieldSelectedIndex > -1 && !customFieldIsAllowedType">
        <ar-text size="xs" text="This custom field type cannot be filtered at this time" multiple-lines />
      </div>
    </div>
  </RendererFrame>
</template>

<script>
import RendererFrame from '../RendererFrame'
import { mapState } from "vuex";
import { clone } from '@/utils/helpers/';

export default {
  name: "CustomFieldPicker",
  components: {
    RendererFrame,
  },
  props: {
    filterCriteria: {
      type: Object,
      default: () => ({}),
    },
    filterCondition: {
      type: Object,
      default: () => ({}),
    },
    conditionButtonText: {
      type: String,
      default: 'Apply filter'
    },
    hideButton: {
      type: Boolean,
      default: false
    },
    disableReadMode: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      isReadOnly: false,
      customFields: [],
      editFilterCondition: clone(this.filterCondition),
      cachedFilterCondition: clone(this.filterCondition),
      isFetchingItems: false,
      selectedCustomField: null,
      itemTickedMap: {},
      chosenItems: [],
    }
  },
  computed: {
    ...mapState({
      auth: state => state.auth,
      segment: state => state.segment,
      promoterOid: state => state.auth.account.promoterOid,
    }),
    inputsCategoryToDisplay() {
      if (this.editFilterCondition.data.condition === 'is_known' ||
        this.editFilterCondition.data.condition === 'is_unknown') {
        return null;
      }
      if (this.customFieldIsAllowedType) {
        return this.selectedCustomFieldType
      }
      return null;
    },
    dropdownCustomFieldOptions() {
      if (['dropdown-multi', 'dropdown-single'].indexOf(this.selectedCustomFieldType) === -1) {
        return [];
      }
      const options = this.selectedCustomField?.options || [];
      const mappedOptions = options.map( option => {
        return {
          name: option.name,
          safeName: option.name.replaceAll(/ /g, '-').replaceAll(/"/g, '-').replaceAll(/'/g, '-'),
          value: option.name,
          oid: option.name.replaceAll(/ /g, '-').replaceAll(/"/g, '-').replaceAll(/'/g, '-'),
        }
      })
      return mappedOptions;
    },
    customFieldIsAllowedType() {
      const dataType = this.selectedCustomFieldType;
      const allowedType = ['text', 'date', 'number', 'dropdown-multi', 'dropdown-single']
      return allowedType.indexOf(dataType) > -1
    },
    selectedCustomFieldType() {
      return this.customFields[this.customFieldSelectedIndex]?.fieldType;
    },
    filterTooltip() {
      return this.filterCriteria?.description;
    },
    shouldAutoFocus() {
      return !this.disableReadMode
    },
    customFieldItems() {
      return this.customFields.map(field => ({
        name: field.name,
        value: field.oid
      }))
    },
    customFieldSelectedIndex() {
      const selectedCustomFieldOid = this.editFilterCondition.data.customFieldOid;
      if (!selectedCustomFieldOid) return -1;
      // For some reason, findIndex didn't always work. Not sure why. This does though.
      let foundIndex = -1;
      const foundCustomField = this.customFields.find( (field, index) => {
        if (field.oid === selectedCustomFieldOid) {
          foundIndex = index;
          return true;
        }
        return false;
      });
      if (!foundCustomField) return -1;
      return foundIndex;
    },
    predicateItems() {
      return this.filterCriteria.data.predicates.filter( predicate => {
        return predicate.supportedTypes.indexOf(this.selectedCustomFieldType) > -1
      }).map(predicate => ({
        name: predicate.title,
        value: predicate.value,
      }));
    },
    predicateItemsSelectedIndex() {
      for (let i = 0; i < this.predicateItems.length; i += 1) {
        if (this.predicateItems[i].value === this.editFilterCondition.data.condition) {
          return i;
        }
      }
      return -1;
    },
    subtitleText() {
      const fieldName = this.selectedCustomField?.name;
      let predicateValue = this.editFilterCondition.data.condition;
      if (['is_known', 'is_unknown'].indexOf(predicateValue) > -1) {
        return `<p style="margin-top: 4px;"><strong>${fieldName}</strong></p>`;
      }

      const predicateName = this.predicateItems.find( item => item.value === predicateValue)?.name
      return `<p style="margin-top: 4px;"><strong>${fieldName}</strong> ${predicateName}</p>`;
    },
    predicateAsValue() {
      if (!this.editFilterCondition?.data) {
        return null;
      }

      const predicateValue = this.editFilterCondition.data.condition;
      if (['is_known', 'is_unknown'].indexOf(predicateValue) > -1) {
        return this.predicateItems.find( item => item.value === predicateValue)?.name
      }
      if (predicateValue === 'is_between') {
        const val1 = this.editFilterCondition.data.values?.[0];
        const val2 = this.editFilterCondition.data.values?.[1];
        return `${val1} - ${val2}`
      }

      if (this.editFilterCondition.data.customFieldType === "dropdown-single" || this.editFilterCondition.data.customFieldType === "dropdown-multi") {
        return null;
      }

      return this.editFilterCondition.data.values[0]?.toString();
    },
    readonlyItems() {
      if (!this.editFilterCondition.data) {
        return null;
      }
      if (this.chosenItems.length === 0) {
        return null;
      }
      return this.chosenItems.map(item => {
        return {
          name: item.name,
        };
      });
    },
    isEditConditionComplete() {
      return this.$arUtils.segment.isSegmentConditionComplete(this.editFilterCondition);
    },
    isCachedConditionComplete() {
      return this.$arUtils.segment.isSegmentConditionComplete(this.cachedFilterCondition);
    },
  },
  async mounted() {
    await this.fetchCustomFields();
    this.itemTickedMap =  this.getTickedItemMap(this.editFilterCondition.data.values || []);
    if (this.editFilterCondition?.data?.customFieldOid !== null || this.editFilterCondition?.data?.customFieldOid !== undefined) {
      const customFieldOid = this.editFilterCondition.data.customFieldOid;
      const index = this.customFields.findIndex(field => field.oid === customFieldOid);
      this.selectedCustomField = this.customFields[index];
    }
  },
  methods: {
    async fetchCustomFields() {
      this.isFetchingItems = true;
      // Manually kick off an axios request here
      const { data } = await this.$axios.get(
        `/promoter/${this.promoterOid}/custom-field`,
        {
          params: {
            $filter: 'visibility=profile'
          }
        }
      );
      this.customFields = data;
      // Once we merge it in though, let's use the $api endpoint
      this.isFetchingItems = false;
    },
    getTickedItemMap(values) {
      const itemTickedMap = {};
      values.forEach(value => {
        itemTickedMap[value] = true;
      });
      return itemTickedMap;
    },
    getTickedItemValues() {
      return Object.keys(this.itemTickedMap).map( tickedVal => {
        return this.dropdownCustomFieldOptions.find( item => item.oid === tickedVal)?.name;
      });
    },
    review() {
      this.$emit('apply', clone(this.editFilterCondition));
    },
    handleCustomFieldSelect(item) {
      // Do nothing if the values are the same
      if (this.editFilterCondition.data.customFieldOid === item.value) {
        return;
      }
      const newCondition = clone(this.editFilterCondition);
      newCondition.data.customFieldOid = item.value;
      newCondition.data.values = [];
      newCondition.data.condition = null;
      const index = this.customFields.findIndex(field => field.oid === item.value)
      newCondition.data.customFieldType = this.customFields[index] ? this.customFields[index].fieldType : null;
      this.editFilterCondition = newCondition;
      this.selectedCustomField = this.customFields[index];
      this.review();
    },
    // TODO: Add handling logic for opening date selector if CF type is date
    handlePredicateSelect (item) {
      // Skip if it doesn't change
      if (this.editFilterCondition.data.condition === item.value) {
        return;
      }
      const newCondition = clone(this.editFilterCondition);
      newCondition.data.condition = item.value;
      if (this.editFilterCondition.data.condition === 'is_between' && item.value !== 'is_between' ) {
        newCondition.data.values = this.editFilterCondition.data.values[0] ? [this.editFilterCondition.data.values[0]] : []
      }
      if (item.value === 'is_known' || item.value === 'is_unknown') {
        newCondition.data.values = [];
        this.itemTickedMap = {};
        this.chosenItems = [];
      }
      if (item.value === 'is_exactly') {
        this.itemTickedMap = {};
      } else if (this.editFilterCondition.data.condition === 'is_exactly') {
        newCondition.data.values = [];
        this.itemTickedMap = {};
        this.chosenItems = [];
      }
      this.editFilterCondition = newCondition;
      this.review();
    },
    handleTextInputChange(val) {
      this.$set(this.editFilterCondition.data.values, 0, val);
      this.review();
    },
    handleNumberInputChange(index, val) {
      if (val !== null && val !== undefined && val !== '') {
        this.$set(this.editFilterCondition.data.values, index, parseInt(val, 10));
        this.review();
      }
    },
    handleEnterPress() {
      // TODO fill this out - copy approach in FreeTextPicker
    },
    handleDateSelect(index, date) {
      this.$set(this.editFilterCondition.data.values, index, date);
      this.review();

      if (index === 0 && this.editFilterCondition.data.condition === 'is_between' && !this.editFilterCondition.data.values[1]) {
        this.openDatepicker(2);
      }
    },
    openDatepicker(index) {
      setTimeout(() => {
        const datePicker = this.$refs[`datepicker-${index}`];
        if (datePicker) {
          datePicker.open();
        }
      }, 50);
    },
    handleConfirmClick() {
      // TODO
      this.isReadOnly = true;
      this.cachedFilterCondition = clone(this.editFilterCondition);
      
    },
    handleDeleteClick() {
      this.$emit('delete');
    },
    handleCancelClick() {
      if (this.isCachedConditionComplete) {
        if (!this.disableReadMode) {
          this.isReadOnly = true;
          this.editFilterCondition = clone(this.cachedFilterCondition);
          this.$emit('apply', clone(this.editFilterCondition));
        } else {
          this.$emit('delete');
        }
      } else {
        this.$emit('delete');
      }
    },
    handleCheckBoxChange(checkValue, item) {
      if (!this.itemTickedMap[checkValue]) {
        delete this.itemTickedMap[checkValue];
        this.chosenItems = this.chosenItems.filter(item => item.oid !== checkValue);
      } else {
        this.chosenItems = [...this.chosenItems, item];
      }
      this.editFilterCondition.data.values = this.getTickedItemValues();
      this.review();
    },
    handleCheckboxLabelClick(oid) {
      this.$refs[`checkbox-${oid}`][0].toggle()
    },
  }
}
</script>

<style scoped lang="scss">
  .custom-field-picker {
    display: flex;
    flex-direction: column;

    .function-wrapper {
      margin-top: 8px;
      margin-bottom: 17px;

      .search {
        margin-top: 10px;
        height: 40px;
      }
    }

    .number-input-section.two-input-section {
      position: relative;
      display: flex;
      justify-content: space-between;

      &::after {
        content: "";
        position: absolute;
        display: inline-block;
        border-bottom: 1px solid $blueGrey600;
        width: 10px;
        height: 1px;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
      }

      .small-input {
        width: 125px;
        display: inline-flex;
      }
    }

    .number-input-section.big-input {
      width: 100%;
      display: inline-flex;
    }

    .date-picker-date-section {
      position: relative;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 10px;

      &.with-dash {
        &::after {
          content: "";
          position: absolute;
          display: inline-block;
          border-bottom: 1px solid $blueGrey600;
          width: 10px;
          height: 1px;
          top: 50%;
          left: 50%;
          transform: translateX(-50%) translateY(-50%);
        }
      }

      .date-picker-small-datepicker {
        display: inline-block;
        width: 132px;
      }

      .date-picker-line {
        width: 10px;
        border-top: 1px solid $skyBlueGrey500;
      }
    }

    .checkbox-section {
      display: flex;
      margin-bottom: 9px;
      align-items: flex-start;

      .checkbox {
        width: 20px;
      }

      .checkbox-label-section {
        cursor: pointer;
        width: calc(100% - 28px);
        margin-left: 8px;
        .checkbox-label-first-line {
          margin-top: 4px;
          display: flex;
          justify-content: space-between;
        }
      }
    }
  }
</style>
