var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{class:[
    'wrapper',
    'content',
    'content--centered',
    _vm.$arMediaQuery.pageContent.maxWidth('sm') && 'sm-max',
    _vm.$arMediaQuery.pageContent.maxWidth('xs') && 'xs-max' ]},[_c('am2-step-link',{staticClass:"step-back-link",attrs:{"text":"Back to integrations"},on:{"click":_vm.handleBackLinkClick}}),_vm._v(" "),_c('am2-showclix-modal',{attrs:{"is-show":_vm.showShowclixModal,"loading":_vm.isCreatingShowclixIntegration},on:{"confirm":_vm.handleShowclixConfirm,"cancel":function($event){_vm.showShowclixModal = false}}}),_vm._v(" "),_c('div',{class:[
      'title-section',
      _vm.$arMediaQuery.pageContent.maxWidth('md') && 'md-max' ]},[_c('div',{staticClass:"title-section-left"},[_c('am2-icon-title-section',{attrs:{"title":"Showclix","description":"Sync your orders, events and attendees from Showclix","icon-props":{
          name: 'showclix',
          color: _vm.$arStyle.color.showclix,
          height: '46px',
          width: '40px',
        }}})],1),_vm._v(" "),(!_vm.isFetchingShowclixIntegration && _vm.integrationsSummary)?_c('div',{staticClass:"title-section-right"},[_c('am2-expand-button-dropdown',{attrs:{"align":"left","button-props":{ iconName: 'settings' },"items":[
          {
            name: 'Remove Account',
            value: 'removeIntegration',
          } ]},on:{"select":_vm.handleSettingOptionSelect}})],1):_vm._e()]),_vm._v(" "),(_vm.isAddingShowclixIntegration || _vm.isFetchingShowclixIntegration)?_c('section',{staticClass:"integration-loading-container"},[_c('am2-loading-bubble')],1):_c('section',[(_vm.integrationFailed)?_c('section',{staticClass:"integration-failed-message"},[_c('ar-snackbar',{style:({ width: '100%' }),attrs:{"type":"warning","message":"Your Showclix connection is no longer valid. This means we can't sync your member data. <a>Please reconnect your account</a>"},on:{"anchorClick":_vm.handleReconnectIntegration}})],1):_c('SyncBlockContainer',{staticClass:"sync-block-container",attrs:{"is-fetching-sync-task":_vm.isFetchingSyncTask,"sync-status":_vm.syncStatus,"sync-time":_vm.syncTime,"sync-copies":{
        'in-progress': 'Syncing data from Showclix',
        'pending': 'The data sync is still pending',
        'completed': 'Your Showclix account is currently connected',
        'cancelled': 'Your Showclix account connection is cancelled',
        'failed': this.syncErrorMessage,
        'default': 'Syncing tasks not found',
      }},on:{"startSync":_vm.handleStartSync,"stopSync":_vm.handleStopSync}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }