<template>
    <portal
      to="modal"
      :disabled="!isShow"
      :style="{ position: 'absolute' }"
    >
      <ar-modal
        :is-open="isShow"
        class="showclix-modal"
        header="Connect to Showclix"
        width="590px"
        :mobile="$arMediaQuery.window.maxWidth('xs')"
        @close="handleCloseClick"
      >
        <div
          slot="body"
          :class="[
            'showclix-modal-body',
            $arMediaQuery.window.maxWidth('xs') && 'u-padding-x-3',
          ]"
        >

          <ar-field
            label="Seller ID"
            class="u-margin-top-5"
          >
            <ar-input
              v-model="sellerId"
              name="sellerId"
              placeholder="Please enter your Showclix seller id"
              id="showclix-seller-id-input"
              type="text"
              data-test-id="showclix-seller-id-input"
            />
          </ar-field>

        </div>
        <div
          slot="footer"
          :class="[
            'showclix-modal-footer',
            $arMediaQuery.window.maxWidth('xs') && 'u-padding-x-3',
          ]">
          <ar-simple-button
            text="Confirm"
            :loading="loading"
            :disabled="confirmButtonDisabled"
            :style="{ width: '108px' }"
            @click="handleConfirmClick"
            id="showclix-confirm-button"
            data-test-id="showclix-confirm-button"
          />
        </div>
      </ar-modal>
    </portal>
  </template>

  <script>
  import { mapState } from 'vuex';

  export default {
    name: 'AskInputModal',
    components: {},
    props: {
      isShow: {
        type: Boolean,
        default: false,
      },
      loading: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        sellerId: null
      };
    },

    computed: {
      confirmButtonDisabled() {
        return !this.sellerId;
      },
      ...mapState({
      promoterOid: state => state.auth.account.promoterOid,
    }),
    },

    watch: {
      isShow(val) {
        if (val) {
          this.sellerId = null;
        }
      },
    },

    methods: {
      handleConfirmClick() {
        let params = {
            sellerId: this.sellerId
        }

        this.$emit('confirm', params);
      },
      handleCloseClick() {
        this.$emit('cancel');
      },
      toggleApplyPrefix() {
        this.applyPrefix = !this.applyPrefix;
      }
    },
  };
  </script>

  <style lang="scss" scoped>
  .showclix-modal {
    .showclix-modal-body {
      padding: 20px 30px 30px;
    }
    .showclix-modal-footer {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding: 25px 30px;
      height: 100px;
    }
  }
  </style>
