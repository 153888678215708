<template>
  <ar-simple-select
    class="select"
    enable-filter
    :items="decoratedPromoterAccounts"
    :default-select-index="selectedPromoterIndex"
    placeholder="Please select or search for an account"
    @select="handlePromoterSelect"
    @filterStringInput="handleFilterStringInput"
  />
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { debounce } from "debounce";

export default {
  name: 'PromoterSelect',
  props: {
    value: { // Value equals to promoter-oid
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      selectedPromoterOid: null,
    };
  },
  computed: {
    ...mapState({
      promoterAccounts: state => state.admin.promoterAccounts,
    }),
    selectedPromoterIndex() {
      return this.promoterAccounts.findIndex(promoter => promoter.oid === this.selectedPromoterOid);
    },
    decoratedPromoterAccounts() {
      return this.promoterAccounts.map(promoter => ({
        name: `${promoter.name} - ${promoter.emailAddress  } (${promoter.oid})`,
        oid: promoter.oid,
      }));
    },
  },
  watch: {
    value: {
      handler: function (val) {
        this.selectedPromoterOid = val;
      },
      immediate: true,
    },
  },
  created() {
    this.fetchPromoterAccountsDebouncing = debounce(this.fetchPromoterAccounts, 300);
    this.fetchPromoterAccountsDebouncing('');
  },
  methods: {
    ...mapActions([
      'admin/FETCH_MORE_PROMOTER_ACCOUNTS',
    ]),
    fetchPromoterAccounts(filterString) {
      this['admin/FETCH_MORE_PROMOTER_ACCOUNTS']({
        filterString,
        reload: true
      });
    },
    getPromoterAccount(oid) {
      return this.promoterAccounts.find(promoter => promoter.oid === oid);
    },

    handlePromoterSelect(promoter) {
      this.$emit('select', this.getPromoterAccount(promoter.oid));
      this.$emit('input', promoter.oid);
    },

    handleFilterStringInput(filterString) {
      this.fetchPromoterAccountsDebouncing(filterString);
    },
  },
};
</script>
