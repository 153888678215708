<template>
  <portal
    to="modal"
    :disabled="!isShow"
    :style="{ position: 'absolute' }"
  >
    <ar-modal
      :is-open="isShow"
      class="purchase-details-modal"
      header="Order Details"
      width="600px"
      :mobile="$arMediaQuery.window.maxWidth('xs')"
      @close="handleCloseClick"
    >
      <div slot="body">

        <div :class="[
          'purchase-details-modal__wrapper',
          'generic',
          'header'
        ]" v-if="purchaseData">
          <div
            :class="[
              'event-details',
              $arMediaQuery.window.maxWidth('sm') && 'sm-max'
            ]"
          >
            <div
              class="icon-avatar"
            >
              <ar-icon
                type="primary"
                :name="purchaseData.source.icon"
                :color="purchaseData.source.iconColor"
                width="15px"
                height="15px"
              />
            </div>
            <div
              :class="[
                'left',
                $arMediaQuery.window.maxWidth('sm') && 'sm-max',
              ]"
            >
              <div class="purchase-data">
                <ar-text
                  size="xs"
                  :text="purchaseData.source.platform"
                  :style="{
                    marginBottom: '4px',
                    marginTop: '3px'
                  }"
                />
                <ar-text
                  size="xs"
                  :text="purchaseData.description"
                  multiple-lines
                  :style="{
                    color: $arStyle.color.skyBlueGrey700,
                  }"
                />
              </div>
            </div>
            <div
              :class="[
                'right',
                $arMediaQuery.window.maxWidth('sm') && 'sm-max',
              ]"
            >
              <ar-text
                size="xs"
                :text="`Order ${purchaseData.orderId}`"
                :align="$arMediaQuery.window.maxWidth('sm') ? 'left' : 'right'"
                :style="{
                marginBottom: '8px'
              }"
              />
              <ar-text
                size="xxxs"
                :text="prettyTime"
                :align="$arMediaQuery.window.maxWidth('sm') ? 'left' : 'right'"
                :style="{
                  color: $arStyle.color.skyBlueGrey700,
                }"
              />
            </div>
          </div>
        </div>


        <div
          :class="[
        'purchase-details-modal__wrapper',
        'generic',
        'body'
      ]" v-if="purchaseData">
          <div class="tickets-data" v-if="decoratedPurchaseDataItems">
            <table class="ticket-table">
              <tr class="header">
                <th class="description-header">Item</th>
                <th class="price-header">Price</th>
                <th class="total-header">Total</th>
              </tr>
              <tr
                v-for="(item, key) in decoratedPurchaseDataItems"
                :key="key"
                class="ticket">
                <td>
                  <div v-if="item.image || purchaseData.fallbackImage" class="ticket-image">
                    <img
                      @click="handleImageClick"
                      v-if="item.image || purchaseData.fallbackImage"
                      :src="item.image || purchaseData.fallbackImage" />
                  </div>
                  <div class="ticket-name">
                    <ar-text
                      size="xs"
                      weight="bold"
                      multiple-lines
                      :style="{
                        marginBottom: '4px',
                      }"
                      :text="`${item.quantity} x ${item.ticketType}`" />
                    <ar-text
                      size="xs"
                      weight="normal"
                      multiple-lines
                      :text="purchaseData.description"
                      :style="{
                        color: $arStyle.color.skyBlueGrey700,
                      }" />
                  </div>
                </td>
                <td
                  :style="{
                      color: itemIsRefunded(item) ? $arStyle.color.red500 : null,
                      textAlign: 'right',
                    }">{{item.formattedSinglePrice}}</td>
                <td
                  :style="{
                    color: itemIsRefunded(item) ? $arStyle.color.red500 : null,
                    textAlign: 'right',
                }">{{item.formattedTotalPrice}}</td>
              </tr>
            </table>

          </div>
          <div class="totals">
            <div class="total grand-total">
              <ar-text
                size="sm"
                weight="bold"
                text="Total" />
              <ar-text
                size="sm"
                weight="bold"
                :text="`${purchaseData.currencyCode} ${formatCurrencyLocal(purchaseData.grossTotal / 100)}`"
              />
            </div>

            <div
              class="total"
            >
              <ar-text
                size="xs"
                align="right"
                :style="{
                  color: $arStyle.color.skyBlueGrey700,
                }"
                text="Fees" />
              <ar-text
                size="xs"
                align="right"
                :style="{
                  color: $arStyle.color.skyBlueGrey700,
                }"
                :text="formatCurrencyLocal(purchaseData.fees ? purchaseData.fees : 0)" />
            </div>

            <div
              class="total"
            >
              <ar-text
                size="xs"
                align="right"
                :style="{
                  color: $arStyle.color.skyBlueGrey700,
                }"
                text="Taxes" />
              <ar-text
                size="xs"
                align="right"
                :style="{
                  color: $arStyle.color.skyBlueGrey700,
                }"
                :text="formatCurrencyLocal(purchaseData.taxes ? purchaseData.taxes : 0)" />
            </div>


          </div>
        </div>
      </div>
    </ar-modal>
  </portal>
</template>

<script>
  import moment from 'moment';
  import { formatCurrency } from '@/utils/helpers';

  export default {
    name: 'PurchaseDetailsModal',

    props: {
      isShow: {
        type: Boolean,
        default: false,
      },
      purchaseData: {
        type: Object,
        default: () => {},
      }
    },

    data() {
      return {
        onClose: () => {},
      }
    },

    computed: {
      getTagColor() {
        if(this.purchaseData.status === 'completed') {
          return 'green'
        }
        if(this.purchaseData.status === 'refunded' || this.purchaseData.status === 'partial refund') {
          return 'orange'
        }

        return 'grey';
      },

      prettyTime() {
        if(!this.purchaseData.purchaseDateTime) return '';
        return moment.utc(this.purchaseData.purchaseDateTime).local().format('ddd, MMMM Do, YYYY, h:mma');
      },

      decoratedPurchaseDataItems() {
        return this.purchaseData.lineItems.map( lineItem => {
          let formattedSinglePrice;
          if (lineItem.singlePrice === 0 && lineItem.subtotalPrice) {
            formattedSinglePrice = '-';
          } else {
            formattedSinglePrice = this.itemIsRefunded(lineItem) ? `-${this.formatCurrencyLocal(lineItem.singlePrice / 100)}` : this.formatCurrencyLocal(lineItem.singlePrice / 100);
          }
          const formattedTotalPrice = this.itemIsRefunded(lineItem) ? `-${this.formatCurrencyLocal(lineItem.subtotalPrice / 100)}` : this.formatCurrencyLocal(lineItem.subtotalPrice / 100);
          return {
            ...lineItem,
            formattedSinglePrice,
            formattedTotalPrice
          }
        });
      },
    },

    methods: {
      formatCurrencyLocal(num) {
        return formatCurrency(num);
      },
      handleImageClick() {
        let link = null
        if (this.purchaseData.campaignOid) {
          link = `/campaigns/${this.purchaseData.campaignOid}/view/dashboard`;
        } else if (this.purchaseData.eventOid) {
          link = `/events/${this.purchaseData.eventOid}/view/sales`;
        }
        if (link) {
          this.$router.push(link);
        }
      },
      itemIsRefunded(item) {
        return item.counted === false || this.purchaseData.status === 'refunded' || this.purchaseData.status === 'cancelled';
      },
      handleCloseClick() {
        this.$emit('close');
      },
    },
  };
</script>

<style lang="scss" scoped>
.purchase-details-modal {
  >>> .modal-header {
    border-bottom: 1px solid #dce0e4;
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    min-height: 50px;
    padding: 23px 28px 28px;
    overflow: auto;

    &.header {
      background-color: #F6F8FA;
      border-bottom: 1px solid #dce0e4;
    }

    .event-details {
      display:flex;
      flex-flow:row;
      justify-content:space-between;

      &.sm-max {
        flex-flow: column;
      }

      .icon-avatar {
        width: 40px;
        height: 40px;
        border-radius: 20px;
        border: solid 1px #dcdee4;
        background-color: #ffffff;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
      }

      .left {
        display:inline-flex;
        overflow:hidden;
        flex-flow: row;
        flex: 1 1;
        margin-right: 10px;
        margin-left: 12px;

        .purchase-data {
          padding-bottom: 2px;
        }

        &.sm-max {
          margin-top: 10px;
          margin-left: 0;
          margin-right: 0;
        }
      }

      .right {
        display:inline-flex;
        flex-direction:column;
        flex: 0 0;

        &.sm-max {
          margin-top: 10px;
        }
      }
    }

    .tickets-data {
      display:flex;
      flex-direction:column;

      .ticket-table {
        border-collapse: collapse;
        width: 100%;
        overflow: hidden;
        table-layout: fixed;
        .header {
          border-bottom: 1px solid $skyBlueGrey400;
          padding-bottom: 10px;
          font-size: 14px;
          height: 30px;
        }
        .description-header {
          text-align:left;
          width: 63%;
        }
        .price-header {
          text-align:right;
          width: 17%;
        }
        .total-header {
          text-align:right;
          width: 20%;
        }

        .ticket {
          border-bottom: 2px solid $skyBlueGrey400;
          td {
            padding: 20px 0;
            font-size: 14px;
            vertical-align: top;
          }

          td:first-child {
            padding: 16px 0;
            display:flex;
            flex-direction:row;
            .ticket-image {
              margin-right: 12px;
              max-width: 55px;
              min-width: 55px;
              max-height: 55px;
              overflow:hidden;
              border-radius: 4px;
              display:inline-flex;
              img {
                height:55px;
                object-fit: cover;
                cursor: pointer;
                max-width:100%;
              }
            }
            .ticket-name {
              display:inline-flex;
              flex-direction: column;
              justify-content: center;
              padding-right: 12px;
            }
          }
          td:last-child {
            text-align:right;
          }
        }
      }
    }

    .totals {
      display:flex;
      flex-direction:column;
      margin-top: 16px;
      .total {
        padding: 4px 0;
        display:flex;
        flex-direction:row;
        justify-content:space-between;
      }
      .grand-total {
        padding: 4px 0 12px;
      }
    }
  }

}

</style>
