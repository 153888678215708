var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"infinite-scroll",rawName:"v-infinite-scroll",value:(_vm.fetchMoreFans),expression:"fetchMoreFans"}],attrs:{"infinite-scroll-disabled":"disableFetchMoreFans","infinite-scroll-distance":50}},[_c('am2-fan-mass-edit-modal',{attrs:{"is-open":_vm.displayMassFansEdit,"search-string":_vm.searchString},on:{"edit":_vm.handleMassFansEdit,"cancel":_vm.handleMassFansEditCancel}}),_vm._v(" "),_c('am2-add-fan-to-message-list-modal',{attrs:{"is-open":_vm.displayAddFanToMessageListModal,"search-string":_vm.searchString},on:{"add":_vm.handleAddFanToMessageListComplete,"cancel":_vm.handleAddFanToMessageListCancel}}),_vm._v(" "),_c('am2-edit-fan-tags-modal',{attrs:{"is-open":_vm.displayEditFanTagsModal,"action":_vm.editFanTagsAction,"search-string":_vm.searchString},on:{"edit":_vm.handleFanTagsEdit,"cancel":_vm.handleFanTagsEditCancel}}),_vm._v(" "),_c('am2-fan-mass-delete-modal',{attrs:{"is-open":_vm.displayFansDeleteModal,"search-string":_vm.searchString},on:{"delete":_vm.handleFansDelete,"cancel":_vm.handleFansDeleteCancel}}),_vm._v(" "),(!_vm.isAudienceFeatureEnabled)?_c('am2-unlock-prompt',{directives:[{name:"ar-sticky-top",rawName:"v-ar-sticky-top",value:({
      priority: 1,
    }),expression:"{\n      priority: 1,\n    }"}],attrs:{"title":_vm.$arMediaQuery.pageContent.minWidth('sm') ? "Unlock Audience" : "Audience","subtitle":_vm.$arMediaQuery.pageContent.minWidth('sm') ? "Store all your ticket sales data & contacts in one place. Create segments for targeting, helping you sell more tickets. <a href='https://audiencerepublic.com/audience' target='_blank' style='color: #FFF;'>Learn more</a>" : "Store all your ticket sales data & contacts in one place. <a href='https://audiencerepublic.com/audience' target='_blank' style='color: #FFF;'>Learn more</a>","button-text":_vm.$arMediaQuery.pageContent.minWidth('sm') ? "Upgrade Plan" : "Upgrade","size":_vm.$arMediaQuery.pageContent.maxWidth('xs') ? 'small' : 'medium'},on:{"click":_vm.goToPlans}}):_vm._e(),_vm._v(" "),_c('section',{staticClass:"audience-page-wrapper"},[_c('div',{class:( _obj = {
        content: true
      }, _obj['audience-page-content'] = true, _obj )},[_c('div',{class:[
          'audience-page-top-wrapper',
          _vm.$arMediaQuery.pageContent.maxWidth('xs') && 'xs-max',
          _vm.$arMediaQuery.pageContent.maxWidth('sm') && 'sm-max' ]},[_c('div',{staticClass:"audience-page-top-left-section"},[_c('div',{staticClass:"audience-page-filter-title"},[_c('ar-text',{attrs:{"size":"lg","text":"Audience"}})],1),_vm._v(" "),_c('am2-segment-dropdown',{style:({
              marginTop: '8px',
            }),attrs:{"size":_vm.$arMediaQuery.pageContent.maxWidth('xs') ? 'small' : 'large'}})],1),_vm._v(" "),(!_vm.displayImportContactsSection)?_c('div',{class:[
            'audience-page-top-right-section',
            _vm.$arMediaQuery.pageContent.maxWidth('xs') && 'xs-max',
            _vm.$arMediaQuery.pageContent.maxWidth('sm') && 'sm-max' ]},[_c('div',{staticClass:"audience-page-top-right-section-button"},[_c('ar-simple-button',{style:({ width: '100%' }),attrs:{"text":_vm.$arMediaQuery.pageContent.maxWidth('xs') ? 'Import' : 'Import Contacts',"data-test-id":"import-contacts-button"},on:{"click":_vm.handleImportClick}})],1)]):_vm._e()]),_vm._v(" "),_c('ar-divider',{style:({
          marginTop: '29px',
        })}),_vm._v(" "),(_vm.hasValidPendingTasks)?_c('ar-snackbar',{style:({ marginTop: '10px' }),attrs:{"type":"warning","message":_vm.pendingTasksString}}):_vm._e(),_vm._v(" "),(_vm.displayImportContactsSection)?_c('ImportContactsBox',{style:({
          marginTop: '28px',
          marginBottom: '30px',
        }),attrs:{"size":_vm.$arMediaQuery.pageContent.maxWidth('sm') ? 'small' : 'medium'},on:{"connectIntegrations":_vm.handleConnectIngegrationsClick,"importCsv":_vm.handleImportClick}}):_vm._e(),_vm._v(" "),_c('div',{style:({
          marginTop: '30px',
          minHeight: '20px',
        })},[(!_vm.hideContactsCountText)?_c('ar-text',{attrs:{"size":"xs","allow-html":"","text":_vm.contactsCountText}}):_vm._e()],1),_vm._v(" "),_c('am2-table-control-section',{directives:[{name:"ar-sticky-top",rawName:"v-ar-sticky-top",value:({
          priority: 1,
        }),expression:"{\n          priority: 1,\n        }"}],style:({
          marginTop: '8px',
          backgroundColor: '#f6f9fc',
        }),attrs:{"with-custom-fields":"","fans-search":{
          action: _vm.serverSideSearch,
        },"column-settings":{
          selectedColumnKeys: _vm.selectedColumnKeys,
          availableColumns: _vm.availableColumnsWithCustomFields,
          action: _vm.handleSelectedColumnKeysUpdate,
          disabled: !_vm.isFeatureEnabled(['audience']),
        },"csv-export":{
          action: _vm.handleExportCsvClick,
          disabled: !_vm.isFeatureEnabled(['audience']),
        },"loading":(_vm.isFetchingAudience || _vm.isWaitingForFirstFilter) && _vm.audience.length === 0,"reset-default-columns":_vm.resetDefaultColumns,"show-filter-and-search-bar":"","show-filter-options-bar":"","show-checked-rows-options-bar":""},on:{"fansAddToMessageList":_vm.handleAddFanToMessageList,"fansEdit":_vm.handleFanEditClick,"fansEditTags":_vm.handleFanEditTagsClick,"fansDelete":_vm.handleFanDeleteClick}}),_vm._v(" "),_c('am2-fan-table',{directives:[{name:"ar-feature-mask",rawName:"v-ar-feature-mask",value:({
          show: _vm.showFeatureMask,
          showUpgradeOn: _vm.filterIsNotEmpty || _vm.forceFeatureMask ? 'always' : 'hover',
          iconName: 'audience',
          title: 'Put your data to use, to sell more tickets',
          message: 'Store all your ticket sales data & contacts in one place. Create segments for targeting, helping you sell more tickets.',
          margin: {
            top: 50
          },
        }),expression:"{\n          show: showFeatureMask,\n          showUpgradeOn: filterIsNotEmpty || forceFeatureMask ? 'always' : 'hover',\n          iconName: 'audience',\n          title: 'Put your data to use, to sell more tickets',\n          message: 'Store all your ticket sales data & contacts in one place. Create segments for targeting, helping you sell more tickets.',\n          margin: {\n            top: 50\n          },\n        }"}],ref:"audience-table",style:({
          minHeight: _vm.showFeatureMask ? '380px' : null,
        }),attrs:{"locked":_vm.showFeatureMask,"use-dummy-fans":_vm.useDummyFans,"head":_vm.displayedTableHeadings,"custom-column-widths":_vm.customColumnWidths,"body":_vm.audience,"loading":_vm.isFetchingAudience || (_vm.isWaitingForFirstFilter && _vm.audience.length === 0),"empty-text":"No audience data","default-sort-by":_vm.orderBy,"window-width":_vm.windowWidth,"enable-row-tick":"","has-sticky-header":"","enable-row-click":_vm.customerProfilesEnabled,"has-control-section":""},on:{"tableMouseLeave":_vm.handleTableMouseLeave,"cellMouseEnter":_vm.handleCellMouseEnter,"sortBy":_vm.handleSortChange,"rowClick":_vm.handleRowClick,"columnResize":_vm.handleColumnResize}})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }