<template>
  <section 
    data-test-id="company-page-overview"
    :class="[
      'company-overview',
      $arMediaQuery.pageContent.minWidth('md') && 'md-max']"
    >
    <div
      v-if="isFetchingPromoterAccount"
      class="loading-container"
    >
      <am2-loading-spinner size="48px" />
    </div>
    <div v-else class="overview-wrapper">
      <ar-snackbar
        v-if="successUpdate"
        class="u-width-100-percent u-margin-top-4 u-margin-bottom-4"
        type="success"
        message="Successfully updated promoter account"
      />
      <ar-snackbar
        v-if="errorUpdate"
        class="u-width-100-percent u-margin-top-4 u-margin-bottom-4"
        type="error"
        message="Failed updating promoter account"
      />

      <div class="inline-block between">
        <div name="account-created" class="account-created">
          <ar-icon
            name="user-circle"
            height="20px"
            :color="$arStyle.color.blueGrey700"
            class="u-margin-right-3"
          />
          <ar-text
            text="Promoter Oid:"
            size="xxs"
            weight="bold"
            class="u-margin-right-2"
          />
          <am2-tag
            v-if="currentPromoter"
            type="green"
            shape="rectangle"
            :text="currentPromoter.oid"
            text-size="xxs"
            :style="{ marginRight: '10px' }"
          />
          <ar-text
            text="Created:"
            size="xxs"
            weight="bold"
            class="u-margin-right-2"
          />
          <ar-text
            :text="formattedData.created"
            size="xxs"
            class="u-margin-right-2"
          />
        </div>

        <div class="account-actions">
          <ar-simple-button
            text="Delete Promoter"
            type="red"
            :loading="isFetchingPromoterAccount"
            :style="{ margin: '15px 0 30px' }"
            @click="handleDeleteClick"
          />

          <ar-simple-button
            text="Update Promoter"
            :loading="isFetchingPromoterAccount"
            :style="{ margin: '15px 0 30px' }"
            @click="handleUpdateClick"
          />
        </div>
      </div>

      <div class="inline-block">
        <am2-field name="promoter-name" label="Name">
          <div>
            <ar-input
              v-validate.disable="'required'"
              id="promoter-name"
              v-model="editPromoter.name"
              name="promoter-name"
              placeholder="Name"
              class="input--block"
              type="text"
            />
            <ar-state-message
              v-if="veeErrors.has('promoter-name')"
              type="error"
              :text="veeErrors.first('promoter-name')"
              :style="{ marginTop: '8px' }"
            />
          </div>
        </am2-field>

        <am2-field name="promoter-emailAddress" label="Email Address">
          <div>
            <ar-input
              v-validate.disable="'required|email'"
              id="promoter-emailAddress"
              ref="promoter-emailAddress"
              v-model="editPromoter.emailAddress"
              name="promoter-emailAddress"
              data-vv-as="emailAddress"
              placeholder="Email"
              class="input--block"
              type="text"
            />
            <ar-state-message
              v-if="veeErrors.has('promoter-emailAddress')"
              type="error"
              :text="veeErrors.first('promoter-emailAddress')"
              :style="{ marginTop: '8px' }"
            />
          </div>
        </am2-field>
      </div>

      <am2-field  name="promoter-category" label="Company Category">
        <ar-simple-select
          data-vv-name="companyCategory"
          data-vv-as="company type"
          placeholder="Company type"
          :items="companyCategoryDropdownItems"
          :default-select-index="companyCategoryIndex"
          data-test-id="company-category-selector"
          @select="handleCompanyTypeDropdownSelect"
        />
      </am2-field>

      <am2-field name="promoter-description" label="Description">
        <div>
          <ar-textarea
            v-validate.disable="'required'"
            :rows="4"
            id="promoter-description"
            ref="promoter-description"
            v-model="editPromoter.description"
            name="promoter-description"
            data-vv-as="description"
            placeholder="Description"
            class="input--block"
            type="text"
          />
          <ar-state-message
            v-if="veeErrors.has('promoter-description')"
            type="error"
            :text="veeErrors.first('promoter-description')"
            :style="{ marginTop: '8px' }"
          />
        </div>
      </am2-field>

      <div class="inline-block">
        <am2-field  name="promoter-country" label="Country">
          <ar-country-select
            value-type="iso"
            v-model="editPromoter.country"
            enable-clear
          />
        </am2-field>

        <am2-field name="promoter-state" label="State">
          <div>
            <ar-input
              v-validate.disable="'required'"
              id="promoter-state"
              ref="promoter-state"
              v-model="editPromoter.state"
              name="promoter-state"
              data-vv-as="state"
              placeholder="State"
              class="input--block"
              type="text"
            />
            <ar-state-message
              v-if="veeErrors.has('promoter-state')"
              type="error"
              :text="veeErrors.first('promoter-state')"
              :style="{ marginTop: '8px' }"
            />
          </div>
        </am2-field>

        <am2-field name="promoter-city" label="City">
          <div>
            <ar-input
              v-validate.disable="'required'"
              id="promoter-city"
              ref="promoter-city"
              v-model="editPromoter.city"
              name="promoter-city"
              data-vv-as="city"
              placeholder="City"
              class="input--block"
              type="text"
            />
            <ar-state-message
              v-if="veeErrors.has('promoter-city')"
              type="error"
              :text="veeErrors.first('promoter-city')"
              :style="{ marginTop: '8px' }"
            />
          </div>
        </am2-field>

        <am2-field name="promoter-postcode" label="Postcode">
          <div>
            <ar-input
              v-validate.disable="'required'"
              id="promoter-postcode"
              ref="promoter-postcode"
              v-model="editPromoter.postcode"
              name="promoter-postcode"
              data-vv-as="postcode"
              placeholder="Postcode"
              class="input--block"
              type="text"
            />
            <ar-state-message
              v-if="veeErrors.has('promoter-postcode')"
              type="error"
              :text="veeErrors.first('promoter-postcode')"
              :style="{ marginTop: '8px' }"
            />
          </div>
        </am2-field>
      </div>

      <div class="inline-block">
        <am2-field name="promoter-address1" label="Address1">
          <div>
            <ar-input
              v-validate.disable="'required'"
              id="promoter-address1"
              ref="promoter-address1"
              v-model="editPromoter.address1"
              name="promoter-address1"
              data-vv-as="address1"
              placeholder="Address1"
              class="input--block"
              type="text"
            />
            <ar-state-message
              v-if="veeErrors.has('promoter-address1')"
              type="error"
              :text="veeErrors.first('promoter-address1')"
              :style="{ marginTop: '8px' }"
            />
          </div>
        </am2-field>

        <am2-field name="promoter-address2" label="Address2">
          <div>
            <ar-input
              v-validate.disable="'required'"
              id="promoter-address2"
              ref="promoter-address2"
              v-model="editPromoter.address2"
              name="promoter-address2"
              data-vv-as="address2"
              placeholder="Address2"
              class="input--block"
              type="text"
            />
            <ar-state-message
              v-if="veeErrors.has('promoter-address2')"
              type="error"
              :text="veeErrors.first('promoter-address2')"
              :style="{ marginTop: '8px' }"
            />
          </div>
        </am2-field>
      </div>

      <am2-field name="promoter-apiLimit" label="API limit">
        <div>
          <ar-input
            v-validate.disable="'required'"
            id="promoter-apiLimit"
            ref="promoter-apiLimit"
            v-model="editPromoter.apiLimit"
            name="promoter-apiLimit"
            data-vv-as="apiLimit"
            placeholder="Additional Info"
            class="input--block"
            type="text"
          />
          <ar-state-message
            v-if="veeErrors.has('promoter-apiLimit')"
            type="error"
            :text="veeErrors.first('promoter-apiLimit')"
            :style="{ marginTop: '8px' }"
          />
        </div>
      </am2-field>

    </div>
  </section>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { generateMessageCenterDateCopy } from '@/utils/date';
import { companyCategoryTypes } from '@/utils/constants';
import { sanitizeHtmlChild } from '@/utils/html-element/';

export default {
  name: 'CompanyOverview',

  data: () => ({
    editPromoter: {
      description: null,
      companyCategory: null,
      disabled: false,
      name: null,
      sysActivep: true,
      city: null,
      postcode: null,
      state: null,
      apiLimit: 0,
      additionalInfo: null,
      address1: null,
      address2: null,
      emailAddress: null,
      country: null,
    },
    companyCategoryIndex: null,
    successUpdate: false,
    errorUpdate: false,
  }),

  watch: {
    currentPromoter(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.setPromoterData()
      }
    }
  },

  computed: {
    ...mapState({
      currentPromoter: state => state.admin.currentPromoter,
      isFetchingPromoterAccount: state => state.admin.isFetchingPromoterAccount,
    }),
    
    formattedData() {
      const { sysCtime } = this.currentPromoter || {}

      return {
        created: sysCtime ? generateMessageCenterDateCopy(sysCtime) : '',
      }
    },

    companyCategoryDropdownItems() {
      return companyCategoryTypes();
    },
  },

  async mounted() {
    this.setPromoterData()
  },

  methods: {
    ...mapActions([
      'admin/FETCH_PROMOTER',
      'admin/UPDATE_PROMOTER',
      'admin/DELETE_PROMOTER',
      'SHOW_CONFIRM',
    ]),

    async fetchPromoterAccount(oid) {
      await this['admin/FETCH_PROMOTER']({ oid });
    },

    async deletePromoter(oid) {
      const deleted = await this['admin/DELETE_PROMOTER']({ oid })
      if (deleted) {
        this.$emit('on-promoter-deleted')
      }
    },

    async handleDeleteClick() {
      await this.SHOW_CONFIRM({
        title: 'Delete this promoter?',
        messageHtml: `You’re about to delete <b>${sanitizeHtmlChild(this.currentPromoter.name)}</b>. Are you sure you want to proceed?`,
        confirmButtonText: 'Delete',
        validation: {
          question: `Type ${this.currentPromoter.emailAddress} to confirm`,
          answer: this.currentPromoter.emailAddress,
        },
        asyncCompleteFunction: () => this.deletePromoter(this.currentPromoter.oid),
      })
    },

    handleUpdateClick() {
      const updatedCorrectly = this['admin/UPDATE_PROMOTER']({
        oid: this.currentPromoter.oid,
        promoterObj: this.editPromoter,
      })

      if (updatedCorrectly) {
        this.successUpdate = true
        this.errorUpdate = false
      } else {
        this.errorUpdate = true
        this.successUpdate = false
      }
    },

    setPromoterData() {
      if (!this.currentPromoter) return
      this.editPromoter.description = this.currentPromoter.description
      this.editPromoter.disabled = this.currentPromoter.disabled
      this.editPromoter.name = this.currentPromoter.name
      this.editPromoter.sysActivep = this.currentPromoter.sysActivep
      this.editPromoter.city = this.currentPromoter.city
      this.editPromoter.postcode = this.currentPromoter.postcode
      this.editPromoter.state = this.currentPromoter.state
      this.editPromoter.apiLim = this.currentPromoter.apiLim
      this.editPromoter.additionalInfo = this.currentPromoter.additionalInfo
      this.editPromoter.address1 = this.currentPromoter.address1
      this.editPromoter.address2 = this.currentPromoter.address2
      this.editPromoter.emailAddress = this.currentPromoter.emailAddress
      this.editPromoter.country = this.currentPromoter.country
      this.editPromoter.companyCategory = this.currentPromoter.companyCategory
      this.setCompanyCategoryIndex(this.currentPromoter.companyCategory)
    },

    setCompanyCategoryIndex(key) {
      this.companyCategoryIndex = 
        this.companyCategoryDropdownItems.findIndex(dropdownItem => dropdownItem.key === key)
    },

    handleCompanyTypeDropdownSelect(item) {
      this.editPromoter.companyCategory = item.key;
      this.setCompanyCategoryIndex(item.key)
    },
  }
}
</script>

<style lang="scss" scoped>
.account-created {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.account-actions {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}
.inline-block {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;

  &.between {
    justify-content: space-between;
  }

  .ar-field {
    flex: 1;
  }
}
</style>